import React from 'react'
import { Box, BoxProps, Grid, Typography, Divider } from '@material-ui/core'

interface MBoxProps extends BoxProps {
  title: string
  iconImgUrl?: string
  styleModifier?: string
}

const MBox: React.FC<MBoxProps> = ({ iconImgUrl, styleModifier, title, children, ...props }) => {
  return (
    <Box id="box" component="div" {...props}>
      <Grid id="paymentSourceBox" container spacing={2} style={{ display: 'flex', justifyContent: 'left', marginBottom: 5, marginTop: -10 }}>
        <Grid item>{iconImgUrl && styleModifier !== 'plain' && <img src={iconImgUrl} alt="img" />}</Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold', justifyContent: 'center' }}>
            {styleModifier !== 'plain' ? title : ''}
          </Typography>
        </Grid>
      </Grid>

      <Divider id="divider" style={{ marginBottom: 10, backgroundColor: '#e1e1e1', display: styleModifier !== 'plain' ? '' : 'none' }} />
      {children}
    </Box>
  )
}

export default MBox
