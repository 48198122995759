import React from 'react'
import { Grid, Box, Typography, useMediaQuery, useTheme, makeStyles } from '@material-ui/core'
import ContainedButton from 'components/ContainedButton'
import Warning from '@material-ui/icons/Warning'
import Transaction from 'models/Transaction'
import ConstantStatusMessage from './ConstantStatusMessage'
import Inquiry from 'models/Inquiry'

const TransactionDeclined: React.FC<{ transaction: Transaction; onBack?: () => void; inquiry?: Inquiry }> = ({ transaction, onBack, inquiry }) => {
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} md={10}>
        <Box textAlign="center">
          <Warning fontSize="large" color="primary" style={{ fontSize: '8em', margin: 60 }} />

          <Typography gutterBottom align="center" variant={mobileDevice ? 'h6' : 'h4'} color="error">
            Transaction declined
          </Typography>
          <Typography gutterBottom align="center" variant="subtitle2">
            We are unable to proceed your transaction, please review back your payment information
          </Typography>
          <br />
          <Typography gutterBottom align="center" variant="subtitle2">
            {ConstantStatusMessage[transaction.statusCode]}
          </Typography>
          {!inquiry || !inquiry.order || inquiry.order.channel != 'paymentlink' ? (
            <Box marginTop={[2, 4]}>
              {onBack ? (
                <ContainedButton onClick={onBack} size="large">
                  Go back
                </ContainedButton>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },
  imageContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    width: 480,
    maxHeight: 260,
    height: 'auto'
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  }
}))

export default TransactionDeclined
