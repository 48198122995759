import React, { useMemo, useEffect } from 'react'
import { Grid, Box, Typography, useMediaQuery, useTheme, makeStyles } from '@material-ui/core'
import ContainedButton from 'components/ContainedButton'

import Countdown, { CountdownRenderProps } from 'react-countdown'
import dayjs from 'dayjs'
import useTranslator from 'hooks/useTranslator'

const PaymentError: React.FC<{ error?: string; subError?: string; onBack?: () => void; display?: string }> = ({
  error,
  subError,
  onBack,
  display = 'inline'
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslator({ path: 'common' })

  subError = subError || t('unprocessed_transaction')

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} md={10}>
        <Box textAlign="center">
          <div className={classes.imageContainer}>
            {/*<img alt="Error" className={classes.image} src={`${process.env.PUBLIC_URL}/images/undraw_cookie_love_ulvn.svg`} /> */}
          </div>

          <Typography gutterBottom align="center" variant={mobileDevice ? 'h6' : 'h5'} color="error">
            {error || t('network_error')}
          </Typography>
          <Typography gutterBottom align="center" variant="subtitle2">
            {subError}
          </Typography>

          <Typography gutterBottom align="center" variant="subtitle2">
            <CountdownTimerSecond updatedTime={new Date()} onBack={onBack} t={t} />
          </Typography>

          <Box marginTop={[2, 4]}>
            {onBack ? (
              <ContainedButton
                id="back-button"
                onClick={onBack}
                style={{
                  display
                }}
                fullWidth
                className={classes.containedButton}
              >
                {t('go_back')}
              </ContainedButton>
            ) : null}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

const CountdownTimerSecond: React.FunctionComponent<{ updatedTime: Date; onBack?: () => void; t: (payload: string) => any }> = ({ updatedTime, onBack, t }) => {
  const classes = useStyles()
  const countDownTime = useMemo(() => dayjs(updatedTime).add(10, 's'), [])
  const renderer: (props: CountdownRenderProps) => React.ReactNode = ({ seconds, completed }) => {
    if (completed) {
      const form = document.getElementById('back-button')
      if (form) form.click()
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={3}>
            <Grid item className={classes.timerItem}>
              <Typography className={classes.timerItemSubUnitSecond}>
                {t('redirected_in')} {seconds} {t('second')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <Countdown date={countDownTime.toDate()} renderer={renderer} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },
  imageContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    width: 480,
    maxHeight: 260,
    height: 'auto'
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
  timerItem: {
    color: theme.palette.primary.main,
    minHeight: 60,
    textAlign: 'center'
  },
  timerItemSubUnitSecond: {
    fontSize: '1.2em',
    color: 'black',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em'
    }
  },
  containedButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: '1px solid',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))

export default PaymentError
