import React, { useState } from 'react'
import Inquiry from 'models/Inquiry'
import { Box, Typography, Grid, Divider, makeStyles, Paper, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Link } from '@material-ui/core'

import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded'
import { Alert } from '@material-ui/lab'
import ContainedButton from 'components/ContainedButton'
import Transaction from 'models/Transaction'
import dayjs from 'dayjs'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import DoneOutline from '@material-ui/icons/DoneOutline'
import iconOVO from 'assets/images/ovoLarge.png'

const OVOAuthorized: React.FunctionComponent<{ inquiry: Inquiry; transaction: Transaction }> = ({ inquiry, transaction }) => {
  const classes = createStyles()
  const [checkStatusError, setCheckStatusError] = useState<string>()

  const expiredTime = dayjs(transaction.createdTime).add(15, 'm')
  const statusData = transaction.statusData
  const onCheckStatus = () => {
    setCheckStatusError('Transaction is authorized and waiting for payment')
  }

  const provider = inquiry.merchant.providers.find((p) => p.providerId === 'ovo')

  return (
    <Box>
      <Box marginTop={[2, 4]} textAlign="center" alignContent="center">
        <Typography variant="h5" component="h5" style={{ fontWeight: 'bold' }}>
          Remaining payment time
        </Typography>
        <Box marginTop={[2, 4]} />

        <CountdownTimer toDate={expiredTime.toDate()} />

        <Box marginTop={2}>
          <Typography variant="body1" color="textSecondary">
            Pay before {expiredTime.format('DD MMM YYYY HH:mm')} to avoid cancellation
          </Typography>
        </Box>
      </Box>

      <Box marginTop={[2, 4]}>
        <Paper>
        <Box padding={[1, 2]} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="caption" color="textSecondary" style={{ textTransform: 'capitalize', fontSize: '26px', whiteSpace: 'break-spaces' }}>
              Complete Payment
            </Typography>
          </Box>
          <Box padding={[1, 2]} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="caption" color="textSecondary" style={{ textTransform: 'capitalize' }}>
              <Button variant="outlined" color="primary" href="#">OPEN OVO APP</Button >
            </Typography>
          </Box>
        </Paper>
      </Box>

      <Box marginTop={[2, 4]}>
        <ContainedButton fullWidth={true} size="large" onClick={onCheckStatus}>
          Check Payment Status
        </ContainedButton>
      </Box>
    </Box>
  )
}

const CountdownTimer: React.FunctionComponent<{ toDate: Date }> = ({ toDate }) => {
  const classes = createStyles()
  const renderer: (props: CountdownRenderProps) => React.ReactNode = ({ hours, minutes, seconds, completed }) => (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={3}>
          <Grid item className={classes.timerItem}>
            <Typography className={classes.timerItemUnit}>{hours}</Typography>
            <Typography className={classes.timerItemSubUnit}>Hour</Typography>
          </Grid>
          <Grid item className={classes.timerItem}>
            <Typography className={classes.timerItemUnit}>:</Typography>
          </Grid>
          <Grid item className={classes.timerItem}>
            <Typography className={classes.timerItemUnit}>{minutes}</Typography>
            <Typography className={classes.timerItemSubUnit}>Minute</Typography>
          </Grid>
          <Grid item className={classes.timerItem}>
            <Typography className={classes.timerItemUnit}>:</Typography>
          </Grid>
          <Grid item className={classes.timerItem}>
            <Typography className={classes.timerItemUnit}>{seconds}</Typography>
            <Typography className={classes.timerItemSubUnit}>Seconds</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  return <Countdown date={toDate} renderer={renderer} />
}

const OVOCaptured: React.FunctionComponent<{ inquiry: Inquiry; transaction: Transaction }> = ({ inquiry, transaction }) => {
  return (
    <Box>
      <Box marginTop={[2, 4]} textAlign="center" alignContent="center">
        <Box marginTop={[2, 4]}>
          <DoneOutline fontSize="large" color="primary" style={{ fontSize: '8em', margin: 60 }} />
        </Box>

        <Typography variant="h4" component="h5" style={{ fontWeight: 'bold' }}>
          Payment has successfully received
        </Typography>
        <Box marginTop={2}>
          <Typography variant="body1" color="textSecondary">
            Amount received {Intl.NumberFormat('en-us', { style: 'currency', currency: inquiry.currency }).format(transaction.amount)}
          </Typography>
        </Box>
      </Box>

      {/* <Box marginTop={2}>
        <ContainedButton fullWidth={true} size="large" onClick={onCheckStatus}>
          Check Payment Status
        </ContainedButton>
      </Box> */}
    </Box>
  )
}
const createStyles = makeStyles((theme) => ({
  timerContainer: {
    flexGrow: 1
  },
  timerItem: {
    color: theme.palette.primary.main,
    minHeight: 60,
    textAlign: 'center'
  },
  timerItemUnit: {
    fontSize: '2em',
    lineHeight: 1,
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: '5em'
    }
  },
  timerItemSubUnit: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: '1em'
    }
  },
  heading: {
    fontWeight: 'bold'
  },
  instructionOl: {
    margin: 0
  },
  instructionLi: {}
}))

export { OVOAuthorized, OVOCaptured }
