import React from 'react'
import LoadingComponent from 'components/LoadingComponent'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'

const PaymentLoading: React.FC = () => (
  <>
    <LoadingComponent message={'Processing your payment'} />
    <PoweredByComponent icon={PoweredByIcon} />
  </>
)

export default PaymentLoading
