import React from 'react'
import { Box, Grid, Typography, Divider } from '@material-ui/core'
import iconCreditCard from 'assets/images/icn-credit-card-2.svg'
import DoneOutline from '@material-ui/icons/DoneOutline'
import Transaction from 'models/Transaction'
import Merchant from 'models/Merchant'
import Inquiry from 'models/Inquiry'
import useTranslator from 'hooks/useTranslator'
import { CountdownTimerSecond } from 'components/CountdownTimer'

const BNIDirectDebitResult: React.FC<{ transaction: Transaction; merchant: Merchant; inquiry: Inquiry }> = ({ transaction, merchant, inquiry }) => {
  const { t } = useTranslator({ path: ['card', 'common'] })
  const provider = merchant.providers.find((x) => x.providerId === 'bnidirectdebit')
  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <img className="iconCardPayment" src={iconCreditCard} alt="CC" />
        </Grid>
        <Grid item>
          <Typography className="labelPayWith" variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
            {t('common:pay_with')} {provider?.title || provider?.provider?.name || 'BNI DirectDebit'}
          </Typography>
        </Grid>
      </Grid>
      <Box margin={[2, 6]} textAlign="center">
        <DoneOutline fontSize="large" color="primary" style={{ fontSize: '8em', margin: 60 }} />

        <Typography className="resultDesc" variant="h4" color="primary" style={{ fontWeight: 'bold' }}>
          {t('common:payment')} {t(`common:${transaction.status}`)}
        </Typography>
        <Typography gutterBottom align="center" variant="subtitle2" style={{ marginTop: 10 }}>
          <CountdownTimerSecond transactionId={transaction.id} updatedTime={transaction.updatedTime} inquiry={inquiry} />
        </Typography>
        <Box m={8} />
      </Box>
    </>
  )
}

export default BNIDirectDebitResult
