import React, { useContext, useState } from 'react'
import { Box, makeStyles, Grid, Typography } from '@material-ui/core'
import Transaction from 'models/Transaction'
import { Alert } from '@material-ui/lab'
import ContainedButton from 'components/ContainedButton'

//@ts-ignore
import QRCode from 'react-qr-code'
import PaymentError from 'modules/checkout/PaymentError'
import Inquiry from 'models/Inquiry'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import dayjs from 'dayjs'
import { AppContext } from 'contexts/AppContext'

interface Props {
  transaction: Transaction
  inquiry: Inquiry
}

const MTIQRAuthorized: React.FC<Props> = ({ transaction, inquiry }) => {
  const cls = createStyles()

  const [isBtnDownloadEnabled, setIsBtnDownloadEnabled] = useState(true)
  const [isQRVisible, setIsQRVisible] = useState(true)
  const { getInquiryTransaction } = useContext(AppContext)

  const handleTimerComplete = () => {
    setIsQRVisible(false)
    setIsBtnDownloadEnabled(false)
  }

  const expirationDate = new Date(transaction.statusData.expireTime)
  const timeNow = new Date()
  const differenceInMilliseconds = expirationDate.getTime() - timeNow.getTime()
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000)

  const [checkStatusError, setCheckStatusError] = useState<string>()
  const [retryDate] = useState<Date>(dayjs().add(differenceInSeconds, 's').toDate())

  const qrCode = transaction.statusData.qrContent

  const merchantName = inquiry.merchant.name.toUpperCase()
  const merchantId = inquiry.merchant.id
  if (!qrCode) return <PaymentError />

  let acquirer = ''
  const getAcquirer = qrCode.match(/WWW0118(.{8})/)
  if (getAcquirer) {
    acquirer = getAcquirer[1]
  }

  var now = new Date()
  const printVersion = '1.0-' + now.getDate() + '.' + (now.getMonth() + 1) + '.' + now.getFullYear()

  const downloadQRCode = () => {
    //@ts-ignore
    const saveSvgAsPng = require('assets/js/saveSvgAsPng.js')

    saveSvgAsPng.saveSvgAsPng(document.getElementById('qrcode')?.firstChild, 'qr.png')
  }

  const onCheckStatus = () => {
    getInquiryTransaction(inquiry.id, transaction.id).then((res) => {
      if (res?.status === 'authorized') {
        setCheckStatusError('Transaction is authorized and waiting for payment')
        setTimeout(() => {
          setCheckStatusError(undefined)
        }, 5000)
      }
    })
  }

  return (
    <Box>
      <Box marginTop={[2, 4]} textAlign="left" alignContent="flex-start">
        {checkStatusError && (
          <Box marginTop={[2, 4, 6]}>
            <Alert severity="error">{checkStatusError}</Alert>
          </Box>
        )}

        <Grid container>
          <Grid item xs={11}>
            <img src={require('assets/images/qris.png')} alt="QRIS" style={{ marginTop: 25 }} />
          </Grid>
          <Grid item xs={1}>
            <img src={require('assets/images/qr-gpn.png')} alt="GPN" style={{ marginTop: 10 }} />
          </Grid>
        </Grid>

        <Box marginTop={[2, 4]}>
          <Typography style={{ fontWeight: 'bold' }}>{merchantName}</Typography>
          <Typography style={{ fontWeight: 'bold' }}>NMID : {merchantId}</Typography>
        </Box>

        {isQRVisible && (
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <Box id="qrcode" className={cls.qrBox}>
                <QRCode value={qrCode} renderAs="canvas" bgColor="#FFFFFF" size={256} level="M" />
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid container alignItems="center" justify="center">
          <CountdownTimer toDate={retryDate} onComplete={handleTimerComplete} />
        </Grid>

        <Typography>Dicetak Oleh : {acquirer}</Typography>
        <Typography>Versi cetak : {printVersion}</Typography>
      </Box>

      <Grid container justify="center">
        <Box marginTop={[2, 4]}>
          <ContainedButton fullWidth={true} size="large" onClick={downloadQRCode} disabled={!isBtnDownloadEnabled}>
            Download QR Code
          </ContainedButton>
        </Box>
      </Grid>

      <Grid container justify="center">
        <Box marginTop={[2, 4]}>
          <ContainedButton fullWidth={true} size="large" onClick={onCheckStatus}>
            Check Payment Status
          </ContainedButton>
        </Box>
      </Grid>
    </Box>
  )
}

const createStyles = makeStyles((theme) => ({
  qrBox: { marginTop: theme.spacing(4), marginBottom: theme.spacing(4) },
  heading: {}
}))

const CountdownTimer: React.FC<{ toDate: Date; onComplete: () => void }> = ({ toDate, onComplete }) => {
  const renderer: (props: CountdownRenderProps) => React.ReactNode = ({ completed, formatted }) => {
    if (completed) {
      onComplete()
    }
    return (
      <>
        <Typography
          style={{
            fontWeight: 'bold',
            color: 'red'
          }}
        >
          {formatted.hours.padStart(2, '0')}:{formatted.minutes.padStart(2, '0')}:{formatted.seconds.padStart(2, '0')}
        </Typography>
      </>
    )
  }

  return <Countdown date={toDate} renderer={renderer} />
}

export default MTIQRAuthorized
