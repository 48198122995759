import cardEn from '../assets/locales/en/card.json'
import commonEn from '../assets/locales/en/common.json'

import cardId from '../assets/locales/id/card.json'
import commonId from '../assets/locales/id/common.json'

export default {
  en: {
    card: cardEn,
    common: commonEn
  },
  id: {
    card: cardId,
    common: commonId
  }
}
