import React, { createContext, useReducer, Dispatch } from 'react'
import { SessionType, sessionReducer, SessionActionType } from 'reducers/SessionReducer'
import { inquiryReducer, InquiryActionType, InquiryType } from 'reducers/InquiryReducer'
import Api, { PaymentRequest, PaymentResponse, PromoRequest, InstallmentRequest } from 'utils/Api'
import Inquiry from 'models/Inquiry'
import { Promo } from 'models/Promo'
import { Installment } from 'models/Installment'
import SvgComponent from 'assets/images/background_logo'
import { renderToStaticMarkup } from 'react-dom/server'
import Transaction from 'models/Transaction'

type InitialStateType = {
  session: SessionType
  inquiry: InquiryType
}

const initialState = {
  session: { counter: 0 },
  inquiry: { inquiry: null, promo: null, installments: null }
}

const AppContext = createContext<{
  state: InitialStateType
  dispatch: Dispatch<SessionActionType | InquiryActionType>
  fetchInquiry: (id: string) => Promise<Inquiry | undefined>
  submitPayment: (req: PaymentRequest) => Promise<any | undefined>
  retryOtp: (ipayload: any) => Promise<string | undefined>
  submitPaymentAuthenticate: (id: string, req: { authenticationCode: any }) => Promise<PaymentResponse | undefined>
  fetchPromo: (req: PromoRequest) => Promise<Promo | null | undefined>
  fetchInstallments: (req: InstallmentRequest) => Promise<Installment[] | null | undefined>
  revertBackground: (inquiryId: string, inquiry?: Inquiry) => void
  removeBackground: (onLoad?: () => void) => void
  getInquiryTransaction: (inquiryId: string, transactionId: string) => Promise<Transaction | undefined>
}>({
  state: initialState,
  dispatch: () => null,
  fetchInquiry: () => Promise.resolve(undefined),
  submitPayment: () => Promise.resolve(undefined),
  retryOtp: () => Promise.resolve(undefined),
  submitPaymentAuthenticate: () => Promise.resolve(undefined),
  fetchPromo: () => Promise.resolve(undefined),
  fetchInstallments: () => Promise.resolve(undefined),
  revertBackground: () => undefined,
  removeBackground: () => undefined,
  getInquiryTransaction: () => Promise.resolve(undefined)
})

const mainReducer = ({ session, inquiry }: InitialStateType, action: SessionActionType | InquiryActionType) => ({
  session: sessionReducer(session, action as SessionActionType),
  inquiry: inquiryReducer(inquiry, action as InquiryActionType)
})

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)

  const fetchInquiry: (id: string) => Promise<Inquiry> = (id: string) => {
    return Api.request<Inquiry>({
      method: 'GET',
      url: `/inquiries/${id}`
    }).then(({ status, data }) => {
      dispatch({ type: 'SET_INQUIRY', payload: data })
      return data
    })
  }

  const getInquiryTransaction: (inquiryId: string, transactionId: string) => Promise<Transaction> = (inquiryId: string, transactionId: string) => {
    return Api.request<Transaction>({
      method: 'GET',
      url: `/inquiries/${inquiryId}/transactions/${transactionId}`
    }).then(({ status, data }) => {
      return data
    })
  }

  const submitPayment: (req: PaymentRequest) => Promise<PaymentResponse> = (req) => {
    return Api.request<PaymentResponse>({
      method: 'POST',
      url: `/payments`,
      data: req
    }).then(({ status, data }) => {
      return data
    })
  }
  const retryOtp: (payload: { transactionId: string }) => Promise<string | undefined> = async (payload) => {
    const { status, data } = await Api.request<string>({
      method: 'POST',
      url: `/payments/otp`,
      data: payload
    })

    return data
  }

  const submitPaymentAuthenticate: (id: string, req: { authenticationCode: any }) => Promise<PaymentResponse> = (id, req) => {
    return Api.request<PaymentResponse>({
      method: 'POST',
      url: `/payments/${id}/authenticate`,
      data: req
    }).then(({ status, data }) => {
      return data
    })
  }

  const fetchPromo: (req: PromoRequest) => Promise<Promo | null> = (req) => {
    return Api.request<Promo>({
      method: 'POST',
      url: `/promos/lookup`,
      data: req
    }).then(({ status, data }) => {
      dispatch({ type: 'SET_PROMO', payload: data })
      return data
    })
  }

  const fetchInstallments: (req: InstallmentRequest) => Promise<Installment[] | null> = (req) => {
    return Api.request<Installment[]>({
      method: 'POST',
      url: `/installments/lookup`,
      data: req
    }).then(({ status, data }) => {
      if (data !== null) {
        data.sort(function (a, b) {
          return a.planPeriod - b.planPeriod
        })
      }

      dispatch({ type: 'SET_INSTALLMENT', payload: data })
      return data
    })
  }

  const revertBackground: (inquiryId: string, inquiry?: Inquiry) => void = async (inquiryId, inquiry) => {
    let merchantStyle: {
      id: string
      theme: string
      primaryColor: string
      secondaryColor: string
      backgroundColor: string
      css: string
    } = {
      id: '',
      theme: '',
      primaryColor: '',
      secondaryColor: '',
      backgroundColor: '',
      css: ''
    }

    if (inquiry) {
      merchantStyle = inquiry.merchant.merchantStyle
    } else {
      fetchInquiry(inquiryId).then((res) => {
        merchantStyle = res.merchant.merchantStyle
      })
    }
    const paper = document.getElementById('paper')
    if (paper) {
      paper.style.boxShadow = '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
    }
    const bgRemove = document.getElementById('checkout-container')
    if (bgRemove) {
      const svgString = encodeURIComponent(
        renderToStaticMarkup(<SvgComponent color={merchantStyle && merchantStyle.backgroundColor ? merchantStyle.backgroundColor : null} />)
      )
      bgRemove.style.backgroundImage = `url('data:image/svg+xml;utf8, ${svgString}')`
      bgRemove.style.display = ''
    }
    const divider = document.getElementById('divider')
    if (divider) {
      divider.style.display = 'inherit'
    }

    const loadingComp = document.getElementById('loadingComp')
    if (loadingComp) {
      loadingComp.style.marginTop = ''
    }

    const paymentSourceBox = document.getElementById('paymentSourceBox')
    if (paymentSourceBox) {
      paymentSourceBox.style.display = 'flex'
    }
  }

  const removeBackground = (onLoad?: () => void) => {
    const paper = document.getElementById('paper')
    if (paper) {
      paper.style.boxShadow = 'none'
    }
    const divider = document.getElementById('divider')
    if (divider) {
      divider.style.display = 'none'
    }
    const paymentSourceBox = document.getElementById('paymentSourceBox')
    if (paymentSourceBox) {
      paymentSourceBox.style.display = 'none'
    }
    const loadingComp = document.getElementById('loadingComp')
    if (loadingComp) {
      loadingComp.style.marginTop = '70%'
    }
    const bg = document.getElementById('checkout-container')
    if (bg) {
      bg.style.backgroundImage = ''
    }
  }

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        fetchInquiry,
        submitPayment,
        submitPaymentAuthenticate,
        fetchPromo,
        fetchInstallments,
        retryOtp,
        revertBackground,
        removeBackground,
        getInquiryTransaction
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }
