import React, { useState, useContext, useEffect, useMemo } from 'react'
import { Box, Grid, Typography, InputLabel, makeStyles } from '@material-ui/core'
import Transaction from 'models/Transaction'
import FilledTextField from 'components/FilledTextField'
import ContainedButton from 'components/ContainedButton'
import LoadingComponent from 'components/LoadingComponent'
import { AppContext } from 'contexts/AppContext'
import dayjs from 'dayjs'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import useTranslator from 'hooks/useTranslator'

const BNIDirectDebitOTP: React.FC<{ transaction: Transaction; onPaymentSubmitted: () => void }> = ({ transaction, onPaymentSubmitted }) => {
  const { submitPaymentAuthenticate, retryOtp } = useContext(AppContext)
  const [authenticationCode, setAuthenticationCode] = useState<string>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [retryDate, setRetryDate] = useState<Date>(dayjs().add(3, 'm').toDate())

  const { t } = useTranslator({ path: ['card', 'common'] })

  const onSubmit = (e: any) => {
    e && e.preventDefault && e.preventDefault()

    if (authenticationCode) {
      setSubmitting(true)
      submitPaymentAuthenticate(transaction.id, {
        authenticationCode
      })
        .then(() => {
          onPaymentSubmitted()
        })
        .catch(() => {
          setSubmitting(false)
          // setPaymentError(new ApiErrorParser(e).toString())
        })
        .finally(() => {})
    }
  }

  if (submitting) return <LoadingComponent />

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={8}>
        <form method="post" onSubmit={onSubmit}>
          <Box marginTop={[2, 4]} textAlign="center">
            <Typography variant="h4" gutterBottom>
              Purchase authentication
            </Typography>
            <Typography variant="body1">We have sent you a text message with a code to {transaction.statusData.phoneNumber}.</Typography>
            {/* <Typography variant="body1">You are paying using card</Typography> */}

            <Box marginTop={4} />

            <InputLabel>
              <h4>Enter your OTP Code below to proceed.</h4>
              <h5>Mohon masukkan kode OTP sebelum batas waktu berakhir (2 menit 30 detik).</h5>
            </InputLabel>
            <FilledTextField
              fullWidth={true}
              variant="filled"
              label={t('common:otp_code')}
              color="primary"
              size="medium"
              placeholder="Enter your OTP code here"
              inputMode="numeric"
              required={true}
              inputProps={{ minLength: 6, maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(e) => {
                const result = e.target.value.replace(/\D/g, '')
                setAuthenticationCode(result)
              }}
            />

            <Box marginTop={[2]}>
              <ContainedButton fullWidth={true} type="submit">
                Submit
              </ContainedButton>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  )
}

export default BNIDirectDebitOTP
