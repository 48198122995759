import React from 'react'
import MTIISOOTP from './MTIISOOTP'
import Transaction from 'models/Transaction'
import Api from 'utils/Api'
import MTIISOCardinal from './MTIISOCardinal'
import LoadingComponent from 'components/LoadingComponent'
import MBox from 'components/MBox'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'

const MTIISOAuthentication: React.FC<{ transaction: Transaction; paymentData?: any; onPaymentSubmitted: () => void; onCancel: () => void }> = ({
  transaction,
  paymentData,
  onPaymentSubmitted,
  onCancel
}) => {
  let authenticationModule = null

  if (transaction.statusData && transaction.statusData.authenticationModule) {
    authenticationModule = transaction.statusData.authenticationModule
  }
  if (authenticationModule === 'mtiisootp' || authenticationModule === 'simulator') {
    return <MTIISOOTP transaction={transaction} onPaymentSubmitted={onPaymentSubmitted} />
  } else if (authenticationModule === 'cardinal') {
    return <MTIISOCardinal transaction={transaction} onPaymentSubmitted={onPaymentSubmitted} onCancel={onCancel} paymentData={paymentData} />
  } else {
    return (
      <>
        <LoadingComponent message={'Please wait for authentication'} />
        {/* <PoweredByComponent icon={PoweredByIcon} /> */}
      </>
    )
  }
}

export default MTIISOAuthentication
