import React, { useEffect, useMemo } from 'react'
import Inquiry from 'models/Inquiry'
import StorageManager from 'utils/StorageManager'
import { Box, Typography, Grid, makeStyles } from '@material-ui/core'

import Transaction from 'models/Transaction'
import dayjs from 'dayjs'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import DoneOutline from '@material-ui/icons/DoneOutline'
import ContainedButton from 'components/ContainedButton'

// for the future webcheckout and applink and currently is just applink
const GopayAuthorized: React.FunctionComponent<{ inquiry: Inquiry; transaction: Transaction }> = ({ transaction, inquiry }) => {
  const urlWebCheckout = transaction.statusData.deeplinkUrl

  const isAutoRedirectFinish = StorageManager.load(`${transaction.id}-submitted`)

  const classes = createStyles()
  const providerTitle = inquiry.merchant.providers.find((p) => p.providerId === 'gopay')

  useEffect(() => {
    for (const [key, value] of Object.entries(localStorage)) {
      const item = JSON.parse(value)
      if (dayjs(item.timestamp).diff(dayjs(), 'h') >= 1) {
        StorageManager.remove(key)
      }
    }
  }, [])

  return (
    <Box>
      <Box marginTop={[2, 4]}>
        <Box
          marginTop={[2, 4]}
          textAlign="center"
          alignContent="center"
          style={{
            padding: 15
          }}
        >
          <Typography variant="h5" color="primary" style={{ fontWeight: 'bold' }}>
            Please complete your payment
          </Typography>
        </Box>
        <Typography variant="caption" color="textSecondary">
          {!isAutoRedirectFinish ? (
            <CountdownTimerSecond transactionId={transaction.id} createdTime={transaction.createdTime} urlCheckout={urlWebCheckout} />
          ) : null}
        </Typography>
        <Box padding={[1, 2]} display="flex" justifyContent="center" alignItems="center" style={{ paddingTop: 40 }}></Box>
      </Box>
      <form id="gopay-form" action={urlWebCheckout} method="get">
        <input type="hidden" name="message" />
        <ContainedButton fullWidth onClick={() => window.location.replace(urlWebCheckout)} className={classes.containedButton}>
          Redirect to {providerTitle ? providerTitle.title : 'Gopay'}
        </ContainedButton>
      </form>
    </Box>
  )
}

const CountdownTimerSecond: React.FunctionComponent<{ createdTime: Date; transactionId: string; urlCheckout: string }> = ({
  createdTime,
  transactionId,
  urlCheckout
}) => {
  const classes = createStyles()
  const autoRedirectTime = useMemo(() => dayjs(createdTime).add(5, 's'), [])

  const renderer: (props: CountdownRenderProps) => React.ReactNode = ({ seconds, completed }) => {
    const submitted = StorageManager.load(`${transactionId}-submitted`)

    if (completed && !Boolean(submitted)) {
      StorageManager.save(`${transactionId}-submitted`, true)
      window.location.replace(urlCheckout)

      return null
    } else {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={3}>
              <Grid item className={classes.timerItem}>
                <Typography className={classes.timerItemSubUnitSecond}>You will be redirected to Gopay in {seconds} seconds</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  return <Countdown date={autoRedirectTime.toDate()} renderer={renderer} />
}

const KredivoCaptured: React.FunctionComponent<{ inquiry: Inquiry; transaction: Transaction }> = ({ inquiry, transaction }) => {
  return (
    <Box>
      <Box marginTop={[2, 4]} textAlign="center" alignContent="center">
        <Box marginTop={[2, 4]}>
          <DoneOutline fontSize="large" color="primary" style={{ fontSize: '8em', margin: 60 }} />
        </Box>

        <Typography variant="h4" component="h5" style={{ fontWeight: 'bold' }}>
          Payment has successfully received
        </Typography>
        <Box marginTop={2}>
          <Typography variant="body1" color="textSecondary">
            Amount received {Intl.NumberFormat('en-us', { style: 'currency', currency: inquiry.currency }).format(transaction.amount)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
const createStyles = makeStyles((theme) => ({
  timerContainer: {
    flexGrow: 1
  },
  timerItem: {
    minHeight: 60,
    textAlign: 'center'
  },
  timerItemUnit: {
    fontSize: '2em',
    lineHeight: 1,
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: '5em'
    }
  },
  containedButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: '1px solid',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  timerItemSubUnit: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: '1em'
    }
  },
  timerItemSubUnitSecond: {
    fontSize: '1.2em',
    color: 'black',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em'
    }
  },
  heading: {
    fontWeight: 'bold'
  },
  instructionOl: {
    margin: 0
  }
}))

export { GopayAuthorized, KredivoCaptured }
