import Inquiry from 'models/Inquiry'

/**
 * Check the language of the customer associated with the provided inquiry.
 * If the inquiry is valid and contains customer information with a valid language ('id' or 'en'),
 * return the language of the customer. Otherwise, return 'id'.
 *
 * @param inquiry - The inquiry object to check for customer language.
 * @returns The language of the customer if valid, otherwise 'id'.
 */
export const checkCustomerLanguage = (inquiry: Inquiry | undefined) => {
  if (inquiry && inquiry.customer && inquiry.customer.language && ['id', 'en'].includes(inquiry.customer.language)) {
    return inquiry.customer.language
  }
  return 'id'
}

/**
 * Formats a number by splitting it into integer and decimal parts, then returning a formatted string.
 *
 * @param num - The number to be formatted.
 * @returns A string representing the formatted number.
 */
export const formatNumber = (num: number): string => {
  const [integerPart, decimalPart] = num.toString().split('.')

  if (decimalPart === '00') {
    return integerPart
  }

  if (decimalPart && decimalPart.length > 2) {
    const middleIndex = Math.floor(decimalPart.toString().length / 2)

    // Insert the decimal point at the middle index
    const firstTwoNum = decimalPart.toString().slice(0, middleIndex)
    const decimalIndex = decimalPart.toString().slice(middleIndex)

    return `${integerPart}${firstTwoNum}.${decimalIndex}`
  }

  return `${integerPart}${decimalPart}`
}
