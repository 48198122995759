import Inquiry from 'models/Inquiry'
import React from 'react'

const SvgComponent: React.FC<{
  color: string | null
}> = ({ color }) => {
  return (
    <svg style={{ width: '100%' }} viewBox="0 0 1440 566" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.68432e-05 0H1440C1440 0 1440 331.814 1440 548.214C812 189.039 420.5 660.642 8.68432e-05 548.214C-0.000108554 348.23 8.68432e-05 0 8.68432e-05 0Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="1440" y1="0.00180215" x2="535.99" y2="807.753" gradientUnits="userSpaceOnUse">
          <stop stop-color={`${color || '#4C9FD8'}`} />
          <stop offset="0.943068" stop-color={`${color || '#55BADB'}`} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgComponent
