import React from 'react'
import { Box, Grid, Typography, Divider } from '@material-ui/core'
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded'
import iconQRIS from 'assets/images/icn-qris.svg'
import DoneOutline from '@material-ui/icons/DoneOutline'
import Transaction from 'models/Transaction'
import Inquiry from 'models/Inquiry'

const MTIQRResult: React.FC<{ transaction: Transaction; inquiry: Inquiry }> = ({ transaction, inquiry }) => {
  const provider = inquiry.merchant.providers.find((p) => p.providerId === 'mtiqris')
  return (
    <>
      <Box margin={[2, 6]} textAlign="center">
        <DoneOutline fontSize="large" color="primary" style={{ fontSize: '8em', margin: 60 }} />

        <Typography variant="h4" color="primary" style={{ fontWeight: 'bold' }}>
          Payment {transaction.status}
        </Typography>
        <Typography variant="body1" color="textPrimary" style={{ marginTop: 10 }}>
          QRIS Payment has been successfully received
        </Typography>

        <Box m={8} />
      </Box>
    </>
  )
}

export default MTIQRResult
