import React from 'react'
import './App.css'
import LoadingScreen from 'components/LoadingScreen'
import { AppProvider } from 'contexts/AppContext'
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { brown, lightBlue } from '@material-ui/core/colors'
import CheckoutDirectPage from 'modules/checkout-direct/CheckoutDirectPage'

// const CheckoutPage = React.lazy(() => {
//   return new Promise<any>((resolve) => {
//     setTimeout(() => resolve(import('modules/CheckoutPage')), 2000)
//   })
//   // return Promise.all<any>([import('modules/CheckoutPage'), new Promise((resolve) => setTimeout(resolve, 1000))]).then(([moduleExports]) => moduleExports)
// })

const CheckoutPage = React.lazy(() => import('modules/checkout/CheckoutPage'))

function App() {
  // return <LoadingScreen />

  return (
    <AppProvider>
      <React.Suspense fallback={<LoadingScreen />}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route path={'/checkout/:inquiryCode'}>
              <CheckoutPage />
            </Route>

            <Route path={'/checkout-direct/:inquiryCode'}>
              <CheckoutDirectPage />
            </Route>
          </Switch>

          {/* <div style={{ marginTop: 100 }}>
              <Link to="/checkout">Goto Checkout</Link>
            </div> */}
        </BrowserRouter>
      </React.Suspense>
    </AppProvider>
  )
}

export default App
