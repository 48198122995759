import React, { useContext, useState } from 'react'
import { AppContext } from 'contexts/AppContext'
import Grid from '@material-ui/core/Grid'
import { Typography, Divider, Box, InputAdornment, InputLabel, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core'
import { Route, useRouteMatch, useHistory } from 'react-router-dom'

// @ts-ignore
import { usePaymentInputs } from 'react-payment-inputs'
// @ts-ignore
import images from 'react-payment-inputs/images'

import iconCreditCard from 'assets/images/icn-credit-card-2.svg'

import FilledTextField from 'components/FilledTextField'
import ContainedButton from 'components/ContainedButton'
import Api, { PaymentRequest } from 'utils/Api'
import PaymentLoading from '../checkout/PaymentLoading'
import PaymentError from '../checkout/PaymentError'
import Inquiry from 'models/Inquiry'
import ApiErrorParser from 'utils/ApiErrorParser'
import { Promo } from 'models/Promo'
import LoadingComponent from 'components/LoadingComponent'
import { Alert, ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { BackToShopButton } from 'components/BackToShopButton'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'
import { Installment } from 'models/Installment'
import './installment.css'
import Features from 'utils/feature'

const BNIISO: React.FC<{
  inquiry: Inquiry
  onPaymentSubmitted: (data?: any) => void
  onPaymentCancelled: () => void
}> = ({ inquiry, onPaymentSubmitted, onPaymentCancelled }) => {
  let {
    fetchPromo,
    fetchInstallments,
    state: {
      inquiry: { installments, promo }
    },
    submitPayment
  } = useContext(AppContext)

  const history = useHistory()
  const { url } = useRouteMatch()
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps } = usePaymentInputs()

  const [loading, setLoading] = useState<boolean>(true)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [paymentError, setPaymentError] = useState<string>()
  const [cardName, setCardName] = useState<string>()
  const [cardNumber, setCardNumber] = useState<string>()
  const [expiryDate, setExpiryDate] = useState<string>()
  const [cvc, setCvc] = useState<string>()
  const [useToken, setUseToken] = useState<boolean>(false)
  const [saveToken, setSaveToken] = useState<boolean>(inquiry.order?.tokenOption && inquiry.order?.tokenOption === 'on' ? true : false)
  const [installment, setInstallment] = useState<Installment>()

  const isEmailOrPhoneNumberApper = inquiry.customer && (inquiry.customer.email || inquiry.customer.phoneNumber)

  const [value, setValue] = useState('')
  const [isError, setIsError] = useState(false)

  const provider = inquiry.merchant.providers.find((p) => p.providerId === 'bniiso')

  const handleChangeCardNumber = (e: any) => {
    setCardNumber(e.target.value)
  }

  const handleChangeExpiryDate = (e: any) => {
    setExpiryDate(e.target.value)
  }

  const handleChangeCVC = (e: any) => {
    setCvc(e.target.value)
  }

  const onBackToMerchant = () => {
    if (inquiry.referenceUrl) {
      window.location.href = inquiry.referenceUrl
    }
  }

  const onSubmit = (e: any) => {
    e && e.preventDefault && e.preventDefault()

    if (!meta.error) {
      setSubmitting(true)
      const emailRegex = /^(?=.{1,64}@)[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const phoneRegex = /^(\+62|\+63|62|63|)?[\s-]?0?8[1-9]\d[\s-]?\d{4}[\s-]?\d{2,5}$/
      const data: PaymentRequest = {
        inquiryId: inquiry.id,
        amount: inquiry.amount,
        currency: inquiry.currency,
        paymentSource: 'bniiso',
        paymentSourceData: {
          cardName,
          cardNumber,
          expiryDate,
          cvc
        },
        saveToken,
        useToken,
        installmentId: installment?.id
      }

      if (Features.cardinalNewField && !isEmailOrPhoneNumberApper) {
        if (emailRegex.test(value)) {
          data.paymentSourceData.email = value
          setIsError(false)
        } else if (phoneRegex.test(value)) {
          data.paymentSourceData.phoneNumber = value
          setIsError(false)
        } else {
          setSubmitting(false)
          setIsError(true)
          return
        }
      }

      submitPayment(data)
        .then(() => {
          onPaymentSubmitted(data)
        })
        .catch((e) => {
          setSubmitting(false)
          setPaymentError(new ApiErrorParser(e).toString())
        })
        .finally(() => {})
    }
  }

  const onSelectOthers = () => {
    history.goBack()
  }

  React.useEffect(() => {
    if (meta.erroredInputs && meta.erroredInputs.cardNumber) {
    } else if (cardNumber) {
      if (inquiry.order !== undefined) {
        if (!inquiry.order!.disablePromo) {
          fetchPromo({
            inquiryId: inquiry.id,
            amount: inquiry.amount,
            currency: inquiry.currency,
            paymentSource: 'bniiso',
            paymentSourceData: {
              cardName,
              cardNumber
            }
          })
        }
      }
    }
  }, [cardNumber])

  React.useEffect(() => {
    if (inquiry.merchant.tokenIsAllowed) {
      Api.request<any>({
        method: 'GET',
        url: `/inquiries/${inquiry.id}/token`
      })
        .then(({ status, data }) => {
          if (status === 200 && data) {
            if (data.maskedData && data.maskedData.cardNumber && data.maskedData.cardName && data.maskedData.expiryDate) {
              setUseToken(true)
              setCardName(data.maskedData.cardName)
              setCardNumber(data.maskedData.cardNumber)
              setExpiryDate(data.maskedData.expiryDate.substr(0, 2) + ' / ' + data.maskedData.expiryDate.substr(2))
              if (inquiry.order?.tokenOption != 'off') {
                setSaveToken(true)
              }
            }
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])

  React.useEffect(() => {
    if (meta.erroredInputs && meta.erroredInputs.cardNumber) {
    } else if (cardNumber) {
      if (inquiry.order !== undefined) {
        fetchInstallments({
          inquiryId: inquiry.id,
          amount: inquiry.amount,
          currency: inquiry.currency,
          paymentSource: 'bniiso',
          paymentSourceData: {
            cardName,
            cardNumber
          }
        })
      }
    }
  }, [cardNumber])

  if (loading)
    return (
      <>
        <LoadingComponent message={'Please wait..'} />
        <PoweredByComponent icon={PoweredByIcon} />
      </>
    )

  return (
    <Route exact path={url}>
      <Grid container spacing={2}>
        <Grid item>
          <img className="iconCardPayment" src={iconCreditCard} />
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
            Pay with {provider?.title || provider?.provider?.name || 'MTI ISO'}
          </Typography>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Box marginTop={[2, 4]} />

      {paymentError ? (
        <PaymentError
          error={paymentError as string}
          onBack={() => {
            setPaymentError(undefined)
            onPaymentCancelled()
          }}
        />
      ) : (
        <form method="post" onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FilledTextField
                variant="filled"
                fullWidth={true}
                label="Name"
                size="medium"
                required={true}
                disabled={submitting}
                placeholder="Full name printed on the card"
                defaultValue={cardName}
                onChange={(e: any) => {
                  setCardName(e.target.value)
                }}
                helperText={' '}
                InputProps={{
                  readOnly: useToken
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FilledTextField
                fullWidth={true}
                variant="filled"
                label="Card number"
                color="primary"
                size="medium"
                required={true}
                disabled={submitting}
                inputRef={getCardNumberProps().ref}
                inputProps={getCardNumberProps({
                  onChange: handleChangeCardNumber,
                  placeholder: '0000 0000 0000 0000'
                })}
                defaultValue={cardNumber}
                error={meta.touchedInputs.cardNumber && meta.erroredInputs.cardNumber ? true : false}
                helperText={meta.touchedInputs.cardNumber && meta.erroredInputs.cardNumber ? meta.erroredInputs.cardNumber : ' '}
                InputProps={{
                  readOnly: useToken,
                  endAdornment: (
                    <InputAdornment position="end">
                      <svg fontSize={36} {...getCardImageProps({ images })} />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <FilledTextField
                fullWidth={true}
                variant="filled"
                label="Expiry date"
                color="primary"
                size="medium"
                required={true}
                disabled={submitting}
                placeholder=""
                inputRef={getExpiryDateProps().ref}
                inputProps={getExpiryDateProps({
                  onChange: handleChangeExpiryDate,
                  placeholder: 'MM / YY'
                })}
                defaultValue={expiryDate}
                error={meta.touchedInputs.expiryDate && meta.erroredInputs.expiryDate ? true : false}
                helperText={meta.touchedInputs.expiryDate && meta.erroredInputs.expiryDate ? meta.erroredInputs.expiryDate : ' '}
                InputProps={{
                  readOnly: useToken
                }}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <FilledTextField
                fullWidth={true}
                type="password"
                variant="filled"
                label="CVV"
                color="primary"
                size="medium"
                required={true}
                disabled={submitting}
                inputRef={getCVCProps().ref}
                inputProps={getCVCProps({
                  onChange: handleChangeCVC,
                  maxLength: 3,
                  placeholder: '',
                  type: 'password'
                })}
                defaultValue={cvc}
                error={meta.touchedInputs.cvc && meta.erroredInputs.cvc ? true : false}
                helperText={meta.touchedInputs.cvc && meta.erroredInputs.cvc ? meta.erroredInputs.cvc : ' '}
              />
            </Grid>

            {Features.cardinalNewField && !isEmailOrPhoneNumberApper ? (
              <>
                <Grid item xs={8} md={8}>
                  <FilledTextField
                    fullWidth={true}
                    variant="filled"
                    label="Email / Phone Number"
                    color="primary"
                    size="medium"
                    required={true}
                    disabled={submitting}
                    onChange={(e) => {
                      const value = e.target.value
                      setValue(value)

                      // Regular expressions for email and phone number validation
                    }}
                    defaultValue={value}
                    error={isError}
                    InputProps={{
                      readOnly: useToken
                    }}
                    helperText={isError ? 'Invalid Phone Number or email' : ' '}
                  />
                </Grid>
              </>
            ) : null}

            {inquiry.merchant && inquiry.merchant.tokenIsAllowed && inquiry.order?.tokenOption != 'off' ? (
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={inquiry.order?.tokenOption === 'on' ? true : false}
                      defaultChecked={saveToken}
                      name="saveToken"
                      color="primary"
                      onChange={(e) => {
                        setSaveToken(e.target.checked)
                      }}
                    />
                  }
                  label="Save card information for future use"
                />
                <FormHelperText className="tokenDesc">
                  <span>This will securely save this card information and will ask your consent for every purchases.</span>
                </FormHelperText>
              </Grid>
            ) : null}

            {installments && (
              <Grid item xs={12} md={12}>
                <InstallmentsSelectionPanel
                  inquiry={inquiry}
                  installment={installment}
                  promo={promo}
                  installments={installments}
                  onSelect={(selected) => {
                    setInstallment(selected || undefined)
                  }}
                />
              </Grid>
            )}
          </Grid>

          {submitting ? (
            <PaymentLoading />
          ) : (
            <>
              {
                <>
                  <Box marginTop={[2, 4, 4]}>
                    <ContainedButton fullWidth={true} type="submit">
                      Pay now
                    </ContainedButton>
                  </Box>

                  <Box marginTop={[1, 2, 2]}>
                    <BackToShopButton merchantName={inquiry.merchant.name} referenceUrl={inquiry.referenceUrl} />
                  </Box>
                </>
              }

              {inquiry.paymentSource ? (
                <Box></Box>
              ) : (
                <Box marginTop={[2, 4, 4]} className="contentOtherPaymentMethod">
                  <ContainedButton variant="text" fullWidth={true} onClick={onSelectOthers}>
                    Select other payment methods
                  </ContainedButton>
                </Box>
              )}
            </>
          )}
        </form>
      )}
    </Route>
  )
}

const InstallmentsSelectionPanel: React.SFC<{
  inquiry: Inquiry
  installment: Installment | null | undefined
  promo: Promo | null | undefined
  installments: Installment[] | null | undefined
  onSelect: (installment: null | Installment) => void
}> = ({ inquiry, promo, installment, installments, onSelect }) => {
  const [installmentError, setInstallmentError] = useState<string>()

  const onChangeSelection = (installment: null | Installment) => {
    if (installment?.minAmount) {
      if (inquiry.amount < installment.minAmount) {
        setInstallmentError(
          `Min amount transaction of ${Intl.NumberFormat('en-us', {
            style: 'currency',
            currency: inquiry.currency
          }).format(installment.minAmount)} is required for this installment`
        )
        return
      }
    }

    setInstallmentError(undefined)

    onSelect(installment)
  }

  const onToggleButtonChange = (event: React.MouseEvent<HTMLElement>, newInstallment: Installment | null) => {
    onChangeSelection(newInstallment)
  }

  if (!(installments && installments.length > 0)) return null

  return (
    <>
      <h2 className="installment_h2">Pay with installment?</h2>
      {installmentError ? (
        <Box marginY={2}>
          <Alert severity="warning">{installmentError}</Alert>
        </Box>
      ) : null}

      <div className="installment columns">
        <ToggleButtonGroup value={installment} exclusive onChange={onToggleButtonChange} className="toggle-button-group">
          {installments.map((installment, index) => {
            let totalAmount = inquiry.amount
            let discountAmount = 0
            if (promo) {
              if (promo && promo.discountType === 'fixed') {
                discountAmount = promo.discountValue
              } else if (promo && promo.discountType === 'percentage') {
                discountAmount = inquiry.amount * (promo.discountValue / 100)
              }

              if (discountAmount > promo.maxDiscountValue) {
                discountAmount = promo?.maxDiscountValue
              }

              discountAmount = Math.floor(discountAmount)
              totalAmount -= discountAmount
            }
            const amount = totalAmount / installment.planPeriod
            const rate = (totalAmount * installment.interestRate) / 100
            const montlyAmount = amount + rate
            return (
              <ToggleButton key={`tgbinst-${index}`} className="plan" value={installment}>
                <div className="month">
                  <span>{installment.planPeriod}</span>
                  <span>Bulan Cicilan</span>
                </div>
                <div className="price">
                  {Intl.NumberFormat('en-us', {
                    style: 'currency',
                    currency: 'idr'
                  }).format(montlyAmount)}{' '}
                  / Mo.
                </div>
              </ToggleButton>
            )
          })}
        </ToggleButtonGroup>
      </div>
    </>
  )
}

export default BNIISO
