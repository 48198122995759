import Inquiry from 'models/Inquiry'
import { useTranslation, UseTranslationOptions } from 'react-i18next'

interface IUseTranslator {
  path?: string | string[]
  options?: UseTranslationOptions
}
const useTranslator = ({ path, options }: IUseTranslator) => {
  const { t, i18n, ready } = useTranslation(path, {
    ...options,
    useSuspense: false
  })
  return { t, i18n, ready }
}

export default useTranslator
