import React, { useState, useContext, useEffect, useMemo } from 'react'
import { Box, Grid, Typography, InputLabel, makeStyles, Button, Snackbar, IconButton } from '@material-ui/core'
import Transaction from 'models/Transaction'
import FilledTextField from 'components/FilledTextField'
import ContainedButton from 'components/ContainedButton'
import LoadingComponent from 'components/LoadingComponent'
import { AppContext } from 'contexts/AppContext'
import OTPNumberInput from 'components/OTPNumberInput'
import dayjs from 'dayjs'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { Alert } from '@material-ui/lab'

const BRIDirectDebitOTP: React.FC<{ transaction: Transaction; onPaymentSubmitted: () => void }> = ({ transaction, onPaymentSubmitted }) => {
  const { submitPaymentAuthenticate, retryOtp } = useContext(AppContext)
  const [authenticationCode, setAuthenticationCode] = useState<string>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [retryDate, setRetryDate] = useState<Date>(dayjs().add(60, 's').toDate())
  const [disabled, setDisabled] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)

  const counter = transaction.statusData && transaction.statusData.counter ? transaction.statusData.counter : 0

  const onSubmit = (e: any) => {
    e && e.preventDefault && e.preventDefault()

    if (authenticationCode) {
      setSubmitting(true)
      submitPaymentAuthenticate(transaction.id, {
        authenticationCode
      })
        .then(() => {
          onPaymentSubmitted()
        })
        .catch(() => {
          setSubmitting(false)
          // setPaymentError(new ApiErrorParser(e).toString())
        })
        .finally(() => {})
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDisabled(true)
    }, 100)
  }, [])

  if (submitting) return <LoadingComponent />

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={8}>
        <form method="post" onSubmit={onSubmit}>
          <Box marginTop={[2, 4]} textAlign="center">
            <Typography variant="h4" gutterBottom>
              Purchase authentication
            </Typography>
            <Typography variant="body1">We have sent you a text message with a code to {transaction.statusData.phoneNumber}.</Typography>
            {/* <Typography variant="body1">You are paying using card</Typography> */}
            <Box marginTop={4} />
            <InputLabel>
              <h4>Enter your OTP Code below to proceed.</h4>
              <h5>Mohon masukkan kode OTP sebelum batas waktu berakhir (2 menit 30 detik).</h5>
            </InputLabel>
            <FilledTextField
              fullWidth={true}
              variant="filled"
              label="OTP Code"
              color="primary"
              size="medium"
              placeholder="Enter your OTP code here"
              inputMode="numeric"
              inputProps={{ minLength: 6, maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(e) => {
                const result = e.target.value.replace(/\D/g, '')
                setAuthenticationCode(result)
              }}
            />
            <Grid container spacing={2} justify="center">
              <Grid item xs={5}>
                <Button
                  style={{
                    fontWeight: 'bold'
                  }}
                  disableRipple={false}
                  color="primary"
                  disabled={disabled}
                  onClick={() => {
                    retryOtp({ transactionId: transaction.id })
                      .then(() => {
                        setRetryDate(dayjs().add(60, 's').toDate())
                        setDisabled(false)
                      })
                      .catch((err) => {
                        setIsError(true)
                        console.error('server error: ', err)
                        setTimeout(() => {
                          setIsError(false)
                        }, 5000)
                      })
                  }}
                >
                  Resend OTP
                </Button>{' '}
              </Grid>

              {disabled ? (
                <Grid
                  item
                  xs={2}
                  style={{
                    paddingTop: 13
                  }}
                >
                  <CountdownTimer
                    toDate={retryDate}
                    counter={counter}
                    onComplete={() => {
                      setDisabled(false)
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Snackbar open={isError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert severity="error">Maximum attempt otp</Alert>
            </Snackbar>

            <Box marginTop={[2]}>
              <ContainedButton fullWidth={true} type="submit">
                Submit
              </ContainedButton>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  )
}

const CountdownTimer: React.FC<{ toDate: Date; onComplete: () => void; counter: number }> = ({ toDate, onComplete, counter }) => {
  const renderer: (props: CountdownRenderProps) => React.ReactNode = ({ completed, formatted }) => {
    if (completed) {
      onComplete()
    }
    return (
      <>
        {counter >= 3 ? null : (
          <Typography
            style={{
              fontWeight: 'bold'
            }}
          >
            {formatted.minutes.padStart(2, '0')}:{formatted.seconds.padStart(2, '0')}
          </Typography>
        )}
      </>
    )
  }

  return <Countdown date={toDate} renderer={renderer} />
}

export default BRIDirectDebitOTP
