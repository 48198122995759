import React, { useContext, useMemo } from 'react'
import { Grid, Box, Typography, useMediaQuery, useTheme, makeStyles } from '@material-ui/core'
import ContainedButton from 'components/ContainedButton'
import Warning from '@material-ui/icons/Warning'
import Transaction from 'models/Transaction'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import StorageManager from 'utils/StorageManager'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import useTranslator from 'hooks/useTranslator'
import { AppContext } from 'contexts/AppContext'
import Inquiry from 'models/Inquiry'

const TransactionDeclined: React.FC<{ transaction: Transaction; onBack?: () => void; backgroundImage?: string; inquiry?: Inquiry }> = ({
  transaction,
  onBack,
  backgroundImage,
  inquiry
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))
  const { inquiryCode } = useParams<{ inquiryCode: string }>()
  const { revertBackground } = useContext(AppContext)

  const { t } = useTranslator({
    path: 'common'
  })

  React.useEffect(() => {
    StorageManager.remove(`${inquiryCode}-runner`)
    if (backgroundImage) {
      revertBackground(backgroundImage)
    }
  }, [])

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} md={10}>
        <Box textAlign="center">
          <Warning fontSize="large" color="primary" style={{ fontSize: '8em', margin: 60 }} />

          <Typography gutterBottom align="center" variant={mobileDevice ? 'h6' : 'h4'} color="error">
            {t('transaction_decline')}
          </Typography>
          <Typography gutterBottom align="center" variant="subtitle2">
            {t('unable_proceed_transaction')}
          </Typography>
          <Typography gutterBottom align="center" variant="subtitle2">
            <CountdownTimerSecond transactionId={transaction.id} updatedTime={transaction.updatedTime} onBack={onBack} t={t} loadDate={dayjs().toDate()} />
          </Typography>
          {!inquiry || !inquiry.order || inquiry.order.channel != 'paymentlink' ? (
            <Box marginTop={[2, 4]}>
              {onBack ? (
                <ContainedButton id="back-button" onClick={onBack} fullWidth className={classes.containedButton}>
                  {t('go_back')}
                </ContainedButton>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  )
}

const CountdownTimerSecond: React.FunctionComponent<{
  updatedTime: Date
  transactionId: string
  onBack?: () => void
  t: (payload: any) => any
  loadDate: Date
}> = ({ updatedTime, transactionId, onBack, t, loadDate }) => {
  const classes = useStyles()

  const countDownTime = useMemo(() => dayjs(loadDate).add(10, 's'), [])

  const renderer: (props: CountdownRenderProps) => React.ReactNode = ({ seconds, completed }) => {
    if (completed) {
      const form = document.getElementById('back-button')
      if (form) form.click()
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={3}>
            <Grid item className={classes.timerItem}>
              <Typography className={classes.timerItemSubUnitSecond}>
                {t('redirected_in')} {seconds} {t('second')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <Countdown date={countDownTime.toDate()} renderer={renderer} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },
  imageContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    width: 480,
    maxHeight: 260,
    height: 'auto'
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
  timerItem: {
    color: theme.palette.primary.main,
    minHeight: 60,
    textAlign: 'center'
  },
  timerItemSubUnitSecond: {
    fontSize: '1.2em',
    color: 'black',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em'
    }
  },
  containedButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: '1px solid',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))

export default TransactionDeclined
