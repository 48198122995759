import React from 'react'
import { Promo } from 'models/Promo'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Grid, Paper } from '@material-ui/core'
import dayjs from 'dayjs'

const PromoInfoComponent: React.SFC<{ promo: Promo }> = ({ promo }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <CardContent>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              <strong>Congratulations!</strong>, We reward you with
            </Typography>
            <Typography variant="h5" component="h2" style={{ marginTop: 10 }}>
              {promo.name}
            </Typography>
            {/* <Typography className={classes.pos} color="textSecondary">
              {promo.type}
            </Typography> */}
            <Typography className={classes.pos} color="textSecondary">
              Period: {dayjs(promo.startDate).format('DD/MMM/YYYY')} - {dayjs(promo.finishDate).format('DD/MMM/YYYY')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography align="right" variant="h3">
              {promo.discountType === 'percentage' ? promo.discountValue + '%' : null}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Paper>
  )
}

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    minWidth: 275,
    backgroundColor: '#fff'
    // backgroundColor: 'rgba(255, 180, 0, 0.1)'
    // color: '#fff'
    // ff9800
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
})

export default PromoInfoComponent
