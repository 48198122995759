import React, { useContext, useState } from 'react'
import Inquiry from 'models/Inquiry'
import { Box } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'

import iconCreditCard from 'assets/images/icn-credit-card-2.svg'
import iconVa from 'assets/images/icn-va-2.svg'
import iconQRIS from 'assets/images/icn-qris.svg'
import iconEWallet from 'assets/images/icn-ewallet-2.svg'
import iconLinkAja from 'assets/images/logoLinkAja.svg'
import iconDirectDebit from 'assets/images/icn-credit-card-2.svg'
import iconOVO from 'assets/images/ovoSmall.png'
import iconShopeePay from 'assets/images/logoShopeePay.svg'
import iconGopay from 'assets/images/gopay-logo.png'
import iconBNIAPIVA from 'assets/images/bni-logo.png'
import iconIndodana from 'assets/images/indodana-big.svg'
import iconAtome from 'assets/images/atome-big.svg'

import ContainedButton from 'components/ContainedButton'
import Api from 'utils/Api'
import ApiErrorParser from 'utils/ApiErrorParser'
import LoadingComponent from 'components/LoadingComponent'
import { Alert } from '@material-ui/lab'
import Transaction from 'models/Transaction'
import MBox from 'components/MBox'
import TransactionDeclined from './TransactionDeclined'
import OVOTransactionDeclined from 'modules/ovo/OVOTransactionDeclined'
import StorageManager from 'utils/StorageManager'
import { useParams } from 'react-router-dom'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'
import iconDana from 'assets/images/dana.svg'
import useTranslator from 'hooks/useTranslator'
import { AppContext } from 'contexts/AppContext'
import PaymentLinkExpired from './PaymentLinkExpired'

const InquiryUnpaidPage: React.SFC<{ inquiry: Inquiry }> = ({ inquiry }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [transactions, setTransactions] = useState<Transaction[]>()
  const [error, setError] = useState<string>()
  const { inquiryCode } = useParams<{ inquiryCode: string }>()
  const [isPaymentLinkExpired, setIsPaymentLinkExpired] = useState<boolean>()
  const [isPaymentLinkCaptured, setIsPaymentLinkCaptured] = useState<boolean>(false)
  const [isPaymentLinkMessage, setIsPaymentLinkMessage] = useState<{ title: string; message: string }>()

  const { t } = useTranslator({
    path: 'common'
  })

  const provider = inquiry.merchant.providers

  const { revertBackground } = useContext(AppContext)

  const onBack = () => {
    // history.goBack()
    StorageManager.remove(`${inquiryCode}-runner`)
    if (inquiry && inquiry.referenceUrl) {
      window.location.href = inquiry.referenceUrl
    }
  }

  React.useEffect(
    React.useCallback(() => {
      setLoading(true)
      Api.request<Transaction[]>({
        method: 'GET',
        url: `/inquiries/${inquiry.id}/transactions`,
        params: { filter: { limit: 1 } }
      })
        .then(({ data }) => {
          // console.log('data', status, data)
          // const sortedTransactions = data.sort((a, b) => a.createdTime.valueOf() - b.createdTime.valueOf())
          // setTransactions(sortedTransactions.slice(0, 1))
          setTransactions(data)
          var currentStatus: string = ''
          var updatedStatus: string = ''
          data.forEach((value: Transaction) => {
            if (value.status !== 'captured') {
              currentStatus = value.status
            }
            if (updatedStatus == '' && value.status === 'captured') {
              updatedStatus = value.status
            }
            if (updatedStatus != '' && updatedStatus != '') {
              return
            }
          })
          if (updatedStatus == 'captured' && currentStatus != updatedStatus) {
            setIsPaymentLinkCaptured(true)
          } else if (updatedStatus == 'captured' && currentStatus == '') {
            setIsPaymentLinkMessage({
              title: 'Already Paid',
              message: 'This Payment Link has been Paid'
            })
            setIsPaymentLinkExpired(true)
          }
        })
        .catch((e) => {
          // dispatch({type: 'FLASH', payload: {}})
          setError(new ApiErrorParser(e).toString())
        })
        .finally(() => {})
    }, [inquiry]),
    [inquiry]
  )

  if (loading && !transactions)
    return (
      <>
        <LoadingComponent message={'Please wait..'} />
        <PoweredByComponent icon={PoweredByIcon} />
      </>
    )
  if (error) {
    return (
      <Box component="div">
        <Alert variant="filled" severity="error">
          {error}
        </Alert>

        <Box marginTop={4}>
          <ContainedButton onClick={() => {}} startIcon={<ArrowBack />}>
            Back
          </ContainedButton>
        </Box>
      </Box>
    )
  }
  const onClose = () => {
    if (inquiry && inquiry.referenceUrl) {
      window.location.href = inquiry.referenceUrl
    }
    StorageManager.remove(`${inquiryCode}-runner`)
  }
  if (isPaymentLinkCaptured && inquiry) {
    return <InquiryUnpaidPage inquiry={inquiry} />
  }
  if (isPaymentLinkExpired) {
    return <PaymentLinkExpired error={t(isPaymentLinkMessage?.title)} subError={t(isPaymentLinkMessage?.message)} isHideButton={true} onBack={onClose} />
  }
  return (
    <Box>
      {transactions?.map((transaction, k) => {
        if (transaction.status === 'declined') {
          revertBackground('', inquiry)
        }
        if (transaction.paymentSource === 'linkaja') {
          const providerTitle =
            provider.find((p) => p.providerId === 'linkaja')?.title || provider.find((p) => p.providerId === 'linkaja')?.provider?.name || 'LinkAja!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconLinkAja} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'gopay') {
          const providerTitle =
            provider.find((p) => p.providerId === 'gopay')?.title || provider.find((p) => p.providerId === 'gopay')?.provider?.name || 'Gopay!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconGopay} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'shopeepay') {
          const providerTitle =
            provider.find((p) => p.providerId === 'shopeepay')?.title || provider.find((p) => p.providerId === 'shopeepay')?.provider?.name || 'ShopeePay!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconShopeePay} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'indodana') {
          const providerTitle =
            provider.find((p) => p.providerId === 'indodana')?.title || provider.find((p) => p.providerId === 'indodana')?.provider?.name || 'Indodana!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconIndodana} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'atome') {
          const providerTitle =
            provider.find((p) => p.providerId === 'atome')?.title || provider.find((p) => p.providerId === 'atome')?.provider?.name || 'Atome!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconAtome} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'ovo') {
          const providerTitle = provider.find((p) => p.providerId === 'ovo')?.title || provider.find((p) => p.providerId === 'ovo')?.provider?.name || 'OVO!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconOVO} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <OVOTransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'mtidirectdebit') {
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconDirectDebit} title={'Pay with MTI Direct Debit'}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <LoadingComponent message="Please wait.." />
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'mtiiso') {
          const providerTitle =
            provider.find((p) => p.providerId === 'mtiiso')?.title || provider.find((p) => p.providerId === 'mtiiso')?.provider?.name || 'MTI ISO!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconDirectDebit} title={t('pay_with') + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'briapiva') {
          const providerTitle =
            provider.find((p) => p.providerId === 'briapiva')?.title ||
            provider.find((p) => p.providerId === 'briapiva')?.provider?.name ||
            'BRI Virtual Account!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconDirectDebit} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'bniapiva') {
          const providerTitle =
            provider.find((p) => p.providerId === 'bniapiva')?.title ||
            provider.find((p) => p.providerId === 'bniapiva')?.provider?.name ||
            'BNI Virtual Account!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconBNIAPIVA} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'dana') {
          const providerTitle = provider.find((p) => p.providerId === 'dana')?.title || provider.find((p) => p.providerId === 'dana')?.provider?.name || 'DANA!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconDana} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'bisnapva') {
          const providerTitle =
            provider.find((p) => p.providerId === 'bisnapva')?.title ||
            provider.find((p) => p.providerId === 'bisnapva')?.provider?.name ||
            'BI SNAP Virtual Account'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconVa} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'bisnapqr') {
          const providerTitle =
            provider.find((p) => p.providerId === 'bisnapqr')?.title || provider.find((p) => p.providerId === 'bisnapqr')?.provider?.name || 'BI SNAP QR'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconQRIS} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'ocbciso') {
          const providerTitle =
            provider.find((p) => p.providerId === 'ocbciso')?.title || provider.find((p) => p.providerId === 'ocbciso')?.provider?.name || 'OCBC ISO!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconDirectDebit} title={t('pay_with') + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'mtiqris') {
          const providerTitle =
            provider.find((p) => p.providerId === 'mtiqris')?.title || provider.find((p) => p.providerId === 'mtiqris')?.provider?.name || 'MTI QRIS'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconQRIS} title={'Pay With ' + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'bniiso') {
          const providerTitle =
            provider.find((p) => p.providerId === 'bniiso')?.title || provider.find((p) => p.providerId === 'bniiso')?.provider?.name || 'OCBC ISO!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconDirectDebit} title={t('pay_with') + providerTitle}>
              {transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
              ) : (
                <>
                  <LoadingComponent message={'Please wait..'} />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else {
          if (transaction.status === 'declined') {
            return <TransactionDeclined transaction={transaction} onBack={onBack} inquiry={inquiry} />
          }
          return <LoadingComponent key={`tpp-load-${k}`} message={'Please wait your transaction are currently ' + transaction.status} />
        }
      })}
    </Box>
  )
}

export default InquiryUnpaidPage
