import React from 'react'
import { Grid, Box, Typography, useMediaQuery, useTheme, makeStyles } from '@material-ui/core'
import ContainedButton from 'components/ContainedButton'

const PaymentLinkExpired: React.FC<{ error?: string; subError?: string; isHideButton?: boolean; onBack?: () => void }> = ({ error, subError, isHideButton, onBack }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))
  subError = subError || 'Network error due to internet connection problem your payment has not been processed Please try again'

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} md={10}>
        <Box textAlign="center">
          <div className={classes.imageContainer}>
            {/*<img alt="Error" className={classes.image} src={`${process.env.PUBLIC_URL}/images/undraw_cookie_love_ulvn.svg`} /> */}
          </div>

          <Typography gutterBottom align="center" variant={mobileDevice ? 'h6' : 'h5'} color="error">
            {error ||'Network error'}
          </Typography>
          <Typography gutterBottom align="center" variant="subtitle2">
            {subError}
          </Typography>

          <Box marginTop={[2, 4]}>
            {onBack && (isHideButton == false || isHideButton == undefined) ? (
              <ContainedButton onClick={onBack} size="large">
                Close
              </ContainedButton>
            ) : null}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },
  imageContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    width: 480,
    maxHeight: 260,
    height: 'auto'
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  }
}))

export default PaymentLinkExpired
