import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, Grid, Typography, Divider, Box, Card, CardHeader, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import LoadingComponent from 'components/LoadingComponent'

import iconVA from 'assets/images/icn-va-2.svg'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import Api from 'utils/Api'
import Inquiry from 'models/Inquiry'
import ContainedButton from 'components/ContainedButton'
import { Alert } from '@material-ui/lab'
import ApiErrorParser from 'utils/ApiErrorParser'
import { BackToShopButton } from 'components/BackToShopButton'

const AlfamartAlgo: React.FC<{ inquiry: Inquiry; onPaymentSubmitted: () => void }> = ({ inquiry, onPaymentSubmitted }) => {
  const history = useHistory()
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [paymentError, setPaymentError] = useState<string>()

  const submitPaymentRequest = () => {
    setSubmitting(true)
    Api.request({
      method: 'POST',
      url: '/payments',
      headers: {},
      data: {
        inquiryId: inquiry.id,
        amount: inquiry.amount,
        currency: inquiry.currency,
        paymentSource: 'alfamartalgo',
        paymentSourceData: {
          customerPhoneNumber: inquiry.customer?.phoneNumber
        }
      }
    })
      .then(() => {
        // setPaymentError(data.statusMessage ? data.statusMessage : 'Under development')
        setLoading(true)
        onPaymentSubmitted()
      })
      .catch((e) => {
        setSubmitting(false)
        setPaymentError(new ApiErrorParser(e).toString())
      })
  }

  const onPayConfirm = () => {
    submitPaymentRequest()
  }

  const onSelectOthers = () => {
    history.goBack()
  }

  if (loading) return <LoadingComponent message="Processing your request" />

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item>
          <img src={require('assets/images/alfamart-logo.png')} alt="Alfamart / Alfamidi" />
        </Grid>
        <Grid item style={{ paddingTop: 23 }}>
          <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
            Alfamart / Alfamidi
          </Typography>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Box marginTop={[2, 4]} />

      <Card variant="outlined">
        <CardHeader title="Alfamart / Alfamidi"></CardHeader>

        <Divider />

        <CardContent>
          <List component="ul">
            <ListItem>
              <ListItemIcon>
                <InfoOutlined color="primary" fontSize="default" />
              </ListItemIcon>
              <ListItemText
                inset={false}
                primary="Tagihan sudah termasuk biaya layanan. Pembayaran di gerai retail lain akan dikenakan biaya layanan berbeda."
                classes={{ primary: classes.listText }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <InfoOutlined color="primary" fontSize="default" />
              </ListItemIcon>
              <ListItemText inset={false} primary='Dapatkan kode pembayaran setelah klik "Pay now".' classes={{ primary: classes.listText }} />
            </ListItem>
          </List>
        </CardContent>
      </Card>

      {paymentError && (
        <Box marginTop={[2, 4, 6]}>
          <Alert severity="error">{paymentError}</Alert>
        </Box>
      )}

      <Box marginTop={[2, 4, 6]}>
        <ContainedButton fullWidth={true} onClick={onPayConfirm} disabled={submitting}>
          Pay now
        </ContainedButton>

        <Box marginTop={[1, 2, 2]}>
          <BackToShopButton merchantName={inquiry.merchant.name} referenceUrl={inquiry.referenceUrl} />
        </Box>

        {inquiry.paymentSource ? (
          <Box></Box>
        ) : (
          <Box marginTop={[2, 4, 4]}>
            <ContainedButton variant="text" fullWidth={true} onClick={onSelectOthers}>
              Select other payment methods
            </ContainedButton>
          </Box>
        )}
      </Box>

      {submitting && (
        <Box marginTop={[2, 4, 6]}>
          <LoadingComponent message="Requesting payment" />
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  listText: {
    fontWeight: 'bold'
  }
}))

export default AlfamartAlgo
