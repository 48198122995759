import React, { useContext, useState } from 'react'
import Inquiry from 'models/Inquiry'
import { Box } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import StorageManager from 'utils/StorageManager'

import iconCreditCard from 'assets/images/icn-credit-card-2.svg'
import iconVa from 'assets/images/icn-va-2.svg'
import iconQRIS from 'assets/images/icn-qris.svg'
import iconLinkAja from 'assets/images/logoLinkAja.svg'
import iconShopeePay from 'assets/images/logoShopeePay.svg'

import iconOVO from 'assets/images/ovoSmall.png'
import PoweredByIcon from 'assets/images/poweredBy.svg'
import iconKredivo from 'assets/images/logo-kredivo-big.svg'
import iconGopay from 'assets/images/gopay-logo.png'
import iconIndodana from 'assets/images/indodana-big.svg'
import iconAtome from 'assets/images/atome-big.svg'
import iconPermata from 'assets/images/permata-logo.svg'
import alfmartLogo from 'assets/images/alfamart-logo.png'

import ContainedButton from 'components/ContainedButton'
import { MandiriAPIVAAuthorized } from '../mandiriapiva/MandiriAPIVAPending'
import { OVOAuthorized } from '../ovo/OVOPending'
import Api from 'utils/Api'
import ApiErrorParser from 'utils/ApiErrorParser'
import LoadingComponent from 'components/LoadingComponent'
import PoweredByComponent from 'components/ComponentPoweredBy'
import { Alert } from '@material-ui/lab'
import Transaction from 'models/Transaction'
import MBox from 'components/MBox'
import TransactionDeclined from './TransactionDeclined'
import { LinkAjaAuthorized } from 'modules/linkaja/LinkAjaPending'
import { KredivoAuthorized } from 'modules/kredivo/KredivoPending'
import { IndodanaAuthorized } from 'modules/indodana/IndodanaPending'
import { AtomeAuthorized } from 'modules/atome/AtomePending'

import DemoCardAuthentication from 'modules/democard/DemoCardAuthentication'
import MTIDirectDebitOTP from 'modules/mtidirectdebit/MTIDirectDebitOTP'
import MTIISOAuthentication from 'modules/mtiiso/MTIISOAuthentication'
import CyberSourceAuthentication from 'modules/cybersource/CyberSourceAuthentication'
import CimbAuthentication from 'modules/cimb/CimbAuthentication'
import { useParams } from 'react-router-dom'
import { BRIAPIVAAuthorized } from '../briapiva/BRIAPIVAPending'
import iconBRIVA from 'assets/images/briva-logo.png'
import { ShopeePayAuthorized } from 'modules/shopeepay/ShopeePayPending'
import { GopayAuthorized } from 'modules/Gopay/GopayPending'
import { BNIAPIVAAuthorized } from 'modules/bniapiva/BNIAPIVAPending'
import iconBNIVA from 'assets/images/bni-logo.png'
import MtiMpgsAuthentication from 'modules/mtimpgs/MtiMpgsAuthentication'
import BriCardAuthentication from 'modules/bricard/BriCardAuthentication'
import iconDana from 'assets/images/dana.svg'
import { DanaAuthorized } from 'modules/dana/danaPending'
import { BISNAPVAAuthorized } from 'modules/bisnapva/BISNAPVAPending'
import BISNAPQRAuthorized from 'modules/bisnapqr/BISNAPQRPending'
import { PermataAPIVAAuthorized } from '../permataapiva/PermataAPIVAPending'
import { AlfamartAlgoAuthorized } from 'modules/alfamartalgo/AlfamartAlgoPending'
import OcbcisoAuthentication from 'modules/ocbciso/OcbcisoAuthentication'
import MTIQRAuthorized from 'modules/mtiqris/MTIQRISPending'
import useTranslator from 'hooks/useTranslator'
import BRIDirectDebitOTP from 'modules/bridirectdebit/BRIDirectDebitOTP'
import BNIISOAuthentication from 'modules/bniiso/BNIISOAuthentication'
import BNIDirectDebitOTP from 'modules/bnidirectdebit/BNIDirectDebitOTP'
import BTNISOAuthentication from 'modules/btniso/BTNISOAuthentication'
import { AppContext } from 'contexts/AppContext'

const InquiryPendingPage: React.FC<{
  inquiry: Inquiry
  paymentData?: any
  onPaymentConfirmed?: () => void
  styleModifier: string
}> = ({ inquiry, paymentData, onPaymentConfirmed, styleModifier }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [transactions, setTransactions] = useState<Transaction[]>()
  const [error, setError] = useState<string>()
  const { inquiryCode } = useParams<{ inquiryCode: string }>()

  const { revertBackground } = useContext(AppContext)

  const provider = inquiry.merchant.providers
  const onBackToMerchant = () => {
    StorageManager.remove(`${inquiryCode}-runner`)
    if (inquiry.referenceUrl) {
      window.location.href = inquiry.referenceUrl
    }
  }
  const { t } = useTranslator({
    path: 'common'
  })

  React.useEffect(
    React.useCallback(() => {
      setLoading(true)
      Api.request<Transaction[]>({
        method: 'GET',
        url: `/inquiries/${inquiry.id}/transactions`,
        params: { filter: { limit: 1 } }
      })
        .then(({ data }) => {
          // console.log('data', status, data)
          setTransactions(data)
        })
        .catch((e) => {
          // dispatch({type: 'FLASH', payload: {}})
          setError(new ApiErrorParser(e).toString())
        })
        .finally(() => {
          setLoading(false)
        })
    }, [inquiry]),
    [inquiry]
  )
  const onClose = () => {
    if (inquiry && inquiry.referenceUrl) {
      window.location.href = inquiry.referenceUrl
    }
  }

  if (loading && !transactions) return <LoadingComponent message={t('loading')} />

  if (error) {
    return (
      <Box component="div">
        <Alert variant="filled" severity="error">
          {error}
        </Alert>

        <Box marginTop={4}>
          <ContainedButton onClick={() => {}} startIcon={<ArrowBack />}>
            {t('back')}
          </ContainedButton>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {transactions?.map((transaction, k) => {
        if (transaction.status === 'authorized') {
          revertBackground('', inquiry)
        }
        if (transaction.paymentSource === 'linkaja') {
          const providerTitle =
            provider.find((p) => p.providerId === 'linkaja')?.title || provider.find((p) => p.providerId === 'linkaja')?.provider?.name || 'LinkAja!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconLinkAja} title={'Pay With ' + providerTitle}>
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <LinkAjaAuthorized inquiry={inquiry} transaction={transaction} />
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'shopeepay') {
          const providerTitle =
            provider.find((p) => p.providerId === 'shopeepay')?.title || provider.find((p) => p.providerId === 'shopeepay')?.provider?.name || 'ShopeePay!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconShopeePay} title={'Pay With ' + providerTitle}>
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <ShopeePayAuthorized inquiry={inquiry} transaction={transaction} />
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'indodana') {
          const providerTitle =
            provider.find((p) => p.providerId === 'indodana')?.title || provider.find((p) => p.providerId === 'indodana')?.provider?.name || 'Indodana!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconIndodana} title={'Pay With ' + providerTitle}>
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <IndodanaAuthorized inquiry={inquiry} transaction={transaction} />
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'atome') {
          const providerTitle =
            provider.find((p) => p.providerId === 'atome')?.title || provider.find((p) => p.providerId === 'atome')?.provider?.name || 'Atome!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconAtome} title={'Pay With ' + providerTitle}>
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <AtomeAuthorized inquiry={inquiry} transaction={transaction} />
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'cybersource') {
          const providerTitle =
            provider.find((p) => p.providerId === 'cybersource')?.title ||
            provider.find((p) => p.providerId === 'cybersource')?.provider?.name ||
            'CyberSource!'

          return (
            <MBox
              styleModifier={styleModifier}
              className="boxCardPayment"
              key={`tpp-load-${k}`}
              iconImgUrl={iconCreditCard}
              title={t('pay_with') + providerTitle}
            >
              {['requested', 'authorized'].includes(transaction.status) ? (
                <LoadingComponent message={'Payment processing'} />
              ) : transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onClose} inquiry={inquiry} />
              ) : transaction.status === 'authenticate' ? (
                <Box key={`trp-${k}`} component="div">
                  <CyberSourceAuthentication
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                    transaction={transaction}
                    paymentData={paymentData}
                    onCancel={onBackToMerchant}
                  />
                  {/* {styleModifier !== 'plain' && (
                    <Box marginTop={[2, 4]}>
                      <ContainedButton fullWidth onClick={onBackToMerchant}>
                        Back to {inquiry.merchant.name}
                      </ContainedButton>
                    </Box>
                  )} */}
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  {/* <PoweredByComponent icon={PoweredByIcon} /> */}
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'mtimpgs') {
          const providerTitle =
            provider.find((p) => p.providerId === 'mtimpgs')?.title || provider.find((p) => p.providerId === 'mtimpgs')?.provider?.name || 'MtiMpgs!'

          return (
            <MBox
              styleModifier={styleModifier}
              className="boxCardPayment"
              key={`tpp-load-${k}`}
              iconImgUrl={iconCreditCard}
              title={t('pay_with') + providerTitle}
            >
              {['requested', 'authorized'].includes(transaction.status) ? (
                <LoadingComponent message={t('payment_processing')} />
              ) : transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onClose} inquiry={inquiry} />
              ) : transaction.status === 'authenticate' ? (
                <Box key={`trp-${k}`} component="div">
                  <MtiMpgsAuthentication
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                    transaction={transaction}
                    paymentData={paymentData}
                    onCancel={onBackToMerchant}
                  />

                  {/* {styleModifier !== 'plain' && (
                    <Box marginTop={[2, 4]}>
                      <ContainedButton fullWidth onClick={onBackToMerchant}>
                        Back to {inquiry.merchant.name}
                      </ContainedButton>
                    </Box>
                  )} */}
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  {/* <PoweredByComponent icon={PoweredByIcon} /> */}
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'bricard') {
          const providerTitle =
            provider.find((p) => p.providerId === 'bricard')?.title || provider.find((p) => p.providerId === 'bricard')?.provider?.name || 'BriCard!'

          return (
            <MBox
              styleModifier={styleModifier}
              className="boxCardPayment"
              key={`tpp-load-${k}`}
              iconImgUrl={iconCreditCard}
              title={t('pay_with') + providerTitle}
            >
              {['requested', 'authorized'].includes(transaction.status) ? (
                <LoadingComponent message={t('payment_processing')} />
              ) : transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onClose} inquiry={inquiry} />
              ) : transaction.status === 'authenticate' ? (
                <Box key={`trp-${k}`} component="div">
                  <BriCardAuthentication
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                    transaction={transaction}
                    paymentData={paymentData}
                    onCancel={onBackToMerchant}
                    inquiry={inquiry}
                  />

                  {/* {styleModifier !== 'plain' && (
                    <Box marginTop={[2, 4]}>
                      <ContainedButton fullWidth onClick={onBackToMerchant}>
                        Back to {inquiry.merchant.name}
                      </ContainedButton>
                    </Box>
                  )} */}
                </Box>
              ) : (
                <>
                  <LoadingComponent message={t('loading')} />
                  {/* <PoweredByComponent icon={PoweredByIcon} /> */}
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'cimb') {
          const providerTitle = provider.find((p) => p.providerId === 'cimb')?.title || provider.find((p) => p.providerId === 'cimb')?.provider?.name || 'CIMB!'

          return (
            <MBox
              styleModifier={styleModifier}
              className="boxCardPayment"
              key={`tpp-load-${k}`}
              iconImgUrl={iconCreditCard}
              title={t('pay_with') + providerTitle}
            >
              {['requested', 'authorized'].includes(transaction.status) ? (
                <LoadingComponent message={'Payment processing'} />
              ) : transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onClose} inquiry={inquiry} />
              ) : transaction.status === 'authenticate' ? (
                <Box key={`trp-${k}`} component="div">
                  <CimbAuthentication
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                    transaction={transaction}
                    paymentData={paymentData}
                    onCancel={onBackToMerchant}
                  />

                  {/* {styleModifier !== 'plain' && (
                    <Box marginTop={[2, 4]}>
                      <ContainedButton fullWidth onClick={onBackToMerchant}>
                        Back to {inquiry.merchant.name}
                      </ContainedButton>
                    </Box>
                  )} */}
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  {/* <PoweredByComponent icon={PoweredByIcon} /> */}
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'mtiiso') {
          const providerTitle =
            provider.find((p) => p.providerId === 'mtiiso')?.title || provider.find((p) => p.providerId === 'mtiiso')?.provider?.name || 'MTI ISO!'

          return (
            <MBox
              styleModifier={styleModifier}
              className="boxCardPayment"
              key={`tpp-load-${k}`}
              iconImgUrl={iconCreditCard}
              title={t('pay_with') + providerTitle}
            >
              {['requested', 'authorized'].includes(transaction.status) ? (
                <LoadingComponent message={'Payment processing'} />
              ) : transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onClose} inquiry={inquiry} />
              ) : transaction.status === 'authenticate' ? (
                <Box key={`trp-${k}`} component="div">
                  <MTIISOAuthentication
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                    transaction={transaction}
                    paymentData={paymentData}
                    onCancel={onBackToMerchant}
                  />

                  {/* {styleModifier !== 'plain' && (
                    <Box marginTop={[2, 4]}>
                      <ContainedButton fullWidth onClick={onBackToMerchant}>
                        Back to {inquiry.merchant.name}
                      </ContainedButton>
                    </Box>
                  )} */}
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  {/* <PoweredByComponent icon={PoweredByIcon} /> */}
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'btniso') {
          const providerTitle =
            provider.find((p) => p.providerId === 'btniso')?.title || provider.find((p) => p.providerId === 'btniso')?.provider?.name || 'BTN ISO'

          return (
            <MBox
              styleModifier={styleModifier}
              className="boxCardPayment"
              key={`tpp-load-${k}`}
              iconImgUrl={iconCreditCard}
              title={t('pay_with') + providerTitle}
            >
              {['requested', 'authorized'].includes(transaction.status) ? (
                <LoadingComponent message={'Payment processing'} />
              ) : transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onClose} inquiry={inquiry} />
              ) : transaction.status === 'authenticate' ? (
                <Box key={`trp-${k}`} component="div">
                  <BTNISOAuthentication
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                    transaction={transaction}
                    paymentData={paymentData}
                    onCancel={onBackToMerchant}
                  />

                  {/* {styleModifier !== 'plain' && (
                    <Box marginTop={[2, 4]}>
                      <ContainedButton fullWidth onClick={onBackToMerchant}>
                        Back to {inquiry.merchant.name}
                      </ContainedButton>
                    </Box>
                  )} */}
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  {/* <PoweredByComponent icon={PoweredByIcon} /> */}
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'mtidirectdebit') {
          const providerTitle = provider.find((p) => p.providerId === 'mtidirectdebit')?.title || 'MTI Direct Debit'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconCreditCard} title={'Pay With ' + providerTitle}>
              {transaction.status === 'processing' ? (
                <Box key={`trp-${k}`} component="div">
                  <MTIDirectDebitOTP
                    transaction={transaction}
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                  />
                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <LoadingComponent message="Please wait.." />
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'bridirectdebit') {
          const providerTitle = provider.find((p) => p.providerId === 'bridirectdebit')?.title || 'BRI Direct Debit'

          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconCreditCard} title={t('pay_with') + providerTitle}>
              {transaction.status === 'processing' ? (
                <Box key={`trp-${k}`} component="div">
                  <BRIDirectDebitOTP
                    transaction={transaction}
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                  />
                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      {t('back_to')} {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <LoadingComponent message={t('loading')} />
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'bnidirectdebit') {
          const providerTitle = provider.find((p) => p.providerId === 'bnidirectdebit')?.title || 'BNI Direct Debit'

          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconCreditCard} title={t('pay_with') + providerTitle}>
              {transaction.status === 'processing' ? (
                <Box key={`trp-${k}`} component="div">
                  <BNIDirectDebitOTP
                    transaction={transaction}
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                  />
                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      {t('back_to')} {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <LoadingComponent message={t('loading')} />
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'ovo') {
          const providerTitle = provider.find((p) => p.providerId === 'ovo')?.title || provider.find((p) => p.providerId === 'ovo')?.provider?.name || 'OVO!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconOVO} title={'Pay With ' + providerTitle}>
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <OVOAuthorized inquiry={inquiry} transaction={transaction} />

                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'mtidirectdebit') {
          return (
            <MBox
              className="boxCardPayment"
              key={`tpp-load-${k}`}
              iconImgUrl={iconCreditCard}
              title={'Pay With ' + (provider.find((p) => p.providerId === 'mtidirectdebit')?.title || 'MTI Direct Debit')}
            >
              {transaction.status === 'authenticate' ? (
                <Box key={`trp-${k}`} component="div">
                  <MTIDirectDebitOTP
                    transaction={transaction}
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                  />

                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <LoadingComponent message="Please wait.." />
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'democard') {
          return (
            <DemoCardAuthentication
              inquiry={inquiry}
              transaction={transaction}
              paymentData={paymentData}
              onPaymentSubmitted={() => {
                if (onPaymentConfirmed) onPaymentConfirmed()
              }}
              onCancel={onBackToMerchant}
            />
          )
        } else if (transaction.paymentSource === 'mandiriapiva') {
          return (
            <MBox
              key={`tpp-load-${k}`}
              iconImgUrl={iconCreditCard}
              title={'Pay With ' + (provider.find((p) => p.providerId === 'mandiriapiva')?.title || 'Bank Mandiri')}
            >
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <MandiriAPIVAAuthorized transaction={transaction} inquiry={inquiry} />

                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <LoadingComponent message="Please wait.." />
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'alfamartalgo') {
          return (
            <MBox
              key={`tpp-load-${k}`}
              iconImgUrl={alfmartLogo}
              title={'Pay With ' + (provider.find((p) => p.providerId === 'alfamartalgo')?.title || 'Alfamart')}
            >
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <AlfamartAlgoAuthorized transaction={transaction} inquiry={inquiry} />

                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <LoadingComponent message="Please wait.." />
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'kredivo') {
          const providerTitle =
            provider.find((p) => p.providerId === 'kredivo')?.title || provider.find((p) => p.providerId === 'kredivo')?.provider?.name || 'Kredivo!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconKredivo} title={'Pay With ' + providerTitle}>
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <KredivoAuthorized inquiry={inquiry} transaction={transaction} />
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'briapiva') {
          const providerTitle =
            provider.find((p) => p.providerId === 'briapiva')?.title ||
            provider.find((p) => p.providerId === 'briapiva')?.provider?.name ||
            'BRI Virtual Account!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconBRIVA} title={'Pay With ' + providerTitle}>
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <BRIAPIVAAuthorized transaction={transaction} inquiry={inquiry} />

                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'gopay') {
          const providerTitle =
            provider.find((p) => p.providerId === 'gopay')?.title || provider.find((p) => p.providerId === 'gopay')?.provider?.name || 'Gopay!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconGopay} title={'Pay With ' + providerTitle}>
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <GopayAuthorized transaction={transaction} inquiry={inquiry} />

                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'bniapiva') {
          const providerTitle =
            provider.find((p) => p.providerId === 'bniapiva')?.title ||
            provider.find((p) => p.providerId === 'bniapiva')?.provider?.name ||
            'BNI Virtual Account!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconBNIVA} title={'Pay With ' + providerTitle}>
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <BNIAPIVAAuthorized transaction={transaction} inquiry={inquiry} />
                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'dana') {
          const providerTitle = provider.find((p) => p.providerId === 'dana')?.title || provider.find((p) => p.providerId === 'dana')?.provider?.name || 'DANA!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconDana} title={'Pay With ' + providerTitle}>
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <DanaAuthorized inquiry={inquiry} transaction={transaction} />
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'bisnapva') {
          const providerTitle =
            provider.find((p) => p.providerId === 'bisnapva')?.title ||
            provider.find((p) => p.providerId === 'bisnapva')?.provider?.name ||
            'BNI Virtual Account!'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconVa} title={'Pay With ' + providerTitle}>
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <BISNAPVAAuthorized transaction={transaction} inquiry={inquiry} />
                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'bisnapqr') {
          const providerTitle =
            provider.find((p) => p.providerId === 'bisnapqr')?.title || provider.find((p) => p.providerId === 'bisnapqr')?.provider?.name || 'BI SNAP QR'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconQRIS} title={'Pay with ' + providerTitle}>
              {['requested', 'authorized'].includes(transaction.status) ? (
                <Box key={`trp-${k}`} component="div">
                  <BISNAPQRAuthorized transaction={transaction} />

                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <LoadingComponent message="Please wait.." />
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'mtiqris') {
          const providerTitle = 'QRIS'
          return (
            <MBox key={`tpp-load-${k}`} iconImgUrl={iconQRIS} title={'Pay with ' + providerTitle}>
              {['requested', 'authorized'].includes(transaction.status) ? (
                <Box key={`trp-${k}`} component="div">
                  <MTIQRAuthorized transaction={transaction} inquiry={inquiry} />

                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <LoadingComponent message="Please wait.." />
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'permataapiva') {
          return (
            <MBox
              key={`tpp-load-${k}`}
              iconImgUrl={iconPermata}
              title={'Pay With ' + (provider.find((p) => p.providerId === 'permataapiva')?.title || 'Bank Permata Virtual Account')}
            >
              {transaction.status === 'authorized' ? (
                <Box key={`trp-${k}`} component="div">
                  <PermataAPIVAAuthorized transaction={transaction} inquiry={inquiry} />

                  <Box marginTop={[2, 4]}>
                    <ContainedButton fullWidth onClick={onBackToMerchant}>
                      Back to {inquiry.merchant.name}
                    </ContainedButton>
                  </Box>
                </Box>
              ) : (
                <LoadingComponent message="Please wait.." />
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'ocbciso') {
          const providerTitle =
            provider.find((p) => p.providerId === 'ocbciso')?.title || provider.find((p) => p.providerId === 'ocbciso')?.provider?.name || 'OCBC ISO!'
          return (
            <MBox
              styleModifier={styleModifier}
              className="boxCardPayment"
              key={`tpp-load-${k}`}
              iconImgUrl={iconCreditCard}
              title={t('pay_with') + providerTitle}
            >
              {['requested', 'authorized'].includes(transaction.status) ? (
                <LoadingComponent message={'Payment processing'} />
              ) : transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onClose} inquiry={inquiry} />
              ) : transaction.status === 'authenticate' ? (
                <Box key={`trp-${k}`} component="div">
                  <OcbcisoAuthentication
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                    transaction={transaction}
                    paymentData={paymentData}
                    onCancel={onBackToMerchant}
                  />

                  {/* {styleModifier !== 'plain' && (
                    <Box marginTop={[2, 4]}>
                      <ContainedButton fullWidth onClick={onBackToMerchant}>
                        Back to {inquiry.merchant.name}
                      </ContainedButton>
                    </Box>
                  )} */}
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  <PoweredByComponent icon={PoweredByIcon} />
                </>
              )}
            </MBox>
          )
        } else if (transaction.paymentSource === 'bniiso') {
          const providerTitle =
            provider.find((p) => p.providerId === 'bniiso')?.title || provider.find((p) => p.providerId === 'bniiso')?.provider?.name || 'OCBC ISO!'
          return (
            <MBox
              styleModifier={styleModifier}
              className="boxCardPayment"
              key={`tpp-load-${k}`}
              iconImgUrl={iconCreditCard}
              title={t('pay_with') + providerTitle}
            >
              {['requested', 'authorized'].includes(transaction.status) ? (
                <LoadingComponent message={'Payment processing'} />
              ) : transaction.status === 'declined' ? (
                <TransactionDeclined transaction={transaction} onBack={onClose} inquiry={inquiry} />
              ) : transaction.status === 'authenticate' ? (
                <Box key={`trp-${k}`} component="div">
                  <BNIISOAuthentication
                    onPaymentSubmitted={() => {
                      if (onPaymentConfirmed) onPaymentConfirmed()
                    }}
                    transaction={transaction}
                    paymentData={paymentData}
                    onCancel={onBackToMerchant}
                    inquiry={inquiry}
                  />

                  {/* {styleModifier !== 'plain' && (
                    <Box marginTop={[2, 4]}>
                      <ContainedButton fullWidth onClick={onBackToMerchant}>
                        Back to {inquiry.merchant.name}
                      </ContainedButton>
                    </Box>
                  )} */}
                </Box>
              ) : (
                <>
                  <LoadingComponent message="Please wait.." />
                  {/* <PoweredByComponent icon={PoweredByIcon} /> */}
                </>
              )}
            </MBox>
          )
        } else if (transaction && transaction.status === 'declined') {
          return <TransactionDeclined transaction={transaction} onBack={onClose} inquiry={inquiry} />
        } else {
          return <LoadingComponent key={`tpp-load-${k}`} message={'Please wait your transaction are currently ' + transaction.status} />
        }
      })}
    </Box>
  )
}

export default InquiryPendingPage
