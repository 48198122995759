import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // minHeight: 45
    },
    containedPrimary: {
      // color: theme.palette.primary.contrastText
      // color: '#fff'
    },
    label: {
      fontWeight: 'bold'
    },
    text: { textTransform: 'capitalize' }
  })
)

interface ButtonProps {
  merchantName: string | undefined
  referenceUrl: string | undefined
  message?: string | undefined
}

const onBackToMerchant = (referenceUrl: string | undefined) => {
  if (referenceUrl) {
    window.location.href = referenceUrl
  }
}

export const BackToShopButton: React.FC<ButtonProps> = ({ merchantName, referenceUrl, message }) => {
  const classes = useStyles()

  return (
    <Button
      className="btnBackToMerchant"
      variant="outlined"
      fullWidth={true}
      color="primary"
      size="large"
      classes={classes}
      onClick={() => {
        onBackToMerchant(referenceUrl)
      }}
    >
      {message || 'Back to'} {merchantName}
    </Button>
  )
}
