import React, { useState, useContext } from 'react'
import { Box, Grid, Typography, InputLabel } from '@material-ui/core'
import Transaction from 'models/Transaction'
import FilledTextField from 'components/FilledTextField'
import ContainedButton from 'components/ContainedButton'
import LoadingComponent from 'components/LoadingComponent'
import { AppContext } from 'contexts/AppContext'
import PaymentError from '../checkout/PaymentError'
import Inquiry from 'models/Inquiry'
import MBox from 'components/MBox'

const DemoCardAuthentication: React.FC<{
  inquiry: Inquiry
  transaction: Transaction
  paymentData?: any
  onPaymentSubmitted: () => void
  onCancel: () => void
}> = ({ inquiry, transaction, paymentData, onPaymentSubmitted, onCancel }) => {
  const ID = 'democard'

  const { submitPaymentAuthenticate } = useContext(AppContext)
  const [authenticationCode, setAuthenticationCode] = useState<string>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const provider = inquiry.merchant.providers.find((p) => p.providerId === ID)

  const onSubmit = (e: any) => {
    e && e.preventDefault && e.preventDefault()

    if (authenticationCode) {
      setSubmitting(true)

      submitPaymentAuthenticate(transaction.id, {
        authenticationCode
      })
        .then(() => {
          onPaymentSubmitted()
        })
        .catch(() => {
          setSubmitting(false)
          setError('Network error')
          // setPaymentError(new ApiErrorParser(e).toString())
        })
        .finally(() => {})
    }
  }

  if (submitting) return <LoadingComponent />

  if (error) {
    return (
      <PaymentError error={error} subError="Your payment has not been processed, Please try again after checking your internet connection." onBack={onCancel} />
    )
  }

  return (
    <MBox title={provider?.title || provider?.provider?.name || 'Authentication'}>
      <Grid container justify="center">
        <Grid item xs={12} md={8}>
          <form method="post" onSubmit={onSubmit}>
            <Box marginTop={[2, 4]} textAlign="center">
              <Typography variant="h4" gutterBottom>
                Purchase authentication
              </Typography>
              <Typography variant="body1">We have sent you a text message with a code to your registered mobile number.</Typography>
              {/* <Typography variant="body1">You are paying using card</Typography> */}

              <Box marginTop={4} />

              <InputLabel>
                <h4>Enter the Pay Code we've sent to proceed.</h4>
              </InputLabel>
              <FilledTextField
                fullWidth={true}
                variant="filled"
                label="Pay Code"
                color="primary"
                size="medium"
                placeholder="Enter your 6 digit Pay code here"
                required={true}
                inputProps={{ maxLength: 6 }}
                onChange={(e) => {
                  setAuthenticationCode(e.target.value)
                }}
                // error={meta.touchedInputs.cvc && meta.erroredInputs.cvc ? true : false}
                // helperText={meta.touchedInputs.cvc && meta.erroredInputs.cvc ? meta.erroredInputs.cvc : ' '}
                // onChange={(e: any) => {
                //   setCvc(e.target.value)
                // }}
              />

              <Box marginTop={[2]}>
                <ContainedButton fullWidth={true} type="submit">
                  Submit
                </ContainedButton>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </MBox>
  )
}

export default DemoCardAuthentication
