import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ButtonProps, Button } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // minHeight: 45
    },
    containedPrimary: {
      // color: theme.palette.primary.contrastText
      // color: '#fff'
    },
    label: {
      fontWeight: 'bold'
    },
    text: { textTransform: 'capitalize' }
  })
)

function ContainedButton(props: ButtonProps) {
  const classes = useStyles()

  return <Button variant="contained" color="primary" size="large" classes={classes} {...props} />
}

export default ContainedButton
