import React from 'react'
// @ts-ignore
import { Eclipse } from 'react-loading-io'
import PoweredByIcon from 'assets/images/poweredBy.svg'
const LoadingScreen: React.FC = () => (
  <>
    {/* <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', height: '100px', width: '100px', margin: 'auto' }}>
      <Eclipse size={160} />
      <div style={{ textAlign: 'center' }}>
        <span>powered by </span>
        <img src={PoweredByIcon} alt="img-powered" />
      </div>
    </div> */}
  </>
)

export default LoadingScreen
