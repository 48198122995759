import React from 'react'
// @ts-ignore
import { Eclipse } from 'react-loading-io'
import { Box, Typography } from '@material-ui/core'

type LoadingComponentProps = {
  message?: string
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ message }) => (
  <Box m={[4, 8, 8]} textAlign="center" id="loadingComp">
    <Eclipse size={80} style={{ margin: 10 }} />
    {message ? (
      <Typography variant="body1" color="textSecondary">
        {message}
      </Typography>
    ) : null}
  </Box>
)

export default LoadingComponent
