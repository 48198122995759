import React from 'react'
import { createStyles, fade, Theme, makeStyles } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput'

const useStylesFlat = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: '#fcfcfb',
      // minHeight: 80,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff'
      },
      '&$focused': {
        backgroundColor: '#fff',
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main
      }
    },
    focused: {}
  })
)

function FilledTextField({ InputProps, ...props }: TextFieldProps) {
  const classes = useStylesFlat()

  return <TextField InputProps={{ classes, disableUnderline: true, ...InputProps } as Partial<OutlinedInputProps>} {...props} />
}

export default FilledTextField
