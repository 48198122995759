import React from 'react'
import Transaction from 'models/Transaction'
import BriCardCardinal from './BriCardCardinal'
import LoadingComponent from 'components/LoadingComponent'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'
import Inquiry from 'models/Inquiry'
import useTranslator from 'hooks/useTranslator'

const PROVIDER = 'bricard'

const BriCardAuthentication: React.FC<{
  transaction: Transaction
  paymentData?: any
  onPaymentSubmitted: () => void
  onCancel: () => void
  inquiry: Inquiry
}> = ({ transaction, paymentData, onPaymentSubmitted, onCancel, inquiry }) => {
  const { t } = useTranslator({ path: 'card' })
  let authenticationModule = null

  if (transaction.statusData && transaction.statusData.authenticationModule) {
    authenticationModule = transaction.statusData.authenticationModule
  }
  if (authenticationModule === 'cardinal') {
    return <BriCardCardinal transaction={transaction} onPaymentSubmitted={onPaymentSubmitted} onCancel={onCancel} paymentData={paymentData} />
  } else {
    return (
      <>
        <LoadingComponent message={t('wait_for_authentication')} />
        {/* <PoweredByComponent icon={PoweredByIcon} /> */}
      </>
    )
  }
}

export default BriCardAuthentication
