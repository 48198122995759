export default class StorageManager {
  public static TTL = 3600

  public static load(key: string) {
    const body = sessionStorage.getItem(key)
    if (body) {
      try {
        const jsonBody = JSON.parse(body)
        if (jsonBody) {
          if (new Date().getTime() < jsonBody.timestamp) {
            return jsonBody.value
          } else {
            return null
          }
        } else {
          return null
        }
      } catch (err) {
        return body
      }
    }
    return null
  }

  public static save(key: string, data: any, ttl: number = StorageManager.TTL) {
    // if (typeof data === 'object') {
    //   data = JSON.stringify(data)
    // } else if (Array.isArray(data)) {
    //   data = JSON.stringify(data)
    // }

    const dataRecord = JSON.stringify({
      value: data,
      timestamp: new Date().getTime() + ttl * 1000
    })
    // tslint:disable-next-line: no-floating-promises
    sessionStorage.setItem(key, dataRecord)
  }

  public static remove(key: string) {
    return sessionStorage.removeItem(key)
  }

  public static clear() {
    return sessionStorage.clear()
  }
}
