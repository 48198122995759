import Merchant from './Merchant'
import Transaction from './Transaction'

export enum InquiryState {
  UNPAID = 'unpaid',
  PENDING = 'pending',
  PAID = 'paid',
  FAILED = 'failed'
}

export enum InquiryStatus {
  FAILED = 'failed',
  UNPAID = 'unpaid',
  PARTIAL = 'partial',
  PENDING = 'pending',
  PAID = 'paid'
}

/**
 * Inquiry model
 */
class Inquiry {
  constructor(
    public id: string,
    public createdTime: Date,
    public updatedTime: Date,
    public currency: string,
    public amount: number,
    public state: InquiryState,
    public status: InquiryStatus,
    public merchantId: string,
    public merchant: Merchant,
    public expirationTime: Date,
    public referenceId: string,
    public referenceUrl?: string,
    public paymentSource?: string,
    public paymentSourceMethod?: string,
    public customer?: { [key: string]: string },
    public order?: any,
    public items?: any,
    public transactions?: Transaction[]
  ) {}
}

export default Inquiry
