import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from 'utils/resources-translate'

const env = process.env.NODE_ENV || 'development'

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  debug: env === 'development' || false,
  react: {
    useSuspense: false
  }
})

export default i18n
