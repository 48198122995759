import React from 'react'
import BTNISOOTP from './BTNISOOTP'
import Transaction from 'models/Transaction'
import Api from 'utils/Api'
import BTNISOCardinal from './BTNISOCardinal'
import LoadingComponent from 'components/LoadingComponent'
import MBox from 'components/MBox'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'

const BTNISOAuthentication: React.FC<{ transaction: Transaction; paymentData?: any; onPaymentSubmitted: () => void; onCancel: () => void }> = ({
  transaction,
  paymentData,
  onPaymentSubmitted,
  onCancel
}) => {
  let authenticationModule = null

  if (transaction.statusData && transaction.statusData.authenticationModule) {
    authenticationModule = transaction.statusData.authenticationModule
  }
  if (authenticationModule === 'btnisootp' || authenticationModule === 'simulator') {
    return <BTNISOOTP transaction={transaction} onPaymentSubmitted={onPaymentSubmitted} />
  } else if (authenticationModule === 'cardinal') {
    return <BTNISOCardinal transaction={transaction} onPaymentSubmitted={onPaymentSubmitted} onCancel={onCancel} paymentData={paymentData} />
  } else {
    return (
      <>
        <LoadingComponent message={'Please wait for authentication'} />
        {/* <PoweredByComponent icon={PoweredByIcon} /> */}
      </>
    )
  }
}

export default BTNISOAuthentication
