import React, { useState, useContext } from 'react'
import { AppContext } from 'contexts/AppContext'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, Typography, Divider, Box } from '@material-ui/core'
import LoadingComponent from 'components/LoadingComponent'

// @ts-ignore
import { usePaymentInputs } from 'react-payment-inputs'
import iconGopay from 'assets/images/gopay-logo.png'

import { PaymentRequest } from 'utils/Api'
import Inquiry from 'models/Inquiry'
import ContainedButton from 'components/ContainedButton'
import ApiErrorParser from 'utils/ApiErrorParser'
import StorageManager from 'utils/StorageManager'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'

const Gopay: React.FunctionComponent<{ inquiry: Inquiry; onPaymentSubmitted: (data: any) => void }> = ({ inquiry, onPaymentSubmitted }) => {
  const PAYMENT_SOURCE = 'gopay'
  let { submitPayment } = useContext(AppContext)
  const { inquiryCode } = useParams<{ inquiryCode: string }>()

  const [loading, setLoading] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [paymentError, setPaymentError] = useState<string>()
  const { meta } = usePaymentInputs()

  const onSubmit = (e: any) => {
    e && e.preventDefault && e.preventDefault()

    if (!meta.error) {
      setSubmitting(true)
      const data: PaymentRequest = {
        inquiryId: inquiry.id,
        amount: inquiry.amount,
        currency: inquiry.currency,
        paymentSource: PAYMENT_SOURCE,
        paymentSourceData: {
          callbackUrl: window.location.href
        },
        inquiry: {
          order: {
            items: []
          }
        },
        customer: inquiry.customer
      }

      if (inquiry.order) {
        for (const item of inquiry.order!.items) {
          const processedItems = {
            id: inquiry.order!.id,
            name: item.name,
            quantity: item.quantity,
            amount: item.amount
          }
          data.inquiry!.order.items.push(processedItems)
        }
      }

      submitPayment(data)
        .then((data) => {
          onPaymentSubmitted(data)
          StorageManager.save(`${inquiryCode}-runner`, true)
        })
        .catch((e) => {
          console.log(e)
          setSubmitting(false)
          setPaymentError(new ApiErrorParser(e).toString())
        })
        .finally(() => {})
    }
  }

  React.useEffect(() => {
    const form = document.getElementById('gopay-direct-pay')
    if (form) {
      // StorageManager.save('submitted', true)
      //@ts-ignore
      form.click()
    }
  }, [])

  if (loading)
    return (
      <>
        <LoadingComponent message={'Processing your request'} />
        <PoweredByComponent icon={PoweredByIcon} />
      </>
    )

  const provider = inquiry.merchant.providers.find((p) => p.providerId === 'gopay')

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item>
          <img src={iconGopay} />
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
            Pay With {provider?.title || provider?.provider?.name || 'Gopay'}
          </Typography>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Box marginTop={[2, 4]} />

      <Box marginTop={[2, 4, 6]}>
        <form method="post" onSubmit={onSubmit}>
          <ContainedButton id="gopay-direct-pay" fullWidth={true} variant="text" type="submit" disabled={submitting}></ContainedButton>
        </form>
      </Box>
    </Box>
  )
}

export default Gopay
