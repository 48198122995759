import React from 'react'
import PoweredByIcon from 'assets/images/poweredBy.svg'
// @ts-ignore
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'

type PoweredByProps = {
  icon?: string
}

const PoweredByComponent: React.FC<PoweredByProps> = ({ icon }) => {
  const classes = useStyles()
  if (!icon) {
    icon = PoweredByIcon
  }
  return (
    <Box
      m={[4, 8, 8]}
      textAlign="center"
      style={{
        marginLeft: 50
      }}
    >
      <Grid container style={{ display: 'flex', marginTop: -45 }}>
        <Grid item className={classes.responsive}></Grid>
        {/* <Grid item>{icon && <img width={80} height={80} src={icon} alt="img-powered" />}</Grid> */}
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles({
  responsive: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 180,
    '@media(max-width: 1100px)': {
      marginLeft: 90
    },
    '@media(max-width: 970px)': {
      marginLeft: 90
    },
    '@media(max-width: 650px)': {
      marginLeft: 100
    },
    '@media(max-width: 600px)': {
      marginLeft: 180
    },
    '@media(max-width: 500px)': {
      marginLeft: 60
    },
    '@media(max-width: 400px)': {
      marginLeft: 20
    }
  }
})

export default PoweredByComponent
