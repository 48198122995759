import React, { useState, useContext } from 'react'
import { AppContext } from 'contexts/AppContext'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, Typography, Divider, Box } from '@material-ui/core'
import LoadingComponent from 'components/LoadingComponent'

// @ts-ignore
import { usePaymentInputs } from 'react-payment-inputs'

import { PaymentRequest } from 'utils/Api'
import Inquiry from 'models/Inquiry'
import ContainedButton from 'components/ContainedButton'
import ApiErrorParser from 'utils/ApiErrorParser'
import iconShopeePay from 'assets/images/logoShopeePay.svg'
import StorageManager from 'utils/StorageManager'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'

const ShopeePay: React.FunctionComponent<{ inquiry: Inquiry; onPaymentSubmitted: (data: any) => void }> = ({ inquiry, onPaymentSubmitted }) => {
  const PAYMENT_SOURCE = 'shopeepay'
  let { submitPayment } = useContext(AppContext)
  const history = useHistory()
  const { inquiryCode } = useParams<{ inquiryCode: string }>()

  const [loading, setLoading] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [paymentError, setPaymentError] = useState<string>()
  const { meta } = usePaymentInputs()

  const onSubmit = (e: any) => {
    e && e.preventDefault && e.preventDefault()

    if (!meta.error) {
      setSubmitting(true)
      const data: PaymentRequest = {
        inquiryId: inquiry.id,
        amount: inquiry.amount,
        currency: inquiry.currency,
        paymentSource: PAYMENT_SOURCE,
        paymentSourceData: {
          returnUrl: window.location.href
        }
      }
      submitPayment(data)
        .then((data) => {
          onPaymentSubmitted(data)
          StorageManager.save(`${inquiryCode}-runner`, true)
        })
        .catch((e) => {
          console.log(e)
          setSubmitting(false)
          setPaymentError(new ApiErrorParser(e).toString())
        })
        .finally(() => {})
    }
  }

  React.useEffect(() => {
    const form = document.getElementById('direct-pay')
    if (form) {
      // StorageManager.save('submitted', true)
      //@ts-ignore
      form.click()
    }
  }, [])

  const onSelectOthers = () => {
    history.goBack()
  }

  if (loading)
    return (
      <>
        <LoadingComponent message={'Processing your request'} />
        <PoweredByComponent icon={PoweredByIcon} />
      </>
    )

  const provider = inquiry.merchant.providers.find((p) => p.providerId === 'Shopeepay')

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item>
          <img src={iconShopeePay} />
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
            Pay With {provider?.title || provider?.provider?.name || 'ShopeePay'}
          </Typography>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Box marginTop={[2, 4]} />

      <Box marginTop={[2, 4, 6]}>
        <form method="post" onSubmit={onSubmit}>
          <ContainedButton id="direct-pay" fullWidth={true} variant="text" type="submit" disabled={submitting}></ContainedButton>
        </form>
      </Box>
    </Box>
  )
}

export default ShopeePay
