import React, { useState, useContext } from 'react'
import { AppContext } from 'contexts/AppContext'
import { useHistory } from 'react-router-dom'
import { makeStyles, Grid, Typography, Divider, Box, Card, CardHeader, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

import LoadingComponent from 'components/LoadingComponent'

import iconQRIS from 'assets/images/icn-qris.svg'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import Inquiry from 'models/Inquiry'
import Api from 'utils/Api'
import { Alert } from '@material-ui/lab'
import ContainedButton from 'components/ContainedButton'
import ApiErrorParser from 'utils/ApiErrorParser'
import scanRQImg from 'assets/images/ScanQR.svg'
import { BackToShopButton } from 'components/BackToShopButton'

const MTIQR: React.SFC<{ inquiry: Inquiry; onPaymentSubmitted: () => void }> = ({ inquiry, onPaymentSubmitted }) => {
  const { fetchPromo } = useContext(AppContext)
  const history = useHistory()
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [paymentError, setPaymentError] = useState<string>()

  const submitPaymentRequest = () => {
    setSubmitting(true)
    Api.request({
      method: 'POST',
      url: '/payments',
      headers: {},
      data: {
        inquiryId: inquiry.id,
        amount: inquiry.amount,
        currency: inquiry.currency,
        paymentSource: 'mtiqris',
        paymentSourceData: {}
      }
    })
      .then(() => {
        setLoading(true)
        onPaymentSubmitted()
      })
      .catch((e) => {
        setSubmitting(false)
        setPaymentError(new ApiErrorParser(e).toString())
      })
  }

  React.useEffect(() => {
    fetchPromo({
      inquiryId: inquiry.id,
      amount: inquiry.amount,
      currency: inquiry.currency,
      paymentSource: 'mtiqris',
      paymentSourceData: {}
    })
  }, [])

  const onPayConfirm = () => {
    submitPaymentRequest()
  }

  const onSelectOthers = () => {
    history.goBack()
  }

  if (loading) return <LoadingComponent message="Processing your request" />

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item>
          <img src={iconQRIS} />
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
            Pay with QRIS
          </Typography>
        </Grid>
      </Grid>

      <Box marginTop={[2, 4]} />
      <Card variant="outlined">
        <CardHeader title="MTI QRIS" subheader="Quick and easy payment using your preferred QRIS app"></CardHeader>

        <Divider />

        <CardContent>
          <List component="ul">
            <ListItem>
              <ListItemIcon>
                <InfoOutlined color="primary" fontSize="default" />
              </ListItemIcon>
              <ListItemText inset={false} primary="Open you preferred QRIS-supporting payment app." classes={{ primary: classes.listText }} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <InfoOutlined color="primary" fontSize="default" />
              </ListItemIcon>
              <ListItemText inset={false} primary="Scan the QR code shown on your monitor." classes={{ primary: classes.listText }} />
            </ListItem>
            <ListItem>
              <ListItemIcon></ListItemIcon>
              <Box style={{ paddingLeft: '25px' }}>
                <img style={{ width: '75%' }} src={scanRQImg} />
              </Box>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <InfoOutlined color="primary" fontSize="default" />
              </ListItemIcon>
              <ListItemText inset={false} primary="Check your payment details in the app, then tap Pay." classes={{ primary: classes.listText }} />
            </ListItem>
          </List>
        </CardContent>
      </Card>

      {paymentError && (
        <Box marginTop={[2, 4, 6]}>
          <Alert severity="error">{paymentError}</Alert>
        </Box>
      )}

      <Box marginTop={[2]}>
        <ContainedButton fullWidth={true} onClick={onPayConfirm} disabled={submitting}>
          Show QR
        </ContainedButton>

        <Box marginTop={[1, 2, 2]}>
          <BackToShopButton merchantName={inquiry.merchant.name} referenceUrl={inquiry.referenceUrl} />
        </Box>

        {inquiry.paymentSource ? (
          <Box></Box>
        ) : (
          <Box marginTop={[2, 4, 4]} className="contentOtherPaymentMethod">
            <ContainedButton variant="text" fullWidth={true} onClick={onSelectOthers}>
              Select other payment methods
            </ContainedButton>
          </Box>
        )}
      </Box>

      {submitting && (
        <Box marginTop={[2, 4, 6]}>
          <LoadingComponent message="Requesting payment" />
        </Box>
      )}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  listText: {
    fontWeight: 'bold'
  },
  paymentSourceBox: {
    marginTop: 16,
    width: '100%'
  },
  paymentSourceItem: {
    width: '100%',
    '& img': {
      marginRight: 5
    }
  }
}))

export default MTIQR
