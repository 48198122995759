import React, { useState, useContext } from 'react'
import { Box, Grid, Typography, InputLabel } from '@material-ui/core'
import Transaction from 'models/Transaction'
import FilledTextField from 'components/FilledTextField'
import ContainedButton from 'components/ContainedButton'
import LoadingComponent from 'components/LoadingComponent'
import { AppContext } from 'contexts/AppContext'
import PaymentError from '../checkout/PaymentError'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'

const CyberSourceOTP: React.FC<{ transaction: Transaction; onPaymentSubmitted: () => void }> = ({ transaction, onPaymentSubmitted }) => {
  const { submitPaymentAuthenticate } = useContext(AppContext)
  const [authenticationCode, setAuthenticationCode] = useState<string>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const onSubmit = (e: any) => {
    e && e.preventDefault && e.preventDefault()

    if (authenticationCode) {
      setSubmitting(true)

      submitPaymentAuthenticate(transaction.id, {
        authenticationCode
      })
        .then(() => {
          onPaymentSubmitted()
        })
        .catch(() => {
          setSubmitting(false)
          setError('Network error')
          // setPaymentError(new ApiErrorParser(e).toString())
        })
        .finally(() => {})
    }
  }

  if (submitting)
    return (
      <>
        <LoadingComponent message={'Please wait..'} />
        <PoweredByComponent icon={PoweredByIcon} />
      </>
    )

  if (error) {
    return <PaymentError error={error} subError="Your payment has not been processed, Please try again after checking your internet connection." />
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={8}>
        <form method="post" onSubmit={onSubmit}>
          <Box marginTop={[2, 4]} textAlign="center">
            <Typography variant="h4" gutterBottom>
              Purchase authentication
            </Typography>
            <Typography variant="body1">We have sent you a text message with a code to your registered mobile number.</Typography>
            {/* <Typography variant="body1">You are paying using card</Typography> */}

            <Box marginTop={4} />

            <InputLabel>
              <h4>Enter your OTP Code below to proceed.</h4>
              <h5>Mohon masukkan kode OTP sebelum batas waktu berakhir (2 menit).</h5>
            </InputLabel>
            <FilledTextField
              fullWidth={true}
              variant="filled"
              label="OTP Code"
              color="primary"
              size="medium"
              placeholder="Enter your OTP code here"
              required={true}
              inputProps={{ maxLength: 6 }}
              onChange={(e) => {
                setAuthenticationCode(e.target.value)
              }}
              // error={meta.touchedInputs.cvc && meta.erroredInputs.cvc ? true : false}
              // helperText={meta.touchedInputs.cvc && meta.erroredInputs.cvc ? meta.erroredInputs.cvc : ' '}
              // onChange={(e: any) => {
              //   setCvc(e.target.value)
              // }}
            />

            <Box marginTop={[2]}>
              <ContainedButton fullWidth={true} type="submit">
                Submit
              </ContainedButton>
            </Box>
            {/* <Countdown date={dayjs().add(3, 'minute').toDate()} /> */}
          </Box>
        </form>
      </Grid>
    </Grid>
  )
}

export default CyberSourceOTP
