import React, { useState } from 'react'
import Inquiry from 'models/Inquiry'
import { Box, Typography, Grid, Divider, makeStyles, Paper, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'

import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded'
import { Alert } from '@material-ui/lab'
import ContainedButton from 'components/ContainedButton'
import Transaction from 'models/Transaction'
import dayjs from 'dayjs'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import DoneOutline from '@material-ui/icons/DoneOutline'

const BISNAPVAAuthorized: React.SFC<{ inquiry: Inquiry; transaction: Transaction }> = ({ inquiry, transaction }) => {
  const classes = createStyles()
  const [checkStatusError, setCheckStatusError] = useState<string>()

  const statusData = transaction.statusData
  const onCheckStatus = () => {
    setCheckStatusError('Transaction is authorized and waiting for payment')
  }

  return (
    <Box>
      {checkStatusError && (
        <Box marginTop={[2, 4, 6]}>
          <Alert severity="error">{checkStatusError}</Alert>
        </Box>
      )}

      <Box marginTop={[2, 4]} textAlign="center" alignContent="center">
        <Typography variant="h5" component="h5" style={{ fontWeight: 'bold' }}>
          Remaining payment time
        </Typography>

        <Box marginTop={[2, 4]} />

        <CountdownTimer toDate={statusData.expireTime} />

        <Box marginTop={2}>
          <Typography variant="body1" color="textSecondary">
            Pay before {dayjs(statusData.expireTime).format('DD MMM YYYY HH:mm')} to avoid cancellation
          </Typography>
        </Box>
      </Box>

      <Box marginTop={[2, 4]}>
        <Paper>
          <Box padding={[1, 2]}>
            <Typography color="primary" style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
              Transfer to
            </Typography>
          </Box>
          <Divider />
          <Box padding={[1, 2]}>
            <Grid container>
              <Grid item xs={4}>
                <Box component="p">
                  <img src={require('assets/images/icn-va-2.svg')} alt="BISNAPVA" style={{ marginTop: 10 }} />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box component="div" textAlign="right">
                  <Typography variant="caption" component="p" color="textSecondary" style={{ textTransform: 'capitalize' }}>
                    Virtual account number
                  </Typography>

                  <Typography variant="body1" gutterBottom color="textPrimary" style={{ fontWeight: 'bold' }}>
                    {statusData.vaNumber}
                  </Typography>

                  <Typography variant="caption" component="p" color="textSecondary" style={{ textTransform: 'capitalize' }}>
                    Total amount
                  </Typography>

                  <Typography variant="body1" gutterBottom color="textPrimary" style={{ fontWeight: 'bold' }}>
                    {Intl.NumberFormat('en-us', { style: 'currency', currency: inquiry.currency }).format(inquiry?.amount)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Box marginTop={[2, 4]}>
        <ContainedButton fullWidth={true} size="large" onClick={onCheckStatus}>
          Check Payment Status
        </ContainedButton>
      </Box>
    </Box>
  )
}

const CountdownTimer: React.SFC<{ toDate: Date }> = ({ toDate }) => {
  const classes = createStyles()

  const renderer: (props: CountdownRenderProps) => React.ReactNode = ({ hours, minutes, seconds, completed }) => (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={3}>
          <Grid item className={classes.timerItem}>
            <Typography className={classes.timerItemUnit}>{hours}</Typography>
            <Typography className={classes.timerItemSubUnit}>Hour</Typography>
          </Grid>
          <Grid item className={classes.timerItem}>
            <Typography className={classes.timerItemUnit}>:</Typography>
          </Grid>
          <Grid item className={classes.timerItem}>
            <Typography className={classes.timerItemUnit}>{minutes}</Typography>
            <Typography className={classes.timerItemSubUnit}>Minute</Typography>
          </Grid>
          <Grid item className={classes.timerItem}>
            <Typography className={classes.timerItemUnit}>:</Typography>
          </Grid>
          <Grid item className={classes.timerItem}>
            <Typography className={classes.timerItemUnit}>{seconds}</Typography>
            <Typography className={classes.timerItemSubUnit}>Seconds</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  return <Countdown date={toDate} renderer={renderer} />
}

const BISNAPVACaptured: React.SFC<{ inquiry: Inquiry; transaction: Transaction }> = ({ inquiry, transaction }) => {
  return (
    <Box>
      <Box marginTop={[2, 4]} textAlign="center" alignContent="center">
        <Box marginTop={[2, 4]}>
          <DoneOutline fontSize="large" color="primary" style={{ fontSize: '8em', margin: 60 }} />
        </Box>

        <Typography variant="h4" component="h5" style={{ fontWeight: 'bold' }}>
          Payment has successfully received
        </Typography>
        <Box marginTop={2}>
          <Typography variant="body1" color="textSecondary">
            Amount received {Intl.NumberFormat('en-us', { style: 'currency', currency: inquiry.currency }).format(transaction.amount)}
          </Typography>
        </Box>
      </Box>

      {/* <Box marginTop={2}>
        <ContainedButton fullWidth={true} size="large" onClick={onCheckStatus}>
          Check Payment Status
        </ContainedButton>
      </Box> */}
    </Box>
  )
}
const createStyles = makeStyles((theme) => ({
  timerContainer: {
    flexGrow: 1
  },
  timerItem: {
    color: theme.palette.primary.main,
    minHeight: 60,
    textAlign: 'center'
  },
  timerItemUnit: {
    fontSize: '2em',
    lineHeight: 1,
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: '5em'
    }
  },
  timerItemSubUnit: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: '1em'
    }
  },
  heading: {
    fontWeight: 'bold'
  },
  instructionOl: {
    margin: 0
  },
  instructionLi: {}
}))

export { BISNAPVAAuthorized, BISNAPVACaptured }
