import { Grid, Typography, makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import useTranslator from 'hooks/useTranslator'
import Inquiry from 'models/Inquiry'
import React, { useMemo } from 'react'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import StorageManager from 'utils/StorageManager'

export const CountdownTimerSecond: React.FunctionComponent<{ updatedTime: Date; transactionId: string; inquiry: Inquiry }> = ({
  updatedTime,
  inquiry,
  transactionId
}) => {
  const classes = createStyles()
  const countDownTime = useMemo(() => dayjs(updatedTime).add(5, 's'), [])
  const { t } = useTranslator({ path: ['card', 'common'] })

  const renderer: (props: CountdownRenderProps) => React.ReactNode = ({ seconds, completed }) => {
    const finished = StorageManager.load(`${transactionId}-finished`)
    if (completed && inquiry && inquiry.referenceUrl && !finished) {
      StorageManager.save(`${transactionId}-finished`, true)
      window.location.href = inquiry.referenceUrl
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={3}>
            <Grid item className={classes.timerItem}>
              {finished ? null : (
                <Typography className={classes.timerItemSubUnitSecond}>
                  {t('common:redirected_in')} {seconds} {t('common:second')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <Countdown date={countDownTime.toDate()} renderer={renderer} />
}

const createStyles = makeStyles((theme) => ({
  timerItem: {
    color: theme.palette.primary.main,
    minHeight: 60,
    textAlign: 'center'
  },
  timerItemSubUnitSecond: {
    fontSize: '1.2em',
    color: 'black',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em'
    }
  }
}))
