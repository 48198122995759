import React, { useContext, PropsWithChildren } from 'react'
import { AppContext } from 'contexts/AppContext'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, Box } from '@material-ui/core'
import ContainedButton from 'components/ContainedButton'
import { Switch, Route, useRouteMatch, Link, Redirect, useHistory } from 'react-router-dom'
import { groupBy } from 'lodash'

import Inquiry from 'models/Inquiry'
import Merchant, { MerchantProvider } from 'models/Merchant'

import iconCreditCard from 'assets/images/icn-credit-card-2.svg'
import iconVa from 'assets/images/icn-va-2.svg'
import iconQRIS from 'assets/images/icn-qris.svg'
import iconRightArrow from 'assets/images/icn-right-arrow.svg'
import iconLinkAja from 'assets/images/logo-payment-linkaja.png'
import iconOVO from 'assets/images/ovoSmall.png'
import iconMandiri from 'assets/images/mandiri-logo.png'
import iconKredivo from 'assets/images/logo-kredivo.svg'
import iconBRIVA from 'assets/images/briva-logo.png'
import iconShopeePay from 'assets/images/shopeePay-logo.png'
import iconGopay from 'assets/images/gopay-logo.png'
import iconCIMB from 'assets/images/cimb-logo.png'
import iconBNIAPIVA from 'assets/images/bni-logo.png'
import iconIndodana from 'assets/images/indodana.svg'
import iconAtome from 'assets/images/atome-logo.svg'
import iconDana from 'assets/images/dana.svg'
import iconPermata from 'assets/images/permata-logo.svg'
import alfmartLogo from 'assets/images/alfamart-logo.png'

import PaymentError from './PaymentError'
import PromoInfoComponent from 'components/PromoInfoComponent'
import MTIDirectDebit from 'modules/mtidirectdebit/MTIDirectDebit'
import LinkAja from 'modules/linkaja/LinkAja'
import MTIISO from 'modules/mtiiso/MTIISO'
import OVO from 'modules/ovo/OVO'
import DemoCard from 'modules/democard/DemoCard'
import CyberSource from 'modules/cybersource/CyberSource'
import Cimb from 'modules/cimb/Cimb'
import MandiriAPIVA from 'modules/mandiriapiva/MandiriAPIVA'
import KredivoComponent from 'modules/kredivo/Kredivo'
import BRIAPIVA from 'modules/briapiva/BRIAPIVA'
import ShopeePay from 'modules/shopeepay/ShopeePay'
import Gopay from 'modules/Gopay/Gopay'
import BNIAPIVA from 'modules/bniapiva/BNIAPIVA'
import Indodana from 'modules/indodana/Indodana'
import Atome from 'modules/atome/Atome'
import Mtimpgs from 'modules/mtimpgs/mtimpgs'
import BriCard from 'modules/bricard/bricard'
import Dana from 'modules/dana/dana'
import BISNAPVA from 'modules/bisnapva/BISNAPVA'
import BISNAPQR from 'modules/bisnapqr/BISNAPQR'
import PermataAPIVA from 'modules/permataapiva/PermataAPIVA'
import AlfamartAlgo from 'modules/alfamartalgo/AlfamartAlgo'
import Ocbciso from 'modules/ocbciso/Ocbciso'
import MTIQR from 'modules/mtiqris/MTIQRIS'
import BRIDirectDebit from 'modules/bridirectdebit/BRIDirectDebit'
import BNIISO from 'modules/bniiso/BNIISO'
import BNIDirectDebit from 'modules/bnidirectdebit/BNIDirectDebit'
import BTNISO from 'modules/btniso/BTNISO'

const SelectPayment: React.FC<{ inquiry: Inquiry; onPaymentSubmitted: () => void; onPaymentCancelled: () => void }> = ({
  inquiry,
  onPaymentSubmitted,
  onPaymentCancelled
}) => {
  const {
    state: {
      inquiry: { promo }
    }
  } = useContext(AppContext)
  const history = useHistory()

  const { path, url } = useRouteMatch()
  React.useEffect(() => {}, [])

  if (!(inquiry.merchant.providers && inquiry.merchant.providers.length > 0)) {
    return (
      <PaymentError
        error="There are no available methods for this payment"
        subError="Please contact the merchant to setup the configuration"
        onBack={() => {
          if (inquiry.referenceUrl) window.location.href = inquiry.referenceUrl
        }}
      />
    )
  }

  const onSelectOthers = () => {
    history.replace(url)
  }

  return (
    <>
      <Switch>
        <Route exact path={`${path}/democard`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="democard" onSelectOthers={onSelectOthers}>
            <DemoCard inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/mandiriapiva`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="mandiriapiva" onSelectOthers={onSelectOthers}>
            <MandiriAPIVA inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/mtidirectdebit`}>
          <MTIDirectDebit inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
        </Route>
        <Route exact path={`${path}/linkaja`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="linkaja" onSelectOthers={onSelectOthers}>
            <LinkAja inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/shopeepay`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="shopeepay" onSelectOthers={onSelectOthers}>
            <ShopeePay inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/mtiiso`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="mtiiso" onSelectOthers={onSelectOthers}>
            <MTIISO inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/btniso`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="btniso" onSelectOthers={onSelectOthers}>
            <BTNISO inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/indodana`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="indodana" onSelectOthers={onSelectOthers}>
            <Indodana inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/atome`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="atome" onSelectOthers={onSelectOthers}>
            <Atome inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>

        <Route exact path={`${path}/ovo`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="ovo" onSelectOthers={onSelectOthers}>
            <OVO inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/cybersource`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="cybersource" onSelectOthers={onSelectOthers}>
            <CyberSource inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/mtimpgs`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="mtimpgs" onSelectOthers={onSelectOthers}>
            <Mtimpgs inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/bricard`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="bricard" onSelectOthers={onSelectOthers}>
            <BriCard inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/kredivo`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="kredivo" onSelectOthers={onSelectOthers}>
            <KredivoComponent inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/briapiva`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="briapiva" onSelectOthers={onSelectOthers}>
            <BRIAPIVA inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/gopay`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="gopay" onSelectOthers={onSelectOthers}>
            <Gopay inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/cimb`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="cimb" onSelectOthers={onSelectOthers}>
            <Cimb inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/bniapiva`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="bniapiva" onSelectOthers={onSelectOthers}>
            <BNIAPIVA inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/dana`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="dana" onSelectOthers={onSelectOthers}>
            <Dana inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/bisnapva`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="bisnapva" onSelectOthers={onSelectOthers}>
            <BISNAPVA inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/bisnapqr`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="bisnapqr" onSelectOthers={onSelectOthers}>
            <BISNAPQR inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/mtiqris`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="mtiqris" onSelectOthers={onSelectOthers}>
            <MTIQR inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/permataapiva`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="permataapiva" onSelectOthers={onSelectOthers}>
            <PermataAPIVA inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/alfamartalgo`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="alfamartalgo" onSelectOthers={onSelectOthers}>
            <AlfamartAlgo inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/ocbciso`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="ocbciso" onSelectOthers={onSelectOthers}>
            <Ocbciso inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/bridirectdebit`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="bridirectdebit" onSelectOthers={onSelectOthers}>
            <BRIDirectDebit inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/bniiso`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="bniiso" onSelectOthers={onSelectOthers}>
            <BNIISO inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}/bnidirectdebit`}>
          <PrivateRoute merchantProvider={inquiry.merchant.providers} targetProvider="bnidirectdebit" onSelectOthers={onSelectOthers}>
            <BNIDirectDebit inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onPaymentCancelled} />
          </PrivateRoute>
        </Route>
        <Route exact path={`${path}`}>
          <SelectionListComponent inquiry={inquiry} onSelectOthers={onSelectOthers} />
        </Route>
        <Route path="*">
          <PaymentError
            error="There are no available methods for this payment"
            subError="Please contact the merchant to setup the configuration"
            onBack={onSelectOthers}
            display="none"
          />
          <Box marginTop={[2, 4, 4]}>
            <ContainedButton variant="text" fullWidth={true} onClick={onSelectOthers}>
              Select other payment methods
            </ContainedButton>
          </Box>
        </Route>
      </Switch>
      {promo && <PromoInfoComponent promo={promo} />}
    </>
  )
}

const SelectionListComponent: React.FC<{ inquiry: Inquiry; onSelectOthers: () => void }> = ({ inquiry, onSelectOthers }) => {
  const {
    dispatch,
    fetchPromo
    // state: {
    //   inquiry: { promo }
    // }
  } = useContext(AppContext)
  const { path, url } = useRouteMatch()
  const classes = useStyles()
  React.useEffect(() => {
    dispatch({ type: 'SET_PROMO', payload: null })
    dispatch({ type: 'SET_INSTALLMENT', payload: null })
  }, [])

  let providers = inquiry.merchant.providers

  // Splice providers by provided inquiry paymentSource parameter
  if (inquiry.paymentSource) {
    providers = providers.filter((p) => {
      if (p.providerId === inquiry.paymentSource) {
        return p
      }
      return false
    })

    // Lets just redirect directly to the provider when theres only 1 provider
    if (providers.length === 1) {
      return <Redirect to={`${url}/${providers[0].providerId}`} />
    }
  }
  return (
    <>
      <List className={classes.listContainer}>
        <BrandNameSection merchant={inquiry.merchant} inquiry={inquiry} />
      </List>
    </>
  )
}

const BrandedPaymentMethod: React.FunctionComponent<{ merchant: Merchant; provider?: MerchantProvider; icon: string }> = ({ merchant, provider, icon }) => {
  const classes = useStyles()
  const { path, url } = useRouteMatch()
  if (provider) {
    return (
      <Link to={`${url}/${provider.providerId}`} style={{ textDecoration: 'none' }}>
        <ListItem button={true} className={classes.listItem} style={{ borderWidth: 2, borderRadius: 10, borderColor: '#ececec', borderStyle: 'solid' }}>
          <ListItemIcon className={classes.listItemIcon}>
            <img src={icon} />
          </ListItemIcon>
          <ListItemText
            primary={<Typography className={classes.listItemTextPrimary}>{provider.title || provider.provider?.name}</Typography>}
            secondary={provider.description}
            classes={{ primary: classes.listItemTextPrimary }}
          />
          <ListItemSecondaryAction>
            <img src={iconRightArrow} />
          </ListItemSecondaryAction>
        </ListItem>
      </Link>
    )
  }
  return null
}

const BrandNameSection: React.FC<{ merchant: Merchant; inquiry: Inquiry }> = ({ merchant, inquiry }) => {
  const classes = useStyles()
  const { path, url } = useRouteMatch()
  const providers = merchant.providers
  const arrayPush: any = []
  providers.map((value: any) => {
    if (
      value.providerId === 'ovo' ||
      value.providerId === 'linkaja' ||
      value.providerId === 'mtidirectdebit' ||
      value.providerId === 'mtiiso' ||
      value.providerId === 'btniso' ||
      value.providerId === 'cybersource' ||
      value.providerId === 'mtimpgs' ||
      value.providerId === 'bricard' ||
      value.providerId === 'shopeepay' ||
      value.providerId === 'democard' ||
      value.providerId === 'mandiriapiva' ||
      value.providerId === 'kredivo' ||
      value.providerId === 'briapiva' ||
      value.providerId === 'gopay' ||
      value.providerId === 'cimb' ||
      value.providerId === 'bniapiva' ||
      value.providerId === 'indodana' ||
      value.providerId === 'atome' ||
      value.providerId === 'dana' ||
      value.providerId === 'bisnapva' ||
      value.providerId === 'bisnapqr' ||
      value.providerId === 'mtiqris' ||
      value.providerId === 'permataapiva' ||
      value.providerId === 'alfamartalgo' ||
      value.providerId === 'ocbciso' ||
      value.providerId === 'bridirectdebit' ||
      value.providerId === 'bniiso' ||
      value.providerId === 'bnidirectdebit'
    ) {
      let icon = ''
      if (value.providerId === 'ovo') icon = iconOVO
      else if (value.providerId === 'linkaja') icon = iconLinkAja
      else if (value.providerId === 'mtidirectdebit') icon = iconCreditCard
      else if (value.providerId === 'mtiiso') icon = iconCreditCard
      else if (value.providerId === 'btniso') icon = iconCreditCard
      else if (value.providerId === 'cybersource') icon = iconVa
      else if (value.providerId === 'mtimpgs') icon = iconVa
      else if (value.providerId === 'bricard') icon = iconVa
      else if (value.providerId === 'democard') icon = iconCreditCard
      else if (value.providerId === 'mandiriapiva') icon = iconMandiri
      else if (value.providerId === 'alfamartalgo') icon = alfmartLogo
      else if (value.providerId === 'kredivo') icon = iconKredivo
      else if (value.providerId == 'briapiva') icon = iconBRIVA
      else if (value.providerId === 'shopeepay') icon = iconShopeePay
      else if (value.providerId === 'gopay') icon = iconGopay
      else if (value.providerId === 'cimb') icon = iconCIMB
      else if (value.providerId === 'bniapiva') icon = iconBNIAPIVA
      else if (value.providerId === 'indodana') icon = iconIndodana
      else if (value.providerId === 'atome') icon = iconAtome
      else if (value.providerId === 'dana') icon = iconDana
      else if (value.providerId === 'bisnapva') icon = iconVa
      else if (value.providerId === 'bisnapqr') icon = iconQRIS
      else if (value.providerId === 'mtiqris') icon = iconQRIS
      else if (value.providerId === 'permataapiva') icon = iconPermata
      else if (value.providerId === 'ocbciso') icon = iconCreditCard
      else if (value.providerId === 'bridirectdebit') icon = iconCreditCard
      else if (value.providerId === 'bniiso') icon = iconCreditCard
      else if (value.providerId === 'bnidirectdebit') icon = iconCreditCard
      value['icon'] = icon
      arrayPush.push(value)
    }
  })

  const grouping = groupBy(arrayPush, 'provider.category')
  return (
    <div>
      {Object.entries(grouping).map(([key, value]) => {
        return (
          <Box className={classes.listGroup}>
            <Grid container spacing={2}>
              <Grid item>
                <ListItem className={classes.listGroupTitle}>
                  <ListItemText
                    primary={
                      <Typography variant="h6" className={classes.listItemTextPrimary}>
                        {key === 'null' ? '' : key}
                      </Typography>
                    }
                    classes={{ primary: classes.listItemTextPrimary }}
                  />
                </ListItem>
              </Grid>
            </Grid>
            {value.map((x) => {
              return <BrandedPaymentMethod merchant={merchant} provider={x} icon={x.icon} />
            })}
          </Box>
        )
      })}
    </div>
  )
}

const PrivateRoute: React.FC<{
  children: PropsWithChildren<any>
  merchantProvider: MerchantProvider[]
  targetProvider?: string
  onSelectOthers: () => void
}> = ({ children, merchantProvider, targetProvider, onSelectOthers }) => {
  const findProvider = merchantProvider.find((x) => x.providerId === targetProvider)
  return findProvider ? (
    children
  ) : (
    <>
      <PaymentError
        onBack={onSelectOthers}
        display="none"
        error="Payment method is not available or not exist"
        subError="Please contact the merchant to setup the configuration"
      />
      <Box marginTop={[2, 4, 4]}>
        <ContainedButton variant="text" fullWidth={true} onClick={onSelectOthers}>
          Select other payment methods
        </ContainedButton>
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  listContainer: {
    marginTop: theme.spacing(1)
  },
  listItem: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: '#fff'
  },
  listGroupTitle: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: '#fff'
  },
  listItemIcon: {
    width: theme.spacing(2),
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  listItemTextPrimary: {
    color: '#2f2f2f',
    fontWeight: 'bold',
    fontSize: 18
  },
  listGroup: {
    paddingBottom: theme.spacing(4)
  }
}))

export default SelectPayment
