import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import shortUUID from 'short-uuid'
import LoadingScreen from 'components/LoadingScreen'
import CheckoutPage from 'modules/checkout/CheckoutPage'
import Api from 'utils/Api'
import Transaction from 'models/Transaction'
import * as CryptoJS from 'crypto-js'

const CheckoutDirectPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const { inquiryCode } = useParams<{ inquiryCode: string }>()
  const inquiryId = shortUUID().toUUID(inquiryCode)
  let [paymentData, setPaymentData] = useState<any>()

  useEffect(() => {
    Api.request<Transaction[]>({ method: 'GET', url: `/inquiries/${inquiryId}/transactions` }).then(({ data: transactions }) => {
      const transaction = transactions[0]
      Api.request<{ paymentSourceData: string }>({
        method: 'POST',
        url: `/b2b/payments-deauthenticate`,
        data: {
          transactionId: transaction.id
        }
      }).then(({ status, data }) => {
        if (status === 200 && data && data.paymentSourceData) {
          let salt = CryptoJS.MD5(`${inquiryId}${transaction.id}`).toString()
          const iv = CryptoJS.enc.Utf8.parse(salt.substring(0, 16))
          const sec = CryptoJS.enc.Utf8.parse(salt.toString())

          const enc = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Hex.parse(data.paymentSourceData)
          })
          const decBytes = CryptoJS.AES.decrypt(enc, sec, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          })
          var decData = decBytes.toString(CryptoJS.enc.Utf8)

          data.paymentSourceData = JSON.parse(decData.toString())

          setPaymentData((paymentData = data))
        }
        setLoading(false)
      })
    })
    // setLoading(false)
  }, [])

  if (loading) return <LoadingScreen />

  return <CheckoutPage paymentData={paymentData} styleModifier={'plain'} />
}

export default CheckoutDirectPage
