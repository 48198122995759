import React, { useMemo } from 'react'
import { Box, Grid, Typography, Divider, makeStyles } from '@material-ui/core'
import DoneOutline from '@material-ui/icons/DoneOutline'
import Transaction from 'models/Transaction'
import StorageManager from 'utils/StorageManager'
import iconGopay from 'assets/images/gopay-logo.png'

import dayjs from 'dayjs'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import Inquiry from 'models/Inquiry'
import ContainedButton from 'components/ContainedButton'

const GopayResult: React.FC<{ transaction: Transaction; inquiry: Inquiry }> = ({ transaction, inquiry }) => {
  const classes = createStyles()
  const providerTitle = inquiry.merchant.providers.find((p) => p.providerId === 'gopay')

  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <img src={iconGopay} alt="Gopay" />
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
            Pay with {providerTitle ? providerTitle.title : 'Gopay'}
          </Typography>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <Box margin={[2, 6]} textAlign="center">
        <DoneOutline fontSize="large" color="primary" style={{ fontSize: '8em', margin: 60 }} />

        <Typography variant="h4" color="primary" style={{ fontWeight: 'bold', padding: 10 }}>
          Payment {transaction.status}
        </Typography>
        <Typography variant="caption" color="textPrimary" style={{ marginTop: 10 }}>
          <CountdownTimerSecond transactionId={transaction.id} updatedTime={transaction.updatedTime} inquiry={inquiry} />
        </Typography>

        <Box m={8} />
      </Box>
      {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
        <ContainedButton
          fullWidth
          className={classes.containedButton}
          onClick={() => {
            if (inquiry.referenceUrl) window.location.href = inquiry.referenceUrl
          }}
        >
          Back to {inquiry.merchant.name}
        </ContainedButton>
      ) : null}
    </>
  )
}

const CountdownTimerSecond: React.FunctionComponent<{ updatedTime: Date; transactionId: string; inquiry: Inquiry }> = ({
  updatedTime,
  inquiry,
  transactionId
}) => {
  const classes = createStyles()
  const countDownTime = useMemo(() => dayjs(updatedTime).add(10, 's'), [])

  const renderer: (props: CountdownRenderProps) => React.ReactNode = ({ seconds, completed }) => {
    const finished = StorageManager.load(`${transactionId}-finished`)
    if (completed && inquiry.referenceUrl && !finished) {
      StorageManager.save(`${transactionId}-finished`, true)
      window.location.href = inquiry.referenceUrl
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={3}>
            <Grid item className={classes.timerItem}>
              {finished ? null : (
                <Typography className={classes.timerItemSubUnitSecond}>
                  You will be redirected to {inquiry.merchant.name} in {seconds} seconds
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <Countdown date={countDownTime.toDate()} renderer={renderer} />
}

const createStyles = makeStyles((theme) => ({
  timerItem: {
    color: theme.palette.primary.main,
    minHeight: 60,
    textAlign: 'center'
  },
  timerItemSubUnitSecond: {
    fontSize: '1.2em',
    color: 'black',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em'
    }
  },
  containedButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: '1px solid',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))

export default GopayResult
