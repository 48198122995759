import React from 'react'
import Transaction from 'models/Transaction'
import LoadingComponent from 'components/LoadingComponent'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'
import BNIISOCardinal from './BNIISOCardinal'
import Inquiry from 'models/Inquiry'

const BNIISOAuthentication: React.FC<{
  transaction: Transaction
  paymentData?: any
  onPaymentSubmitted: () => void
  onCancel: () => void
  inquiry: Inquiry
}> = ({ transaction, paymentData, onPaymentSubmitted, onCancel, inquiry }) => {
  let authenticationModule = null

  if (transaction.statusData && transaction.statusData.authenticationModule) {
    authenticationModule = transaction.statusData.authenticationModule
  }
  if (authenticationModule === 'cardinal') {
    return <BNIISOCardinal transaction={transaction} onPaymentSubmitted={onPaymentSubmitted} onCancel={onCancel} paymentData={paymentData} />
  } else {
    return (
      <>
        <LoadingComponent message={'Please wait for authentication'} />
        {/* <PoweredByComponent icon={PoweredByIcon} /> */}
      </>
    )
  }
}

export default BNIISOAuthentication
