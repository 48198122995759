import { AppContext } from 'contexts/AppContext'
import React, { useContext, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Paper, Typography, Divider, Box, Container, createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { renderToStaticMarkup } from 'react-dom/server'

import SelectPayment from './SelectPayment'

import shortUUID from 'short-uuid'
import LoadingScreen from 'components/LoadingScreen'
import { InquiryStatus, InquiryState } from 'models/Inquiry'
import InquiryPaidPage from './InquiryPaidPage'
import PaymentError from './PaymentError'
import PaymentLinkExpired from './PaymentLinkExpired'
import InquiryPendingPage from './InquiryPendingPage'
import StorageManager from 'utils/StorageManager'
import InquiryUnpaidPage from './InquiryUnpaidPage'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import SvgComponent from 'assets/images/background_logo'
import { lightBlue } from '@material-ui/core/colors'
import useTranslator from 'hooks/useTranslator'
import { checkCustomerLanguage } from 'utils/helper'
import Api from 'utils/Api'
import Transaction from 'models/Transaction'

let fetchInquiryTimer: null | any = null
let fetchInquiryInterval = 3000

const CheckoutPage: React.FC<{ paymentData?: any; styleModifier?: string }> = (params) => {
  const { inquiryCode } = useParams<{ inquiryCode: string }>()
  const inquiryId = shortUUID().toUUID(inquiryCode)
  const [styleFile, setStyleFile] = useState('default')

  const classes = useStyles()
  let {
    fetchPromo,
    state: {
      inquiry: { inquiry, promo }
    },
    fetchInquiry
  } = useContext(AppContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [paymentData, setPaymentData] = useState<any>(params.paymentData)
  const [submitted, setSubmitted] = useState<boolean>(StorageManager.load(`${inquiryCode}-runner`) ? true : false)
  const [isPaymentLinkExpired, setIsPaymentLinkExpired] = useState<boolean>()
  const [isPaymentLinkCaptured, setIsPaymentLinkCaptured] = useState<boolean>(false)
  const [isPaymentLinkMessage, setIsPaymentLinkMessage] = useState<{ title: string; message: string }>()
  const { t, i18n } = useTranslator({ path: 'common' })
  const fetchInquiryLoop = () => {
    if (fetchInquiryTimer !== null) {
      clearTimeout(fetchInquiryTimer)
      fetchInquiryTimer = null
    }
    fetchInquiry(inquiryId)
      .then((inquiry) => {
        setLoading(false)
        i18n.changeLanguage(checkCustomerLanguage(inquiry))
        if (inquiry) {
          setStyleFile(inquiry.merchant.merchantStyle && inquiry.merchant.merchantStyle.theme ? inquiry.merchant.merchantStyle.theme : 'default')
        }
        if (inquiry && inquiry.state === InquiryState.PENDING && inquiry.status === InquiryStatus.PENDING) {
          fetchInquiryTimer = setTimeout(fetchInquiryLoop, fetchInquiryInterval)
        } else if (inquiry && inquiry.state === InquiryState.PENDING && inquiry.status === InquiryStatus.UNPAID) {
          fetchInquiryTimer = setTimeout(fetchInquiryLoop, fetchInquiryInterval)
        } else if (inquiry && inquiry.state === InquiryState.PENDING && inquiry.status === InquiryStatus.PARTIAL) {
          fetchInquiryTimer = setTimeout(fetchInquiryLoop, fetchInquiryInterval)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 410) {
          setIsPaymentLinkMessage({
            title: 'Payment link has expired',
            message: 'Please Contact your merchant for further information or Create another Payment Link'
          })
          setIsPaymentLinkExpired(true)
          setLoading(false)
        } else {
          fetchInquiryTimer = setTimeout(fetchInquiryLoop, fetchInquiryInterval)
        }
        // We catch error, probably network error, but doont display any trace error, keep retrying
      })
  }

  const onPaymentSubmitted = (data?: any) => {
    setPaymentData(data)
    setSubmitted(true)
    fetchInquiryLoop()
  }

  const onClose = () => {
    if (inquiry && inquiry.referenceUrl) {
      window.location.href = inquiry.referenceUrl
    }
    StorageManager.remove(`${inquiryCode}-runner`)
  }

  React.useEffect(() => {
    if (inquiry) {
      if (inquiry.order && inquiry.order.channel === 'paymentlink') {
        setLoading(true)
        Api.request<Transaction[]>({
          method: 'GET',
          url: `/inquiries/${inquiry.id}/transactions`
        })
          .then(({ data }) => {
            if (inquiry == null) return
            const updatedTime = dayjs(inquiry.updatedTime).add(60, 'second').toDate()
            const currentTime = new Date()
            if (inquiry.status != InquiryStatus.PENDING && data[0] && updatedTime < currentTime) {
              setIsPaymentLinkExpired(true)
              setIsPaymentLinkMessage({
                title: 'Payment Rejected',
                message: 'Please Contact your merchant for further information or Create another Payment Link'
              })
              if (data[0].status == 'captured') {
                setIsPaymentLinkMessage({
                  title: 'Already Paid',
                  message: 'This Payment Link has been Paid'
                })
              }
            }
          })
          .catch((e) => {
            setLoading(false)
          })
          .finally(() => {
            setLoading(false)
          })
      }
      fetchPromo({
        inquiryId: inquiry.id,
        amount: inquiry.amount,
        currency: inquiry.currency,
        paymentSource: '',
        paymentSourceData: {
          customerPhoneNumber: inquiry.customer?.phoneNumber,
          customerName: inquiry.customer?.name
        }
      })
      const style = document.createElement('style')
      if (inquiry.merchant.merchantStyle) {
        style.innerHTML = inquiry.merchant.merchantStyle.css
        document.getElementsByTagName('head')[0].appendChild(style)
      }
    }
  }, [inquiry?.id, promo?.id])

  React.useEffect(() => {
    // dispatch({
    //   type: 'ADD_COUNTER',
    //   payload: { count: 1 }
    // })

    setLoading(true)
    //fetchInquiry(inquiryId).then(() => {
    //  setLoading(false)
    //})
    fetchInquiryLoop()
  }, [])

  React.useEffect(() => {
    const head = document.head
    const link = document.createElement('link')

    link.type = 'text/css'
    link.rel = 'stylesheet'

    link.href = process.env.PUBLIC_URL + '/styles/' + styleFile + '.css'

    head.appendChild(link)

    return () => {
      head.removeChild(link)
    }
  }, [styleFile])

  if (isPaymentLinkExpired) {
    return <PaymentLinkExpired error={t(isPaymentLinkMessage?.title)} subError={t(isPaymentLinkMessage?.message)} isHideButton={true} onBack={onClose} />
  }

  if (loading) return <LoadingScreen />
  else if (!inquiry) return null //TODO: Empty/not found trx

  const { merchant, amount } = inquiry

  let totalAmount = amount
  let discountAmount = 0

  if (promo) {
    if (promo && promo.discountType === 'fixed') {
      discountAmount = promo.discountValue
    } else if (promo && promo.discountType === 'percentage') {
      discountAmount = inquiry.amount * (promo.discountValue / 100)
    }

    if (discountAmount > promo.maxDiscountValue) {
      discountAmount = promo?.maxDiscountValue
    }

    discountAmount = Math.floor(discountAmount)

    totalAmount -= discountAmount
  }

  const showingLinkExpirationTime = (time: Date) => {
    if (time) {
      dayjs.extend(calendar)
      var newTime = dayjs(time).calendar(undefined, {
        sameDay: '[Today at] h:mm A',
        nextDay: '[Tomorrow] h:mm A',
        nextWeek: 'dddd, MMMM D, YYYY h:mm A',
        sameElse: 'dddd, MMMM D, YYYY h:mm A'
      })
      return newTime
    }
  }

  const merchantStyle = inquiry.merchant.merchantStyle

  const svgString = encodeURIComponent(
    renderToStaticMarkup(<SvgComponent color={merchantStyle && merchantStyle.backgroundColor ? merchantStyle.backgroundColor : null} />)
  )

  return (
    <MuiThemeProvider
      theme={theme(
        merchantStyle && merchantStyle.primaryColor ? merchantStyle.primaryColor : { ...lightBlue },
        merchantStyle && merchantStyle.secondaryColor ? merchantStyle.secondaryColor : '#fff'
      )}
    >
      <div
        id="checkout-container"
        className={`${classes.panelCheckout} mainContainer`}
        style={{
          backgroundImage: `url('data:image/svg+xml;utf8, ${svgString}')`,
          height: '100vh',
          backgroundSize: '100%'
        }}
      >
        <Container disableGutters={true} maxWidth="lg">
          <Grid container spacing={0} justify="center" className={classes.panelContainer}>
            <Grid item xs={12} md={8} lg={8} style={{ zIndex: 1 }}>
              <Paper id="paper" elevation={3} className={`${classes.panel} ${classes.panelLeft} panelLeft`} style={{ borderRadius: '10px' }}>
                {isPaymentLinkCaptured && inquiry ? (
                  <>
                    <InquiryPaidPage inquiry={inquiry} />
                  </>
                ) : InquiryState.UNPAID === inquiry.state && InquiryStatus.UNPAID === inquiry.status && !submitted && !paymentData ? (
                  <>
                    <SelectPayment inquiry={inquiry} onPaymentSubmitted={onPaymentSubmitted} onPaymentCancelled={onClose} />
                  </>
                ) : InquiryState.UNPAID === inquiry.state && InquiryStatus.UNPAID === inquiry.status ? (
                  <>
                    <InquiryUnpaidPage inquiry={inquiry} />
                  </>
                ) : InquiryState.PENDING === inquiry.state && [InquiryStatus.PENDING, InquiryStatus.PARTIAL, InquiryStatus.UNPAID].includes(inquiry.status) ? (
                  <>
                    {InquiryState.PENDING === inquiry.state &&
                    [InquiryStatus.PENDING, InquiryStatus.PARTIAL].includes(inquiry.status) &&
                    inquiry.paymentSourceMethod === 'authcapture' ? (
                      <InquiryPaidPage inquiry={inquiry} />
                    ) : (
                      <InquiryPendingPage
                        inquiry={inquiry}
                        paymentData={paymentData}
                        onPaymentConfirmed={onPaymentSubmitted}
                        styleModifier={params.styleModifier || ''}
                      />
                    )}
                  </>
                ) : [InquiryState.PAID].includes(inquiry.state) ? (
                  <>
                    <InquiryPaidPage inquiry={inquiry} />
                  </>
                ) : [InquiryState.FAILED].includes(inquiry.state) ? (
                  <>
                    <PaymentError error={t('payment_failed')} subError={t('unauthorized_or_declined_payment')} onBack={onClose} />
                  </>
                ) : (
                  <>
                    <PaymentError error={t('payment_failed')} subError={t('unauthorized_or_declined_payment')} onBack={onClose} />
                  </>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4} className="contentRight">
              <Paper
                elevation={3}
                className={`${classes.panel} ${classes.panelRight} panelRight`}
                style={{ borderRadius: '0px 10px 10px 0px', minWidth: '30em' }}
              >
                <div
                  className={classes.invoice}
                  style={{
                    backgroundColor: merchantStyle && merchantStyle.primaryColor ? merchantStyle.primaryColor : undefined
                  }}
                >
                  <div className={classes.invoice_head}>
                    {merchant?.logoUrl && <img src={merchant.logoUrl} alt={merchant.name} className={classes.invoice_logo} />}
                    <Typography variant="h5" component="h5" gutterBottom style={{ marginTop: 10, fontSize: '18px', color: '#283B42', fontWeight: 'bold' }}>
                      {merchant?.name}
                    </Typography>
                  </div>
                  <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                    {inquiry.order && (
                      <Grid container style={{ marginTop: 12 }} justify="space-between">
                        <Grid item xs={12}>
                          {/* <Typography variant="h6" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
                          Invoice <br />
                        </Typography> */}
                          {/* <Typography variant="caption" color="textSecondary" gutterBottom style={{}} align="left">
                          Invoice
                        </Typography> */}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" gutterBottom style={{ opacity: '0.5', fontSize: '14px', padding: '12px 18px 0px' }} align="left">
                            {inquiry.order.id}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {/* <Typography variant="h6" component="h6" gutterBottom color="primary" style={{ fontWeight: 'bold' }}></Typography> */}
                    <Divider style={{ backgroundColor: 'rgba(151,151,151,.1)' }} />
                    {promo ? (
                      <>
                        <Grid container style={{ marginTop: 20, padding: '10px 18px 6px' }}>
                          <Grid item xs={6}>
                            <Typography align="left" className={`${classes.invoiceNote} invoiceNote`}>
                              Amount
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography align="right" className={`${classes.invoiceNote} invoiceNote`}>
                              {Intl.NumberFormat('en-us', {
                                style: 'currency',
                                currency: inquiry.currency,
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 4
                                //@ts-ignore
                              }).format(inquiry.amount)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: 10, padding: '10px 18px 6px' }}>
                          <Grid item xs={6}>
                            <Typography align="left" className={classes.discount_total}>
                              Discount {promo.discountType === 'percentage' ? '(' + promo.discountValue + '%) ' : null}
                            </Typography>
                            <Typography align="left" className={classes.discount_note}>
                              Max Discount{' '}
                              {Intl.NumberFormat('en-us', {
                                style: 'currency',
                                currency: inquiry.currency,
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 4
                              }).format(promo.maxDiscountValue)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography align="right" className={classes.discount_total}>
                              {Intl.NumberFormat('en-us', {
                                style: 'currency',
                                currency: inquiry.currency,
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 4
                              }).format(discountAmount)}
                            </Typography>
                            <Typography align="right" className={classes.discount_note}>
                              {promo.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider style={{ marginTop: 10 }} />
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                    <Grid container style={{ backgroundColor: 'white', padding: '10px 18px 6px' }}>
                      <Grid item xs={4}>
                        <Typography align="left" className={`${classes.invoiceNote}`}>
                          {t('total')}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          align="right"
                          className={`${classes.invoiceAmount}`}
                          style={{
                            color: merchantStyle && merchantStyle.secondaryColor ? merchantStyle.secondaryColor : undefined,
                            wordBreak: 'break-all'
                          }}
                        >
                          {Intl.NumberFormat('en-us', {
                            style: 'currency',
                            currency: inquiry.currency,
                            maximumFractionDigits: 4,
                            minimumFractionDigits: 0
                          }).format(totalAmount)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {inquiry.expirationTime ? (
                  <div className={classes.panelRightInfo}>
                    <div className={classes.customerContainer}>
                      <div>
                        <Typography
                          variant="h6"
                          component="h6"
                          gutterBottom
                          style={{ marginTop: 10, color: '#283B42', fontSize: '18px', textAlign: 'center', fontWeight: 'bold' }}
                        >
                          {t('expiration_time')}
                        </Typography>
                        <Grid container style={{ backgroundColor: 'white', padding: '10px 18px 6px' }}>
                          <p>This link will expire {showingLinkExpirationTime(inquiry.expirationTime)}</p>
                        </Grid>
                      </div>
                    </div>
                  </div>
                ) : null}
                {inquiry.customer ? (
                  <div
                    className={classes.panelRightInfo}
                    style={{
                      backgroundColor: merchantStyle && merchantStyle.primaryColor ? merchantStyle.primaryColor : undefined
                    }}
                  >
                    <div className={classes.customerContainer}>
                      <div>
                        <Typography
                          variant="h6"
                          component="h6"
                          gutterBottom
                          style={{
                            marginTop: 10,
                            color: '#283B42',
                            fontSize: '18px',
                            textAlign: 'center',
                            fontWeight: 'bold'
                          }}
                        >
                          {t('customer_detail')}
                        </Typography>

                        <ul className={`${classes.customerInfoList} customerInfoList`}>
                          {inquiry.customer &&
                            Object.keys(inquiry.customer)
                              .slice(0, 4)
                              .map((k) => (
                                <li key={`oc-id-${k}`} className={classes.customerInfoLi}>
                                  <Typography variant="caption" gutterBottom color="textSecondary" style={{ textTransform: 'capitalize', paddingTop: '6px' }}>
                                    {t(k)}
                                  </Typography>
                                  <Box>
                                    <Typography variant="overline" gutterBottom color="textPrimary" style={{ fontWeight: 'bold' }}>
                                      {inquiry?.customer && (t(inquiry?.customer[k]) as string)}
                                    </Typography>
                                  </Box>
                                </li>
                              ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </MuiThemeProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  panelCheckout: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  },
  panelContainer: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    maxWidth: '130%',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8)
    }
  },
  panel: {
    padding: theme.spacing(1),
    '@media (min-width: 768px)': {
      padding: theme.spacing(2)
    }
  },
  panelLeft: {
    // minWidth: 600,
    // backgroundColor: '#F2F2F2',
    // boxShadow: '0px 16px 20px rgba(0, 0, 0, 0.15)',
    '@media (min-width: 768px)': {
      padding: theme.spacing(5)
    },
    '@media (min-width: 960px)': {
      minHeight: 780
    }
  },
  panelRight: {
    backgroundColor: '#F9F9F9',
    marginTop: 0,
    position: 'sticky',
    top: 0,
    '@media (min-width: 960px)': {
      //marginTop: theme.spacing(4)
      marginTop: '65px'
    }
    // boxShadow: '-17px 8px 26px rgba(0, 0, 0, 0.148055)'
  },
  invoice: {
    border: '1px solid #F4F4F4',
    padding: '10px',
    margin: '10px',
    backgroundColor: '#EDF6FB',
    borderRadius: '10px'
  },
  invoice_head: {
    // borderBottom: '1px solid rgba(151,151,151,.1)',
    textAlign: 'center'
  },
  invoice_logo: {
    height: theme.spacing(15),
    borderRadius: 5
  },
  invoiceNote: {
    fontWeight: 'bold',
    // color: '#00ACB9'
    color: '#333',
    fontSize: '20px'
  },
  invoiceAmount: {
    fontWeight: 'bold',
    color: '#4DA0D9',
    fontSize: '20px'
  },
  discount_total: {
    fontWeight: 'bold',
    color: '#ed4a12'
  },
  discount_note: {
    fontSize: '0.9em',
    color: '#999'
  },
  invoiceFooter: {
    //TODO: position bottom instead
    marginTop: theme.spacing(10)
  },
  panelRightInfo: {
    backgroundColor: '#EDF6FB',
    margin: '24px 11px 11px',
    borderRadius: '10px',
    padding: '10px'
  },
  customerContainer: {
    marginTop: '8px'
  },
  customerInfoList: {
    listStyleType: 'none',
    paddingLeft: '0px',
    marginTop: '0px'
  },
  customerInfoLi: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '4px 18px 0px'
  },
  title: {
    color: '#283B42',
    fontSize: '22px',
    fontWeight: 'bold'
  },
  test: {
    display: 'none'
  }
}))

const theme = (mainColor: string | { [key: string]: string }, secondaryColor: string) =>
  createMuiTheme({
    // overrides: {
    // MuiFilledInput: {
    //   // root: { backgroundColor: '#fff', minHeight: 70 },
    //   root: {
    //     border: '1px solid #e2e2e1',
    //     // overflow: 'hidden',
    //     borderRadius: 4,
    //     backgroundColor: '#fcfcfb',
    //     // transition: theme.transitions.create(['border-color', 'box-shadow']),
    //     '&:hover': {
    //       backgroundColor: '#fff'
    //     },
    //     '&$focused': {
    //       backgroundColor: '#fff'
    //       // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
    //       // borderColor: theme.palette.primary.main,
    //     }
    //   },
    //   focused: {}
    // }
    // },
    palette: {
      primary: { ...lightBlue, contrastText: secondaryColor }
    }
  })

export default CheckoutPage
