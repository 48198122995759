import React from 'react'
import { Box, Grid, Typography, Divider } from '@material-ui/core'
import DoneOutline from '@material-ui/icons/DoneOutline'
import iconOVO from 'assets/images/ovoSmall.png'
import Transaction from 'models/Transaction'
import Inquiry from 'models/Inquiry'
const OVOResult: React.FC<{ transaction: Transaction; inquiry: Inquiry }> = ({ transaction, inquiry }) => {
  const provider = inquiry.merchant.providers.find((p) => p.providerId === 'ovo')
  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <img src={iconOVO} alt="OVO" />
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
            Pay with {provider?.title || provider?.provider?.name || 'OVO'}
          </Typography>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Box margin={[2, 6]} textAlign="center">
        <DoneOutline fontSize="large" color="primary" style={{ fontSize: '8em', margin: 60 }} />

        <Typography variant="h4" color="primary" style={{ fontWeight: 'bold' }}>
          Payment {transaction.status}
        </Typography>
        <Typography variant="body1" color="textPrimary" style={{ marginTop: 10 }}>
          {provider?.title || provider?.provider?.name || 'OVO'} payment has been successfully processed
        </Typography>

        <Box m={8} />
      </Box>
    </>
  )
}

export default OVOResult
