import React, { useState } from 'react'
import { Box, makeStyles, Grid, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import Transaction from 'models/Transaction'
import { Alert } from '@material-ui/lab'
import ContainedButton from 'components/ContainedButton'

//@ts-ignore
import QRCode from 'react-qr-code'
import PaymentError from 'modules/checkout/PaymentError'
import Inquiry from 'models/Inquiry'

interface Props {
  transaction: Transaction
}

const BISNAPQRAuthorized: React.SFC<Props> = ({ transaction }) => {
  const cls = createStyles()

  const [checkStatusError, setCheckStatusError] = useState<string>()

  const qrCode = transaction.statusData.qrContent
  const nmid = transaction.statusData.referenceNo
  if (!qrCode) return <PaymentError />

  const acquirerName = 'Bank Indonesia'

  var now = new Date()
  const printVersion = '1.0.' + now.getDate() + '.' + (now.getMonth() + 1) + '.' + now.getFullYear()

  const downloadQRCode = () => {
    //@ts-ignore
    const saveSvgAsPng = require('assets/js/saveSvgAsPng.js')

    saveSvgAsPng.saveSvgAsPng(document.getElementById('qrcode')?.firstChild, 'qr.png')
  }

  const onCheckStatus = () => {
    setCheckStatusError('Transaction is authorized and waiting for payment')
  }

  const qrExpired = new Date(transaction.statusData.expireTime).toString()

  return (
    <Box>
      <Box marginTop={[2, 4]} textAlign="left" alignContent="flex-start">
        <Box marginTop={[2, 4]}>
          <Typography color="textSecondary">Please scan the following QR code to complete your payment.</Typography>

          <Typography color="textPrimary">QR Code is valid until {qrExpired}.</Typography>
        </Box>

        {checkStatusError && (
          <Box marginTop={[2, 4, 6]}>
            <Alert severity="error">{checkStatusError}</Alert>
          </Box>
        )}

        <Grid container>
          <Grid item xs={11}>
            <img src={require('assets/images/qris.png')} alt="QRIS" style={{ marginTop: 25 }} />
          </Grid>
          <Grid item xs={1}>
            <img src={require('assets/images/qr-gpn.png')} alt="GPN" style={{ marginTop: 10 }} />
          </Grid>
        </Grid>

        <Box marginTop={[2, 4]}>
          <Typography style={{ fontWeight: 'bold' }}>NMID: {nmid}</Typography>
        </Box>

        <Grid container alignItems="center" justify="center">
          <Grid item>
            <Box id="qrcode" className={cls.qrBox}>
              <QRCode value={qrCode} renderAs="canvas" bgColor="#FFFFFF" size={256} level="M" />
              {/* <Typography variant="body2" style={{ fontSize: 9 }}>
              {qrCode}
            </Typography> */}
            </Box>
          </Grid>
        </Grid>

        <Typography>Dicetak Oleh: {acquirerName}</Typography>

        <Typography>Versi cetak: {printVersion}</Typography>
      </Box>

      <Grid container justify="center">
        <Box marginTop={[2, 4]}>
          <ContainedButton fullWidth={true} size="large" onClick={downloadQRCode}>
            Download QR Code
          </ContainedButton>
        </Box>
      </Grid>

      <Grid container justify="center">
        <Box marginTop={[2, 4]}>
          <ContainedButton fullWidth={true} size="large" onClick={onCheckStatus}>
            Check Payment Status
          </ContainedButton>
        </Box>
      </Grid>
    </Box>
  )
}

const createStyles = makeStyles((theme) => ({
  qrBox: { marginTop: theme.spacing(4), marginBottom: theme.spacing(4) },
  heading: {}
}))

export default BISNAPQRAuthorized
