import React, { useContext } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import Transaction from 'models/Transaction'
import LoadingComponent from 'components/LoadingComponent'
import { AppContext } from 'contexts/AppContext'
import PaymentError from '../checkout/PaymentError'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'
import Api from 'utils/Api'
import Env from 'utils/Env'
import Inquiry from 'models/Inquiry'
import useTranslator from 'hooks/useTranslator'
import { useParams } from 'react-router-dom'
import shortUUID from 'short-uuid'
import { formatNumber } from 'utils/helper'

const SONGBIRDJS_URL =
  Env.visaMpiEnv === 'production'
    ? 'https://songbird.cardinalcommerce.com/cardinalcruise/v1/songbird.js'
    : 'https://songbirdstag.cardinalcommerce.com/cardinalcruise/v1/songbird.js'

const BriCardCardinal: React.FC<{ transaction: Transaction; onPaymentSubmitted: () => void; onCancel: () => void; paymentData?: any }> = ({
  transaction,
  onPaymentSubmitted,
  onCancel,
  paymentData
}) => {
  const { submitPaymentAuthenticate, removeBackground, revertBackground } = useContext(AppContext)
  const [submitting] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string>()
  const { inquiryCode } = useParams<{ inquiryCode: string }>()
  const inquiryId = shortUUID().toUUID(inquiryCode)

  const { t } = useTranslator({ path: ['card', 'common'] })
  const [isRedirect, setIsRedirect] = React.useState<boolean>(false)

  const postPaymentAuthenticate = (jwt?: string) => {
    return submitPaymentAuthenticate(transaction.id, {
      authenticationCode: jwt
    }).catch((err) => {
      revertBackground(inquiryId)
      setError(t('card:authentication_failure'))
    })
  }

  const cardinalSetup = (jwt: string) => {
    if (!isRedirect) {
      removeBackground(() => {
        setIsRedirect(true)
      })
    }
    // console.log('Submitting', cardName, cardNumber, expiryDate, cvc)
    //@ts-ignore
    Cardinal.configure({
      logging: {
        level: process.env.NODE_ENV === 'development' ? 'error' : 'off'
      },
      maxRequestRetries: 1
      // payment: {
      //   view: 'modal',
      //   framework: 'bootstrap3',
      //   displayLoading: false
      // }
    })

    //@ts-ignore
    Cardinal.on('error.fatal', function (err) {
      console.error('Fatal error', err)
      // revertBackground(inquiryId)
      setError(t('card:authentication_failure'))
    })
    //@ts-ignore
    Cardinal.on('error', function (err) {
      console.error('Fatal error', err)
      // revertBackground(inquiryId)
      setError(t('card:authentication_failure'))
    })

    //@ts-ignore
    Cardinal.on('payments.setupComplete', async function (data) {
      // Do something
      //console.log('payments.setupComplete', data)
      let cardFromToken = paymentData.paymentSourceData.cardNumber
      if (paymentData.useToken) {
        const { data } = await Api.post(`inquiries/${paymentData.inquiryId}/token`, {
          cvc: paymentData.paymentSourceData.cvc,
          expiryDate: paymentData.paymentSourceData?.expiryDate.replace(/\D+/g, '')
        }).catch((err) => {
          console.error('invalid error token', err)
          return {
            data: null
          }
        })
        if (data) {
          cardFromToken = data.data.cardNumber
        }
      }
      cardinalStart(cardFromToken)
    })

    //@ts-ignore
    Cardinal.on('payments.validated', function (data, jwt) {
      //console.log('payments.validated', data, jwt)
      revertBackground(inquiryId)
      if (
        data &&
        data.ErrorNumber === 0 &&
        data.Payment &&
        data.Payment.ProcessorTransactionId &&
        data.Payment.ExtendedData &&
        data.Payment.ExtendedData.Amount
      ) {
        // This means success
        postPaymentAuthenticate(jwt).then(() => {
          onPaymentSubmitted()
        })
      } else if (data && data.ErrorNumber === 0 && data.Payment && data.Payment.ExtendedData && data.Payment.ExtendedData.ChallengeCancel) {
        // Customer cancel
        postPaymentAuthenticate(jwt)
      } else if (data.ActionCode === 'ERROR') {
        setError(t('card:authentication_failure'))
      } else if (data.ErrorNumber) {
        setError(t('card:authentication_failure'))
      } else {
        postPaymentAuthenticate(jwt)
      }

      /* data.ActionCode doesnt really sent, so we'll skip this
      switch (data.ActionCode) {
        case 'SUCCESS':
          // Handle successful transaction, send JWT to backend to verify
          // {ErrorNumber: 0, ErrorDescription: "Success", Payment: {ExtendedData: {Amount: ""}, ProcessorTransactionId: "GWOjj....", Type: "CCA"}}
          break

        case 'NOACTION':
          // Handle no actionable outcome, cust may not enrolled
          // {ErrorNumber: 0, ErrorDescription: "Success", Payment: {ExtendedData: {ChallengeCancel: "01"}, ProcessorTransactionId: "GWOjj....", Type: "CCA"}}
          break

        case 'FAILURE':
          // Handle failed transaction attempt
          break

        case 'ERROR':
          // Handle service level error
          break
      }
      */
    })

    //@ts-ignore
    Cardinal.setup('init', {
      jwt: jwt
    })
  }

  const cardinalStart = (cardNumberFromServer: string) => {
    if (!(paymentData && paymentData.paymentSourceData)) {
      revertBackground(inquiryId)
      setError(t('card:no_payment_source_data'))
      return
    }

    const cardNumber = cardNumberFromServer.replace(/ /g, '')
    const expiryDate = paymentData.paymentSourceData?.expiryDate.replace(/\D+/g, '')
    const expYear = '20' + expiryDate.substr(2)
    const expMonth = expiryDate.substr(0, 2)
    const orderObject = {
      OrderDetails: {
        // Amount: parseFloat(transaction.amount).toFixed(2),
        Amount: transaction.amount + '00',
        CurrencyCode: '360'
      },
      Consumer: {
        Account: {
          AccountNumber: cardNumber,
          ExpirationMonth: expMonth,
          ExpirationYear: expYear
        }
      }
    }

    if (transaction.amount.toString().split('.').length > 1) {
      orderObject.OrderDetails.Amount = formatNumber(transaction.amount)
    }
    //console.log('Starting cardinal', orderObject)
    //@ts-ignore
    Cardinal.start('cca', orderObject)
  }

  React.useEffect(() => {
    if (!submitting) return
    appendScript('https://cdnjs.cloudflare.com/ajax/libs/jquery/1.11.1/jquery.min.js', () => {
      appendScript(SONGBIRDJS_URL, () => {
        //@ts-ignore
        try {
          //@ts-ignore
          cardinalSetup(transaction.statusData.challengeAuthenticationCode)
        } catch (e) {
          console.error('Cardinal exception', e)
        }
      })
    })

    return () => {
      //@ts-ignore
      Cardinal.off('payments.setupComplete')
      //@ts-ignore
      Cardinal.off('payments.validated')
      removeScript(SONGBIRDJS_URL, () => {
        revertBackground(inquiryId)
      })
    }
  }, [submitting]) // eslint-disable-next-line react-hooks/exhaustive-deps
  /*
  useEffect(() => {
  const script = document.createElement('script');
  script.src = "/path/to/resource.js";
  script.async = true;
  document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);
  */

  if (error) {
    return <PaymentError error={error} subError={t('common:error_payment_process')} onBack={onCancel} />
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={12}>
        <Box marginTop={[2, 4]} textAlign="center">
          {/* <Typography variant="h4" gutterBottom>
            {t('card:authentication')}
          </Typography>
          <Typography variant="body1">{t('card:authentication_loading')}</Typography> */}
          {/* <Typography variant="body1">You are paying using card</Typography> */}

          <Box marginTop={0} textAlign="center" />

          {submitting ? (
            <>
              <LoadingComponent message={t('common:loading')} />
              {/* <PoweredByComponent icon={PoweredByIcon} /> */}
            </>
          ) : null}

          {/* <Box marginTop={[2]}>
              <ContainedButton fullWidth={true} type="submit">
                Continue
              </ContainedButton>
            </Box> */}
          {/* <Countdown date={dayjs().add(3, 'minute').toDate()} /> */}
        </Box>
      </Grid>
    </Grid>
  )
}

const appendScript = (scriptToAppend: string, onLoad: () => void) => {
  const script = document.createElement('script')
  script.src = scriptToAppend
  script.async = false
  script.onload = onLoad
  document.body.appendChild(script)
}

const removeScript = (scriptToremove: string, removeScript: () => void) => {
  let allsuspects = document.getElementsByTagName('script')
  removeScript()
  for (let i = allsuspects.length; i >= 0; i--) {
    if (allsuspects[i] && allsuspects[i].getAttribute('src') !== null && allsuspects[i].getAttribute('src')?.indexOf(`${scriptToremove}`) !== -1) {
      allsuspects[i].parentNode?.removeChild(allsuspects[i])
    }
  }
}

export default BriCardCardinal
