import React, { useContext, useState } from 'react'
import { AppContext } from 'contexts/AppContext'
import Grid from '@material-ui/core/Grid'
import { Typography, Divider, Box, InputAdornment } from '@material-ui/core'
import { Route, useRouteMatch, useHistory } from 'react-router-dom'

// @ts-ignore
import { usePaymentInputs } from 'react-payment-inputs'
// @ts-ignore
import images from 'react-payment-inputs/images'

import iconCreditCard from 'assets/images/icn-credit-card-2.svg'

import FilledTextField from 'components/FilledTextField'
import ContainedButton from 'components/ContainedButton'
import Api, { PaymentRequest } from 'utils/Api'
import PaymentLoading from '../checkout/PaymentLoading'
import PaymentError from '../checkout/PaymentError'
import Inquiry from 'models/Inquiry'
import ApiErrorParser from 'utils/ApiErrorParser'
import LoadingComponent from 'components/LoadingComponent'
import { BackToShopButton } from 'components/BackToShopButton'
import useTranslator from 'hooks/useTranslator'

const BNIDirectDebit: React.FunctionComponent<{ inquiry: Inquiry; onPaymentSubmitted: (data?: any) => void; onPaymentCancelled: () => void }> = ({
  inquiry,
  onPaymentSubmitted,
  onPaymentCancelled
}) => {
  const PAYMENT_SOURCE = 'bnidirectdebit'
  let { fetchPromo, fetchInstallments, submitPayment } = useContext(AppContext)
  const history = useHistory()
  const { url } = useRouteMatch()
  const { meta, getCardNumberProps, getCardImageProps } = usePaymentInputs()

  const { t } = useTranslator({ path: ['card', 'common'] })

  const [loading, setLoading] = useState<boolean>(true)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [paymentError, setPaymentError] = useState<string>()
  const [cardName, setCardName] = useState<string>()
  const [cardNumber, setCardNumber] = useState<string>()
  const [useToken, setUseToken] = useState<boolean>(false)
  const [saveToken, setSaveToken] = useState<boolean>(false)
  const [accountNumber, setAccountNumber] = useState<string>('')
  const providers = inquiry.merchant.providers

  const handleChangeCardNumber = (e: any) => {
    setCardNumber(e.target.value)
  }

  function generateRandomString(length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let result = ''

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      result += characters.charAt(randomIndex)
    }

    return result
  }

  const onBackToMerchant = () => {
    if (inquiry.referenceUrl) {
      window.location.href = inquiry.referenceUrl
    }
  }
  const onSubmit = (e: any) => {
    e && e.preventDefault && e.preventDefault()

    if (!meta.error) {
      setSubmitting(true)
      const data: PaymentRequest = {
        inquiryId: inquiry.id,
        amount: inquiry.amount,
        currency: inquiry.currency,
        paymentSource: PAYMENT_SOURCE,
        paymentSourceData: {
          cardNumber,
          bankAccountNo: accountNumber,
          deviceId: generateRandomString(400)
        },
        saveToken,
        useToken
      }

      submitPayment(data)
        .then(() => {
          onPaymentSubmitted(data)
        })
        .catch((e) => {
          console.log(e)
          setSubmitting(false)
          setPaymentError(new ApiErrorParser(e).toString())
        })
        .finally(() => {})
    }
  }

  const onSelectOthers = () => {
    history.goBack()
  }

  React.useEffect(() => {
    if (meta.erroredInputs && meta.erroredInputs.cardNumber) {
    } else if (cardNumber) {
      if (inquiry.order !== undefined) {
        if (!inquiry.order!.disablePromo) {
          fetchPromo({
            inquiryId: inquiry.id,
            amount: inquiry.amount,
            currency: inquiry.currency,
            paymentSource: PAYMENT_SOURCE,
            paymentSourceData: {
              cardName,
              cardNumber
            }
          })
        }
      }
    }
  }, [cardNumber])

  React.useEffect(() => {
    if (cardNumber) {
      fetchInstallments({
        inquiryId: inquiry.id,
        amount: inquiry.amount,
        currency: inquiry.currency,
        paymentSource: PAYMENT_SOURCE,
        paymentSourceData: {
          cardName,
          cardNumber
        }
      })
    }
  }, [cardNumber])

  React.useEffect(() => {
    if (inquiry.merchant.tokenIsAllowed) {
      Api.request<any>({
        method: 'GET',
        url: `/inquiries/${inquiry.id}/token`
      })
        .then(({ status, data }) => {
          if (status === 200 && data) {
            if (data.maskedData && data.maskedData.cardNumber && data.maskedData.cardName && data.maskedData.expiryDate) {
              console.log('Token', data)
              setUseToken(true)
              setCardName(data.maskedData.cardName)
              setCardNumber(data.maskedData.cardNumber)
              setSaveToken(true)
            }
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) return <LoadingComponent />

  return (
    <Route exact path={url}>
      <Grid container spacing={2}>
        <Grid item>
          <img className="iconCardPayment" src={iconCreditCard} />
        </Grid>
        <Grid item>
          <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
            {t('common:pay_with')} {providers.find((p) => p.providerId === 'bnidirectdebit')?.title || 'Direct Debit BNI'}
          </Typography>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Box marginTop={[2, 4]} />

      {paymentError ? (
        <PaymentError
          error={paymentError as string}
          onBack={() => {
            setPaymentError(undefined)
            onPaymentCancelled()
          }}
        />
      ) : (
        <form method="post" onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FilledTextField
                fullWidth={true}
                variant="filled"
                label={t('card:card_number_label')}
                color="primary"
                size="medium"
                required={true}
                disabled={submitting}
                inputRef={getCardNumberProps().ref}
                inputProps={getCardNumberProps({
                  onChange: handleChangeCardNumber,
                  placeholder: '0000 0000 0000 0000'
                })}
                defaultValue={cardNumber}
                error={meta.touchedInputs.cardNumber && meta.erroredInputs.cardNumber ? true : false}
                helperText={meta.touchedInputs.cardNumber && meta.erroredInputs.cardNumber ? t(`card:card_number_invalid`) : ' '}
                InputProps={{
                  readOnly: useToken,
                  endAdornment: (
                    <InputAdornment position="end">
                      <svg fontSize={36} {...getCardImageProps({ images })} />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FilledTextField
                fullWidth={true}
                variant="filled"
                label={t('card:account_number_label')}
                color="primary"
                size="medium"
                required={true}
                disabled={submitting}
                value={accountNumber}
                inputProps={{ minLength: 1, maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }}
                onChange={(e) => {
                  const result = e.target.value.replace(/\D/g, '')
                  setAccountNumber(result)
                }}
              />
            </Grid>
          </Grid>

          {submitting ? (
            <PaymentLoading />
          ) : (
            <>
              {
                <>
                  <Box marginTop={[2, 4, 4]}>
                    <ContainedButton fullWidth={true} type="submit">
                      {t('common:pay_now')}
                    </ContainedButton>
                  </Box>
                  <Box marginTop={[1, 2, 2]}>
                    <BackToShopButton message={t('common:back_to')} merchantName={inquiry.merchant.name} referenceUrl={inquiry.referenceUrl} />
                  </Box>
                </>
              }

              {inquiry.paymentSource ? (
                <Box></Box>
              ) : (
                <Box marginTop={[2, 4, 4]} className="contentOtherPaymentMethod">
                  <ContainedButton variant="text" fullWidth={true} onClick={onSelectOthers}>
                    {t('common:select_other_payment_method')}
                  </ContainedButton>
                </Box>
              )}
            </>
          )}
        </form>
      )}
    </Route>
  )
}

export default BNIDirectDebit
