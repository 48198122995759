import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, Grid, Typography, Divider, Box, Card, CardHeader, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import LoadingComponent from 'components/LoadingComponent'

// @ts-ignore
import { usePaymentInputs } from 'react-payment-inputs'

import { AppContext } from 'contexts/AppContext'

import iconVA from 'assets/images/icn-va-2.svg'
import iconOVO from 'assets/images/ovoSmall.png'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import Api, { PaymentRequest } from 'utils/Api'
import Inquiry from 'models/Inquiry'
import ContainedButton from 'components/ContainedButton'
import { Alert } from '@material-ui/lab'
import ApiErrorParser from 'utils/ApiErrorParser'
import { BackToShopButton } from 'components/BackToShopButton'
import PoweredByComponent from 'components/ComponentPoweredBy'
import PoweredByIcon from 'assets/images/poweredBy.svg'

const OVO: React.FunctionComponent<{ inquiry: Inquiry; onPaymentSubmitted: (data: any) => void }> = ({ inquiry, onPaymentSubmitted }) => {
  const PAYMENT_SOURCE = 'ovo'
  let { submitPayment } = useContext(AppContext)

  const history = useHistory()
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [paymentError, setPaymentError] = useState<string>()

  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps } = usePaymentInputs()

  const onSubmit = (e: any) => {
    e && e.preventDefault && e.preventDefault()

    if (!meta.error) {
      setSubmitting(true)
      const data: PaymentRequest = {
        inquiryId: inquiry.id,
        amount: inquiry.amount,
        currency: inquiry.currency,
        paymentSource: PAYMENT_SOURCE,
        paymentSourceData: {
          customerPhoneNumber: inquiry.customer?.phoneNumber
          // authorizationCode
        }
      }

      submitPayment(data)
        .then(() => {
          onPaymentSubmitted(data)
        })
        .catch((e) => {
          console.log(e)
          setSubmitting(false)
          setPaymentError(new ApiErrorParser(e).toString())
        })
        .finally(() => {})
    }
  }

  const onSelectOthers = () => {
    history.goBack()
  }
  if (loading)
    return (
      <>
        <LoadingComponent message={'Processing your request'} />
        <PoweredByComponent icon={PoweredByIcon} />
      </>
    )

  const provider = inquiry.merchant.providers.find((p) => p.providerId === 'ovo')

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item>
          <img src={iconOVO} />
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
            Pay With {provider?.title || provider?.provider?.name || 'OVO'}
          </Typography>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Box marginTop={[2, 4]} />

      <Card variant="outlined">
        <CardHeader title={'OVO'} />

        <Divider />

        <CardContent>
          <List component="ul">
            <ListItem>
              <ListItemIcon>
                <InfoOutlined color="primary" fontSize="default" />
              </ListItemIcon>
              <ListItemText
                inset={false}
                primary={
                  <>
                    {/* <p> Pastikan Anda memiliki akun OVO yang aktif. Pastikan saldo OVO Anda mencukupi untuk pembayaran transaksi ini.</p> */}
                    <p> Make sure you have an active OVO account. Make sure your OVO balance is sufficient for payment for this transaction</p>
                  </>
                }
                classes={{ primary: classes.listText }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <InfoOutlined color="primary" fontSize="default" />
              </ListItemIcon>
              <ListItemText
                inset={false}
                primary={
                  <>
                    {/* <p>Open your OVO application and click on the bell icon on the top right to continue the payment process. </p> */}
                    <p> Please complete the payment for this transaction within the time limit specified.</p>
                  </>
                }
                classes={{ primary: classes.listText }}
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>

      {paymentError && (
        <Box marginTop={[2, 4, 6]}>
          <Alert severity="error">{paymentError}</Alert>
        </Box>
      )}

      <Box marginTop={[2, 4, 6]}>
        <form method="post" onSubmit={onSubmit}>
          <ContainedButton fullWidth={true} type="submit" disabled={submitting}>
            Pay now
          </ContainedButton>

          <Box marginTop={[1, 2, 2]}>
            <BackToShopButton merchantName={inquiry.merchant.name} referenceUrl={inquiry.referenceUrl} />
          </Box>
        </form>

        {inquiry.paymentSource ? (
          <Box></Box>
        ) : (
          <Box marginTop={[2, 4, 4]}>
            <ContainedButton variant="text" fullWidth={true} onClick={onSelectOthers}>
              Select other payment methods
            </ContainedButton>
          </Box>
        )}
      </Box>

      {submitting && (
        <Box marginTop={[2, 4, 6]}>
          <LoadingComponent message="Requesting payment" />
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  listText: {
    fontWeight: 'bold'
  }
}))

export default OVO
