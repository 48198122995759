import axios from 'axios'
import Env from './Env'

const Api = axios.create({
  // baseURL: window.process && window.process.env && window.process.env.REACT_APP_API_URL,
  baseURL: Env.apiUrl,
  timeout: 60000
})

export default Api

export interface PaymentResponse {
  id: undefined | string
  status: string
  // statusCode: PaymentStatusCode
  statusCode: string
  statusData: any
}

export interface PaymentRequest {
  inquiryId: string
  amount: number
  currency: string
  paymentSource: string
  paymentSourceData: any
  installmentId?: number
  saveToken?: boolean
  useToken?: boolean
  customer?: { [key: string]: string }
  inquiry?: any
}

export interface PromoRequest extends PaymentRequest {}

export interface InstallmentRequest extends PaymentRequest {}
