import React, { useContext, useState } from 'react'
import Inquiry from 'models/Inquiry'
import { Box, Typography, Grid, Divider } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import SessionStorage from 'utils/StorageManager'

import iconVa from 'assets/images/icn-va-2.svg'
import iconQRIS from 'assets/images/icn-qris.svg'
import iconBNIVA from 'assets/images/bni-logo.png'
import iconBRIVA from 'assets/images/briva-logo.png'
import iconPermata from 'assets/images/permata-logo.svg'
import alfmartLogo from 'assets/images/alfamart-logo.png'

import ContainedButton from 'components/ContainedButton'
import { MandiriAPIVACaptured } from '../mandiriapiva/MandiriAPIVAPending'
import Api from 'utils/Api'
import ApiErrorParser from 'utils/ApiErrorParser'
import LoadingComponent from 'components/LoadingComponent'
import { Alert } from '@material-ui/lab'
import Transaction from 'models/Transaction'
import { useParams } from 'react-router-dom'
import OVOResult from 'modules/ovo/OVOResult'
import LinkAjaResult from 'modules/linkaja/LinkAjaResult'
import ShopeePayResult from 'modules/shopeepay/ShopeePayResult'
import MTIISOResult from 'modules/mtiiso/MTIISOResult'
import MTIDirectDebitResult from 'modules/mtidirectdebit/MTIDirectDebitResult'
import DemoCardResult from 'modules/democard/DemoCardResult'
import CyberSourceResult from 'modules/cybersource/CyberSourceResult'
import KredivoResult from 'modules/kredivo/KredivoResult'
import { BRIAPIVACaptured } from '../briapiva/BRIAPIVAPending'
import { BNIAPIVACaptured } from '../bniapiva/BNIAPIVAPending'
import GopayResult from 'modules/Gopay/GopayResult'
import IndodanaResult from 'modules/indodana/IndodanaResult'
import AtomeResult from 'modules/atome/AtomeResult'
import CimbResult from 'modules/cimb/CimbResult'
import MtiMpgsResult from 'modules/mtimpgs/MtiMpgsResult'
import BriCardResult from 'modules/bricard/BriCardResult'
import DanaResult from 'modules/dana/danaResult'
import { BISNAPVACaptured } from 'modules/bisnapva/BISNAPVAPending'
import BISNAPQRCaptured from 'modules/bisnapqr/BISNAPQRResult'
import { PermataAPIVACaptured } from '../permataapiva/PermataAPIVAPending'
import { AlfamartAlgoCaptured } from 'modules/alfamartalgo/AlfamartAlgoPending'
import OcbcisoResult from 'modules/ocbciso/OcbcIsoResult'
import MTIQRResult from 'modules/mtiqris/MTIQRISResult'
import useTranslator from 'hooks/useTranslator'
import BRIDirectDebitResult from 'modules/bridirectdebit/BRIDirectDebitResult'
import BNIISOResult from 'modules/bniiso/BNIISOResult'
import BNIDirectDebitResult from 'modules/bnidirectdebit/BNIDirectDebitResult'
import BTNISOResult from 'modules/btniso/BTNISOResult'
import { AppContext } from 'contexts/AppContext'
import PaymentLinkExpired from './PaymentLinkExpired'

const InquiryPaidPage: React.FC<{ inquiry: Inquiry }> = ({ inquiry }) => {
  const { inquiryCode } = useParams<{ inquiryCode: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [transactions, setTransactions] = useState<Transaction[]>()
  const [error, setError] = useState<string>()
  const { t } = useTranslator({
    path: 'common'
  })
  const { revertBackground } = useContext(AppContext)

  const onFinish = () => {
    if (inquiry.referenceUrl) {
      window.location.href = inquiry.referenceUrl
    }
  }
  React.useEffect(
    React.useCallback(() => {
      SessionStorage.remove(`${inquiryCode}-runner`)
      setLoading(true)
      Api.request<Transaction[]>({
        method: 'GET',
        url: `/inquiries/${inquiry.id}/transactions`
      })
        .then(({ data }) => {
          // purposely we filter out to display capture and authorized status
          setTransactions(data.filter((x) => x.status === 'captured' || x.status === 'authorized'))
          revertBackground('', inquiry)
        })
        .catch((e) => {
          // dispatch({type: 'FLASH', payload: {}})
          setError(new ApiErrorParser(e).toString())
        })
        .finally(() => {
          setLoading(false)
        })
    }, [inquiry]),
    [inquiry]
  )

  if (loading && !transactions) return <LoadingComponent message="Please wait.." />

  if (error) {
    return (
      <Box component="div">
        <Alert variant="filled" severity="error">
          {error}
        </Alert>

        <Box marginTop={4}>
          <ContainedButton onClick={() => {}} startIcon={<ArrowBack />}>
            Back
          </ContainedButton>
        </Box>
      </Box>
    )
  }
  return (
    <Box>
      {transactions?.map((transaction, k) => {
        if (transaction.paymentSource === 'mandiriapiva' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <Grid container spacing={2}>
                <Grid item>
                  <img src={iconVa} />
                </Grid>
                <Grid item>
                  <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
                    Mandiri UBP Virtual Account
                  </Typography>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: 10, marginBottom: 10 }} />

              <MandiriAPIVACaptured inquiry={inquiry} transaction={transaction} />

              <Box m={8} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'alfamartalgo' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <Grid container spacing={2}>
                <Grid item>
                  <img src={alfmartLogo} />
                </Grid>
                <Grid item style={{ paddingTop: 23 }}>
                  <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
                    Alfamart Algo
                  </Typography>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: 10, marginBottom: 10 }} />

              <AlfamartAlgoCaptured inquiry={inquiry} transaction={transaction} />

              <Box m={8} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        }
        if (transaction.paymentSource === 'linkaja' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <LinkAjaResult transaction={transaction} inquiry={inquiry} />
            </Box>
          )
        } else if (transaction.paymentSource === 'shopeepay' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <ShopeePayResult transaction={transaction} inquiry={inquiry} />
            </Box>
          )
        } else if (transaction.paymentSource === 'indodana' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <IndodanaResult transaction={transaction} inquiry={inquiry} />
            </Box>
          )
        } else if (transaction.paymentSource === 'atome' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <AtomeResult transaction={transaction} inquiry={inquiry} />
            </Box>
          )
        } else if (transaction.paymentSource === 'ovo' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <OVOResult transaction={transaction} inquiry={inquiry} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'cybersource' && (transaction.status == 'authorized' || transaction.status === 'captured')) {
          return (
            <Box key={`trp-${k}`} component="div">
              <CyberSourceResult transaction={transaction} merchant={inquiry.merchant} inquiry={inquiry} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'mtimpgs' && (transaction.status == 'authorized' || transaction.status === 'captured')) {
          return (
            <Box key={`trp-${k}`} component="div">
              <MtiMpgsResult transaction={transaction} merchant={inquiry.merchant} inquiry={inquiry} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'bricard' && (transaction.status == 'authorized' || transaction.status === 'captured')) {
          return (
            <Box key={`trp-${k}`} component="div">
              <BriCardResult transaction={transaction} merchant={inquiry.merchant} inquiry={inquiry} />

              <ContainedButton className="btnFinish" fullWidth onClick={onFinish}>
                {t('finish')}
              </ContainedButton>
            </Box>
          )
        } else if (transaction.paymentSource === 'cimb' && (transaction.status == 'authorized' || transaction.status === 'captured')) {
          return (
            <Box key={`trp-${k}`} component="div">
              <CimbResult transaction={transaction} merchant={inquiry.merchant} inquiry={inquiry} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'mtiiso' && (transaction.status == 'authorized' || transaction.status === 'captured')) {
          return (
            <Box key={`trp-${k}`} component="div">
              <MTIISOResult transaction={transaction} merchant={inquiry.merchant} inquiry={inquiry} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'btniso' && (transaction.status == 'authorized' || transaction.status === 'captured')) {
          return (
            <Box key={`trp-${k}`} component="div">
              <BTNISOResult transaction={transaction} merchant={inquiry.merchant} inquiry={inquiry} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'mtidirectdebit' && transaction.status === 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <MTIDirectDebitResult transaction={transaction} merchant={inquiry.merchant} inquiry={inquiry} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'bridirectdebit' && transaction.status === 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <BRIDirectDebitResult transaction={transaction} merchant={inquiry.merchant} inquiry={inquiry} />
              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  {t('finish')}
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'bnidirectdebit' && transaction.status === 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <BNIDirectDebitResult transaction={transaction} merchant={inquiry.merchant} inquiry={inquiry} />
              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  {t('finish')}
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'democard') {
          return (
            <Box key={`trp-${k}`} component="div">
              <DemoCardResult inquiry={inquiry} transaction={transaction} merchant={inquiry.merchant} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'kredivo' && transaction.status === 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <KredivoResult transaction={transaction} inquiry={inquiry} />
            </Box>
          )
        } else if (transaction.paymentSource === 'gopay' && transaction.status === 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <GopayResult transaction={transaction} inquiry={inquiry} />
            </Box>
          )
        } else if (transaction.paymentSource === 'briapiva' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <Grid container spacing={2}>
                <Grid item>
                  <img src={iconBRIVA} />
                </Grid>
                <Grid item>
                  <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold', marginTop: '14px' }}>
                    {inquiry.merchant.providers.find((p) => p.providerId === 'briapiva')?.title || 'BRI Virtual Account'}
                  </Typography>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: 10, marginBottom: 10 }} />

              <BRIAPIVACaptured inquiry={inquiry} transaction={transaction} />

              <Box m={8} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'bniapiva' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <Grid container spacing={2}>
                <Grid item>
                  <img src={iconBNIVA} />
                </Grid>
                <Grid item>
                  <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold', marginTop: '14px' }}>
                    {inquiry.merchant.providers.find((p) => p.providerId === 'bniapiva')?.title || 'BRI Virtual Account'}
                  </Typography>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: 10, marginBottom: 10 }} />

              <BNIAPIVACaptured inquiry={inquiry} transaction={transaction} />

              <Box m={8} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'dana' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <DanaResult transaction={transaction} inquiry={inquiry} />
            </Box>
          )
        } else if (transaction.paymentSource === 'bisnapva' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <Grid container spacing={2}>
                <Grid item>
                  <img src={iconVa} />
                </Grid>
                <Grid item>
                  <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold', marginTop: '14px' }}>
                    {inquiry.merchant.providers.find((p) => p.providerId === 'bisnapva')?.title || 'BI SNAP Virtual Account'}
                  </Typography>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: 10, marginBottom: 10 }} />

              <BISNAPVACaptured inquiry={inquiry} transaction={transaction} />

              <Box m={8} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'bisnapqr' && (transaction.status == 'authorized' || transaction.status === 'captured')) {
          return (
            <Box key={`trp-${k}`} component="div">
              <Grid container spacing={2}>
                <Grid item>
                  <img src={iconQRIS} />
                </Grid>
                <Grid item style={{ marginTop: 20 }}>
                  <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
                    {inquiry.merchant.providers.find((p) => p.providerId === 'bisnapqr')?.title || 'BI SNAP QRIS'}
                  </Typography>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: 10, marginBottom: 10 }} />

              <BISNAPQRCaptured inquiry={inquiry} transaction={transaction} />

              <Box m={8} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'mtiqris' && (transaction.status == 'authorized' || transaction.status === 'captured')) {
          return (
            <Box key={`trp-${k}`} component="div">
              <Grid container spacing={2}>
                <Grid item>
                  <img src={iconQRIS} />
                </Grid>
                <Grid item>
                  <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
                    {inquiry.merchant.providers.find((p) => p.providerId === 'mtiqris')?.title || 'MTI QRIS'}
                  </Typography>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: 10, marginBottom: 10 }} />

              <MTIQRResult inquiry={inquiry} transaction={transaction} />

              <Box m={8} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'permataapiva' && transaction.status == 'captured') {
          return (
            <Box key={`trp-${k}`} component="div">
              <Grid container spacing={2}>
                <Grid item>
                  <img src={iconPermata} />
                </Grid>
                <Grid item style={{ marginTop: 20 }}>
                  <Typography variant="h5" component="h5" gutterBottom color="primary" style={{ fontWeight: 'bold' }}>
                    {inquiry.merchant.providers.find((p) => p.providerId === 'permataapiva')?.title || 'Bank Permata Virtual Account'}
                  </Typography>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: 10, marginBottom: 10 }} />

              <PermataAPIVACaptured inquiry={inquiry} transaction={transaction} />

              <Box m={8} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'ocbciso' && (transaction.status == 'authorized' || transaction.status === 'captured')) {
          return (
            <Box key={`trp-${k}`} component="div">
              <OcbcisoResult transaction={transaction} merchant={inquiry.merchant} inquiry={inquiry} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else if (transaction.paymentSource === 'bniiso' && (transaction.status == 'authorized' || transaction.status === 'captured')) {
          return (
            <Box key={`trp-${k}`} component="div">
              <BNIISOResult transaction={transaction} merchant={inquiry.merchant} inquiry={inquiry} />

              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        } else {
          // if (transactions.length > 1) return null

          const transactionStatus = transactions[0].status
          const inquiryStatus = inquiry.status
          const paymentStatus = transactions[0].type
          return (
            <Box key={`trp-${k}`} component="div">
              <Box margin={[2, 6]} textAlign="center">
                <Typography variant="h4" color="primary" style={{ fontWeight: 'bold' }}>
                  Transaksi Anda dalam status {transactionStatus}
                </Typography>
                <Typography variant="body1" color="textPrimary" style={{ marginTop: 10 }}>
                  Silahkan contact call center kami untuk bantuan.
                  <br />
                  <br />
                  Informasi bantuan:
                  <br />
                  inquiry.status = {inquiryStatus}
                  <br />
                  payment.status = {paymentStatus}
                </Typography>

                <Box m={8} />
              </Box>
              {!inquiry.order || inquiry.order.channel != 'paymentlink' ? (
                <ContainedButton fullWidth onClick={onFinish}>
                  Finish
                </ContainedButton>
              ) : null}
            </Box>
          )
        }
      })}
    </Box>
  )
}

export default InquiryPaidPage
